import React, {useEffect, useState} from 'react';
import {reauthenticateWithCredential, updateEmail, EmailAuthProvider} from "firebase/auth";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import './settings.scss';
import FirebaseUtil from "../Firebase/Firebase";
import {auth} from "../../App";
import {snackbarSlice} from "../../store/Snackbar";

interface Props {
    expanded: boolean;
    onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    collapse: () => void;
}

const EmailAccordion = (props: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const isCompany = !!company;
    const isAccountActive = isCompany ? company?.active : user!.active;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(false);
    }, [props.expanded]);

    const deactivateAcccount = async () => {
        if (isLoading)
            return;

        setIsLoading(true);
        try {
            await FirebaseUtil.write(isCompany ? 'Companies' : 'Users', isCompany ? company.id! : user!.id!, {
                active: !isAccountActive
            });

            dispatch(snackbarSlice.actions.show({
                message: isAccountActive ? 'Cont dezactivat cu succes!' : 'Cont activat cu succes!',
                severity: 'success',
            }));

            setIsLoading(false);
            props.collapse();
        } catch (e: any) {
            console.error(e.message);
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }));
        }
    }

    return (
        <Accordion
            expanded={props.expanded}
            onChange={!isLoading ? props.onChange('delete') : () => {
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDownward/>}
                aria-controls="panel1a-content"
            >
                <div>
                    <h3>
                        {
                            isAccountActive ? 'Dezactivare cont' : 'Activare cont'
                        }
                    </h3>
                    <p className={'grey-subtitle'}>
                        {
                            'Starea contului: '
                        }
                        <span style={{
                            fontWeight: 500,
                            opacity: 1,
                            color: !isAccountActive ? 'red' : undefined
                        }}>{isAccountActive ? 'activ' : 'inactiv'}</span>
                    </p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {
                        isAccountActive ?
                            isCompany ?
                                'Dupa dezactivarea contului, profilul companiei va fi invizibil pentru toti candidatii. De asemenea, job-urile companiei nu vor mai aparea in căutări.' :
                                'Dupa dezactivarea contului, profilul tău va fi vizibil doar pentru tine. De asemenea, nu vei mai putea aplica la joburi.'
                            : 'Activeaza-ti contul pentru a beneficia toate facilitatile pe care ti le poate oferi aceasta platforma.'
                    }
                </Typography>
                <Typography>
                    Contul poate fi oricând reactivat din acest meniu.
                </Typography>
            </AccordionDetails>
            <AccordionActions>
                <Button
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'red'}}
                    onClick={deactivateAcccount}
                >
                    {
                        isAccountActive ? 'Dezactivare cont' : 'Activare cont'
                    }
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

export default EmailAccordion;