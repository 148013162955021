import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {Job} from "../../../models/Job";
import {useNavigate} from "react-router";
import CardHeader from "../../Profile/Cards/CardHeader";
import JobCard from "./JobCard";
import {CompanyDetails} from "../../../store/Authentication";

interface Props {
    jobs: Job[];
    isOwnProfile: boolean;
    companyProfile?: CompanyDetails;
}

const CompanyJobsInformationCard = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                hideEditButton={true}
                title={'Joburi disponibile'}
            />
            <div className={'solid'}/>
            <div className={'jobs-container'}>
                {
                    props.jobs.length > 0 ?
                        props.jobs.map((job, index) => {
                            return (
                                <div
                                    key={`experience-card-item-${index}`}>
                                    <JobCard
                                        job={job}
                                        isOwnProfile={props.isOwnProfile}
                                        companyProfile={props.companyProfile}
                                    />
                                    {
                                        (props.isOwnProfile || index < props.jobs.length - 1) &&
                                        <div className={'solid-thin'}/>
                                    }
                                </div>
                            );
                        }) :
                        'Niciun job disponibil la momentul actual.'
                }
            </div>
            {
                props.isOwnProfile &&
                <Button onClick={() => {
                    navigate('/adauga-job');
                }}
                        style={{textTransform: 'uppercase', marginTop: 10}}>
                    {
                        <>
                            <Add color={'primary'} fontSize={'small'}/>
                            <span>Posteaza un job nou</span>
                        </>
                    }
                </Button>
            }
        </div>
    );
}

export default CompanyJobsInformationCard;