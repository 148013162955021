import FullMonths from "../Constants/Months";

const DateUtil = {
    getDateFromTimestamp: (dateTimestamp: any) => {
      return new Date(dateTimestamp.seconds * 1000)
    },
    getMonthYearFromString: (dateTimestamp: any) => {
        let monthYearString = FullMonths[new Date(dateTimestamp.seconds * 1000).getMonth()].substring(0, 3) + ' ' + new Date(dateTimestamp.seconds * 1000).getFullYear();
        return monthYearString;
    },
    getTimePassed: (startDateTimestamp: any, endDateTimestamp: any) => {
        let endDate = endDateTimestamp ? new Date(endDateTimestamp.seconds * 1000) : new Date();
        let startDate = new Date(startDateTimestamp.seconds * 1000);

        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();
        let days = endDate.getDate() - startDate.getDate();

        if(days < 0)
        {
            months++;
        }
        if( months < 0 ){
            years--;
            months = 12 + months;
        }


        const timePassedString = (years > 0 ? (years + (years > 1 ? ' ani ' : ' an ')) : ' ') + (months ? (months + (months > 1 ? ' luni' : ' luna')) : '');
        return timePassedString;
    },
    getAge: (dateTimestamp: any, isFromCloudFunctions?: boolean) => {
        let today = new Date();
        let birthDate = new Date((isFromCloudFunctions ? dateTimestamp._seconds : dateTimestamp.seconds) * 1000);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export default DateUtil;