import React, {useEffect, useState} from 'react';
import MotionHoc from "../MotionHoc";
import './settings.scss';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    Typography
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import EmailAccordion from './EmailAccordion';
import PasswordAccordion from "./PasswordAccordion";
import DeactivateAccordion from "./DeactivateAccordion";

const SettingsComponent = () => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <Container maxWidth={'lg'} className={'form-page-container'}>
            <div className={'page-title'}>Setări cont</div>
            <div className={'container-form'}>
                <EmailAccordion
                    expanded={expanded === 'mail'}
                    onChange={handleChange}
                    collapse={() => setExpanded(false)}
                />
                <PasswordAccordion
                    expanded={expanded === 'password'}
                    onChange={handleChange}
                    collapse={() => setExpanded(false)}
                />
                <DeactivateAccordion
                    expanded={expanded === 'delete'}
                    onChange={handleChange}
                    collapse={() => setExpanded(false)}
                />
            </div>
        </Container>
    )
}

const Settings = MotionHoc(SettingsComponent);

export default Settings;