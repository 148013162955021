import React, {useEffect, useState} from 'react';
import '../JobsList/jobsList.scss'
import {Button, CircularProgress, Container} from "@mui/material";
import MotionHoc from "../MotionHoc";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../App";
import {UserDetails} from "../../store/Authentication";
import CandidatesFilterCard from "../CandidatesFilterCard";
import CandidateListItem from "../CandidateListItem";
import {CareerLevelEnum, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {SchoolTypeEnum} from "../Profile/Cards/CardItem";
import JobsFilterCard from "../JobsFilterCard";

const CandidatesListComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isCandidatesPage = window.location.pathname.includes('/candidati') && window.location.pathname[10] !== '/';
    const [hasFilters, setHasFilters] = useState(false);

    let query = searchParams.get('query');
    let careerLevel = searchParams.getAll('level');
    let industries = searchParams.getAll('industry');
    let skills = searchParams.getAll('skill');
    let minAge = searchParams.get('minAge');
    let maxAge = searchParams.get('maxAge');
    let languages = searchParams.getAll('language');
    let education = searchParams.get('education');

    const [page, setPage] = useState<number>(1);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const ITEMS_PER_PAGE = 20;

    const navigate = useNavigate();
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);

    const [candidates, setCandidates] = useState<UserDetails[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [filterText, setFilterText] = useState<string>('');
    const [maxPages, setMaxPages] = useState<number>(0);

    useEffect(() => {
        setFilterText(query ? query : '');
        setHasFilters(searchParams.toString().length > 0);

        if (isCandidatesPage)
            getCandidates();
    }, [searchParams, page]);

    const searchCandidatesWithQuery = () => {
        if (filterText)
            searchParams.set('query', filterText);
        else
            searchParams.delete('query');
        // searchParams.delete('page');
        setPage(1);
        navigate(searchParams.toString().length > 0 ? '/candidati?' + searchParams.toString() : '/candidati');
    }

    const getCandidates = async () => {
        setCandidates([]);
        setResultsCount(0);
        setIsLoading(true);

        const getCandidatesWithFilters = httpsCallable(functions, 'getCandidatesWithFilters');
        try {
            await getCandidatesWithFilters({
                query: query,
                careerLevel: careerLevel.map(level => CareerLevelEnum[level as any]),
                industries: industries.map(industry => IndustriesEnum[industry as any]),
                minAge: minAge ? parseInt(minAge) : null,
                maxAge: maxAge ? parseInt(maxAge) : null,
                skills: skills.map(skillString => {
                    const skillList = skillString.split(';');
                    if(skillList.length > 2)
                        return {
                            label: '',
                            content: 'Mediu'
                        };
                    return {
                        label: skillList[0],
                        content: skillList[1]
                    };
                }),
                languages: languages,
                education: education,
                page: page,
                itemsPerPage: ITEMS_PER_PAGE,
            })
                .then((result) => {
                    setCandidates((result.data as any).candidates);
                    setMaxPages(Math.ceil((result.data as any).resultsCount / ITEMS_PER_PAGE));
                    setResultsCount((result.data as any).resultsCount);
                });
        } catch (e: any) {
        } finally {
            setIsLoading(false);
        }
    }

    const getPaginationButtons = () => {
        if (maxPages === 0) return;
        let paginationButtons: any[] = [];
        if (page > 1) {
            paginationButtons = [
                <Button
                    key={'prec'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page - 1);
                    }}
                >
                    <span className={'only-large'}>Pagina precedenta</span>
                    <span className={'only-small'}>Prec.</span>
                </Button>
            ];
        }

        paginationButtons = [
            ...paginationButtons,
            <Button
                key={'1'}
                className={`new-job-button pagination-button ${page === 1 ? '' : 'not-selected'}`}
                onClick={() => {
                    setPage(1);
                }}
            >
                1
            </Button>
        ];

        if (page === 2) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'2'}
                    className={'new-job-button pagination-button'}>
                    2
                </Button>
            ];
        } else if (page > 2) {
            paginationButtons = [
                ...paginationButtons,
                '...',
                <Button
                    key={'page-number'}
                    className={'new-job-button pagination-button'}
                    onClick={() => {
                        setPage(page);
                    }}
                >
                    {page}
                </Button>
            ];
        }

        if (page < maxPages) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'next'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    <span className={'only-large'}>Pagina urmatoare</span>
                    <span className={'only-small'}>Urm.</span>
                </Button>
            ];
        }

        return paginationButtons;
    }

    return (
        <Container maxWidth={'xl'} className={'page-container'}
                   style={{paddingTop: 30, paddingBottom: 30, position: 'relative'}}>
            <div className={'jobs-sidebar-small'}>
                <div className={'sidebar-holder'}>
                    <input type="search"
                           value={filterText}
                           className="sidebar-search-input"
                           placeholder="Căutare candidati"
                           onChange={(e) => setFilterText(e.target.value)}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter' || e.key === 'Return') {
                                   searchCandidatesWithQuery()
                               }
                           }}
                    />
                    <Button
                        style={{width: '100%', marginTop: 20}}
                        className={'new-job-button'}
                        onClick={() => {
                            searchCandidatesWithQuery()
                        }}
                    >
                        Caută candidati
                    </Button>
                    <CandidatesFilterCard
                        searchParams={searchParams}
                        query={query ?? ''}
                        skills={skills.map(skillString => {
                            const skillList = skillString.split(';');
                            if(skillList.length > 2)
                                return {
                                    label: '',
                                    content: 'Mediu'
                                };
                            return {
                                label: skillList[0],
                                content: skillList[1]
                            };
                        })}
                        languages={languages}
                        ageInterval={{minAge: minAge ? parseInt(minAge) : null, maxAge: maxAge ? parseInt(maxAge) : null}}
                        careerLevel={careerLevel.map(level => CareerLevelEnum[level as keyof typeof CareerLevelEnum])}
                        industries={industries.map(industry => IndustriesEnum[industry as keyof typeof IndustriesEnum])}
                        education={education as SchoolTypeEnum ?? null}
                    />
                </div>
            </div>
            <div className={'jobs-header'}>
                {
                    hasFilters ?
                        <h2>Rezultatele căutării</h2>
                        : <h2>
                            Candidați
                        </h2>
                }
            </div>
            <div className={'jobs-page'}>
                <div className={'jobs-list-container'}>
                    {
                        (candidates.length > 0) ?
                            candidates.map(candidate => {
                                return <CandidateListItem key={candidate.id} candidate={candidate}/>
                            }) : isLoading ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '50vh',
                                    alignItems: 'center'
                                }}>
                                    <CircularProgress size={60}/>
                                </div>
                                :
                                'Niciun candidat in acest moment'
                    }
                    {
                        !isLoading &&
                        <div className={'pagination-container'} style={{position: 'relative'}}>
                            {
                                getPaginationButtons()
                            }
                            {
                                resultsCount > 0 &&
                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: -10,
                                    fontWeight: 400,
                                    color: '#858c95'
                                }}>
                                    {`${(page - 1) * ITEMS_PER_PAGE + 1}-${Math.min(page * ITEMS_PER_PAGE, resultsCount)} din ${resultsCount}`}
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    <div className={'jobs-sidebar-large'}>
                        <div className={'sidebar-holder'}>
                            <h3 className={'sidebar-title'}>
                                Caută candidati
                            </h3>
                            <input type="search"
                                   value={filterText}
                                   className="sidebar-search-input"
                                   placeholder="Căutare candidati"
                                   onChange={(e) => setFilterText(e.target.value)}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter' || e.key === 'Return') {
                                           searchCandidatesWithQuery()
                                       }
                                   }}
                            />
                            <Button
                                style={{width: '100%', marginTop: 20}}
                                className={'new-job-button'}
                                onClick={() => {
                                    searchCandidatesWithQuery()
                                }}
                            >
                                Caută candidati
                            </Button>
                            <CandidatesFilterCard
                                searchParams={searchParams}
                                query={query ?? ''}
                                skills={skills.map(skillString => {
                                    const skillList = skillString.split(';');
                                    if(skillList.length > 2)
                                        return {
                                            label: '',
                                            content: 'Mediu'
                                        };
                                    return {
                                        label: skillList[0],
                                        content: skillList[1]
                                    };
                                })}
                                languages={languages}
                                ageInterval={{minAge: minAge ? parseInt(minAge) : null, maxAge: maxAge ? parseInt(maxAge) : null}}
                                careerLevel={careerLevel.map(level => CareerLevelEnum[level as keyof typeof CareerLevelEnum])}
                                industries={industries.map(industry => IndustriesEnum[industry as keyof typeof IndustriesEnum])}
                                education={education as SchoolTypeEnum ?? null}
                            />
                        </div>
                    </div>
                }
            </div>
        </Container>
    );
}

const CandidatesList = MotionHoc(CandidatesListComponent);

export default CandidatesList;