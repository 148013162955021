import React from 'react';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'

export interface DialogConfig {
    title: string;
    content?: string;
}

export interface TripleChoiceDialogConfig {
    title: string;
    content: string;
    buttonOptions: string[];
}

export const DialogUtil = {
    confirm: (config: DialogConfig, open: boolean, resolve : (option: boolean) => void, isYesAndNo?: boolean) => {
        return (
            <Dialog open={open}>
                <DialogTitle color={'primary'}>{config.title}</DialogTitle>
                {
                    config.content && <DialogContent>{config.content}</DialogContent>
                }
                <DialogActions>
                    <Button onClick={() => resolve(false)} style={{color: 'black', textTransform: 'uppercase'}}>
                        {isYesAndNo ? 'Nu' : 'Anulează'}
                    </Button>
                    <Button onClick={() => resolve(true)} style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                        {isYesAndNo ? 'Da' : 'Confirmă'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
    inform: (config: DialogConfig, open: boolean, resolve: () => void) => {
        return (
            <Dialog open={open}>
                <DialogTitle color={'primary'}>{config.title}</DialogTitle>
                {
                    config.content && <DialogContent>{config.content}</DialogContent>
                }
                <DialogActions>
                    <Button onClick={() => resolve()}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        )
    },
    multipleChoices: (config: TripleChoiceDialogConfig, open: boolean, resolve: (number: number) => void): any => {
        return (
            <Dialog open={open}>
                <DialogTitle color={'primary'}>{config.title}</DialogTitle>
                <DialogContent>{config.content}</DialogContent>
                <DialogActions>
                    {
                        config.buttonOptions.map((buttonOption, index) => {
                            return (
                                <Button onClick={() => {
                                    resolve(index);
                                }}>
                                    {buttonOption}
                                </Button>
                            );
                        })
                    }
                </DialogActions>
            </Dialog>
        );
    },
};
