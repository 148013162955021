import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import './registerCompany.scss';
import {Autocomplete, Button, Checkbox, CircularProgress, FormControlLabel, TextField} from "@mui/material";
import {useNavigate} from "react-router";
import * as yup from 'yup';
import {useFormik} from 'formik';
import FirebaseUtil from "../Firebase/Firebase";
import {Check, CheckRounded} from "@mui/icons-material";
import {auth, cuiUrl} from "../../App";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {snackbarSlice} from "../../store/Snackbar";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import MotionHoc from "../MotionHoc";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {NavLink} from "react-router-dom";
import {defaultRejectionMessage, defaultThanksForApplyingMessage} from "../defaults";

const RegisterCompanyComponent = () => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [emailNotUnique, setEmailNotUnique] = useState(false);
    const [validCompany, setValidCompany] = useState<boolean | null>(null);

    const [isCuiValid, setIsCuiValid] = useState<boolean | null>(null);
    const [isCuiUnique, setIsCuiUnique] = useState<boolean>(true);
    const [lastCuiValue, setLastCuiValue] = useState<string>('');
    const cuiInvalidMessage = 'CUI inexistent în baza de date a Ministerului de Finanțe'
    const cuiNotUniqueMessage = 'CUI deja inregistrat'
    const dispatch = useDispatch();

    useEffect(() => {
        if (user || company) {
            navigate(user ? ('/candidati/' + user.id!) : ('/companii/' + company?.id), {
                replace: true
            });
            dispatch(snackbarSlice.actions.show({
                message: 'Conectare reușită!',
                severity: 'success',
            }));
        }
    }, [user, company]);

    const currentStepDetails: { name: string, description: string }[] = [
        {
            name: '',
            description: '',
        },
        {
            name: 'Date autentificare',
            description: 'Completează datele de autentificare pentru a putea accesa platforma si a incepe recrutarea.'
        },
        {
            name: 'Datele companiei',
            description: 'Aceste date vor fi folosite pentru verificarea companiei si activarea contului pe platforma.'
        },
        {
            name: 'Activitatea companiei',
            description: 'Mai multe date pentru a putea ajuta candidatii sa cunoasca compania.'
        },
        {
            name: 'Contact',
            description: 'Datele de contact ale companiei vor fi vizibile pentru candidati, pentru a facilita comunicarea intre ei si compania dvs.'
        },
    ];

    const employeesCounts = [
        '0-10',
        '11-50',
        '51-250',
        '251-500',
        '501-1000',
        '1001-2000',
        'Peste 2000'
    ]

    const authenticationValidation = yup.object({
        email: yup
            .string()
            .email('Introduceți un e-mail valid')
            .required('Acest câmp este obligatoriu'),
        password: yup
            .string()
            .min(6, 'Parola trebuie sa aiba minim 6 caractere')
            .required('Acest câmp este obligatoriu'),
        confirmPassword: yup
            .string()
            .required('Acest câmp este obligatoriu')
            .when('password', {
                is: (val: string) => (!!(val && val.length > 0)),
                then: yup.string().oneOf([yup.ref('password')], 'Parolele trebuie sa coincida')
            })
    });

    const authenticationFormik: any = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: authenticationValidation,
        isInitialValid: false,
        onSubmit: (values) => {
            // tryNavigate(currentStep + 1);
        }
    })

    const detailsValidation = yup.object({
        cui: yup
            .string()
            .required('Acest câmp este obligatoriu'),
        nrOrdine: yup
            .string(),
        companyName: yup
            .string()
            .required('Acest câmp este obligatoriu'),
        address: yup
            .string()
            .required('Acest câmp este obligatoriu'),
        city: yup
            .string()
            .required('Acest câmp este obligatoriu'),
        country: yup
            .string()
            .required('Acest câmp este obligatoriu'),
    });

    const detailsFormik: any = useFormik({
        initialValues: {
            cui: '',
            nrOrdine: '',
            companyName: '',
            address: '',
            city: '',
            country: '',
        },
        validationSchema: detailsValidation,
        isInitialValid: false,
        onSubmit: (values) => {
            // tryNavigate(currentStep + 1);
        }
    })

    const activityValidations = yup.object({
        employees: yup
            .string()
            .required('Acest câmp este obligatoriu'),
        industries: yup
            .array()
            .min(1, 'Cel putin un domeniu trebuie selectat'),
        isValidCompany: yup
            .boolean(),
        cityAddress: yup
            .string()
            .when('isValidCompany',
                {
                    is: true,
                    then: yup.string().required('Acest câmp este obligatoriu')
                }),
    });

    const activityFormik: any = useFormik({
        initialValues: {
            employees: '',
            industries: [],
            isValidCompany: false,
            cityAddress: ''
        },
        validationSchema: activityValidations,
        isInitialValid: false,
        onSubmit: (values) => {
            // tryNavigate(currentStep + 1);
        }
    })

    const contactValidations = yup.object({
        contactEmail: yup
            .string()
            .email('Introduceți un e-mail valid')
            .required('Acest câmp este obligatoriu'),
        website: yup
            .string()
            .matches(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, 'Introduceți un url valid'),
        phoneNumber: yup
            .string()
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Introduceți un numar de telefon valid'),
        acceptTerms: yup
            .boolean()
            .oneOf([true], 'Acest câmp este obligatoriu')
    });

    const contactFormik: any = useFormik({
        initialValues: {
            contactEmail: '',
            website: '',
            phoneNumber: '',
            acceptTerms: false
        },
        validationSchema: contactValidations,
        isInitialValid: false,
        onSubmit: (values) => {
        }
    })

    const renderForm = () => {
        switch (currentStep) {
            case 1:
                return renderAuthenticationForm();
            case 2:
                return renderDetailsForm();
            case 3:
                return renderActivityForm();
            case 4:
                return renderContactForm();
        }

        return null;
    }
    const renderAuthenticationForm = () => {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                nextStep(true)
            }}>
                <div className={'form-name'}>
                    {currentStepDetails[1].name}
                </div>
                <div className={'form-fields'}>
                    <TextField
                        label={'E-mail'}
                        required
                        name={'email'}
                        fullWidth
                        value={authenticationFormik.values.email}
                        onChange={authenticationFormik.handleChange}
                        error={authenticationFormik.touched.email &&
                        (!!authenticationFormik.errors.email || emailNotUnique)}
                        helperText={authenticationFormik.touched.email &&
                        (authenticationFormik.errors.email?.length > 0 ?
                            authenticationFormik.errors.email :
                            emailNotUnique ? 'Acest email este deja inregistrat' : '')}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <TextField
                        label={'Parola'}
                        required
                        name={'password'}
                        type={'password'}
                        fullWidth
                        value={authenticationFormik.values.password}
                        onChange={authenticationFormik.handleChange}
                        error={authenticationFormik.touched.password && !!authenticationFormik.errors.password}
                        helperText={authenticationFormik.touched.password && authenticationFormik.errors.password}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <TextField
                        label={'Confirmă parola'}
                        required
                        name={'confirmPassword'}
                        type={'password'}
                        fullWidth
                        value={authenticationFormik.values.confirmPassword}
                        onChange={authenticationFormik.handleChange}
                        error={authenticationFormik.touched.confirmPassword && !!authenticationFormik.errors.confirmPassword}
                        helperText={authenticationFormik.touched.confirmPassword && authenticationFormik.errors.confirmPassword}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                </div>
                <input type={'submit'} style={{display: 'none'}}/>
            </form>
        );
    }

    const checkCui = async () => {
        detailsFormik.setFieldValue('cui', detailsFormik.values.cui.trim());
        if (detailsFormik.values.cui.trim().length === 0 || detailsFormik.values.cui.trim() === lastCuiValue)
            return;

        setLastCuiValue(detailsFormik.values.cui.trim());
        let cuiUnique = true;
        await FirebaseUtil.getDocsWithCondition('Companies').then(docs => {
            docs.forEach((doc) => {
                if (doc.data()['cui'] === detailsFormik.values.cui) {
                    cuiUnique = false;
                    return;
                }
            })
        })

        if (!cuiUnique) {
            setIsCuiUnique(false);
            setIsCuiValid(true);
            return;
        }
        setIsCuiUnique(true);

        const url = cuiUrl + detailsFormik.values.cui;
        let data: any;
        setValidCompany(null);
        await fetch(url)
            .then(response => response.json())
            .then(responseData => data = responseData);

        if (!data.data) {
            setValidCompany(false);
            setIsCuiValid(false);
            return;
        }

        const isValid = data.status === 200;
        if (isValid) {
            setValidCompany(true);
            setIsCuiValid(true);
            detailsFormik.setFieldValue('cui', data.data['cod_fiscal']);
            detailsFormik.setFieldValue('nrOrdine', data.data['cod_inmatriculare'] ?? '');
            detailsFormik.setFieldValue('companyName', data.data['nume']);
            detailsFormik.setFieldValue('address', data.data['adresa'] ? `${data.data['adresa_strada'] ? ('Str. ' + data.data['adresa_strada']) : ''}, ${data.data['adresa_nr_strada'] ? 'Nr. ' + data.data['adresa_nr_strada'] : ''}` : '');
            detailsFormik.setFieldValue('city', data.data['adresa'] ? data.data['adresa_localitate'] : '');
            detailsFormik.setFieldValue('country', data.data['adresa'] ? data.data['adresa_tara'] : '');
        }
    }

    const renderDetailsForm = () => {
        return (
            <form onSubmit={(e) => {
                e.preventDefault();
                nextStep(true)
            }}>
                <div className={'form-name'}>
                    {currentStepDetails[2].name}
                </div>
                <div className={'form-fields-fullwidth'}>
                    <div className={'column left'}>
                        <div className={'title'}>
                            Detalii
                        </div>
                        <TextField
                            label={'CUI'}
                            name={'cui'}
                            fullWidth
                            required
                            onBlur={checkCui}
                            value={detailsFormik.values.cui}
                            onChange={detailsFormik.handleChange}
                            error={isCuiValid === false || isCuiUnique === false || (detailsFormik.touched.cui && !!detailsFormik.errors.cui)}
                            helperText={isCuiValid === false ? cuiInvalidMessage : isCuiUnique === false ? cuiNotUniqueMessage : (detailsFormik.touched.cui && detailsFormik.errors.cui)}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                        <TextField
                            label={'Număr de ordine la registrul comertului'}
                            name={'nrOrdine'}
                            fullWidth
                            value={detailsFormik.values.nrOrdine}
                            onChange={detailsFormik.handleChange}
                            error={detailsFormik.touched.nrOrdine && !!detailsFormik.errors.nrOrdine}
                            helperText={detailsFormik.touched.nrOrdine && detailsFormik.errors.nrOrdine}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                        <TextField
                            label={'Numele companiei'}
                            name={'companyName'}
                            fullWidth
                            required
                            disabled
                            value={detailsFormik.values.companyName}
                            onChange={detailsFormik.handleChange}
                            error={detailsFormik.touched.companyName && !!detailsFormik.errors.companyName}
                            helperText={detailsFormik.touched.companyName && detailsFormik.errors.companyName}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                    </div>
                    <div className={'column right'}>
                        <div className={'title'}>
                            Adresa
                        </div>
                        <TextField
                            label={'Adresa sediului social'}
                            name={'address'}
                            fullWidth
                            required
                            value={detailsFormik.values.address}
                            onChange={detailsFormik.handleChange}
                            error={detailsFormik.touched.address && !!detailsFormik.errors.address}
                            helperText={detailsFormik.touched.address && detailsFormik.errors.address}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                        <TextField
                            label={'Orasul sediului social'}
                            name={'city'}
                            fullWidth
                            required
                            value={detailsFormik.values.city}
                            onChange={detailsFormik.handleChange}
                            error={detailsFormik.touched.city && !!detailsFormik.errors.city}
                            helperText={detailsFormik.touched.city && detailsFormik.errors.city}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                        <TextField
                            label={'Tara sediului social'}
                            name={'country'}
                            fullWidth
                            required
                            value={detailsFormik.values.country}
                            onChange={detailsFormik.handleChange}
                            error={detailsFormik.touched.country && !!detailsFormik.errors.country}
                            helperText={detailsFormik.touched.country && detailsFormik.errors.country}
                            variant={'standard'}
                            className={'form-text-field'}
                        />
                    </div>
                </div>
                <input type={'submit'} style={{display: 'none'}}/>
            </form>
        );
    }

    const renderActivityForm = () => {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    nextStep(true)
                }}>
                <div className={'form-name'}>
                    {currentStepDetails[3].name}
                </div>
                <div className={'form-fields'}>
                    <div className={'title-smaller'}>
                        {detailsFormik.values.companyName}
                    </div>
                    <Autocomplete
                        value={activityFormik.values.employees}
                        id={'employees'}
                        onChange={(e, v) => {
                            activityFormik.setFieldValue('employees', v ?? '')
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={'Număr angajati'}
                                    fullWidth
                                    required
                                    error={activityFormik.touched.employees && !!activityFormik.errors.employees}
                                    helperText={activityFormik.touched.employees && activityFormik.errors.employees}
                                    variant={'standard'}
                                    className={'form-text-field'}

                                />
                            );
                        }}
                        options={employeesCounts}
                        componentsProps={{
                            paper: {
                                style: {
                                    borderRadius: '0 0 20px 20px'
                                }
                            }
                        }}/>
                    <Autocomplete
                        multiple
                        getOptionDisabled={(option) => activityFormik.values.industries.length >= 3}
                        value={activityFormik.values.industries}
                        onChange={(e, v) => {
                            activityFormik.setFieldValue('industries', v as IndustriesEnum[]);
                        }}
                        fullWidth
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={'Domeniile in care activeaza companie'}
                                    required
                                    error={activityFormik.touched.industries && !!activityFormik.errors.industries}
                                    helperText={activityFormik.touched.industries && activityFormik.errors.industries}
                                    variant={'standard'}
                                    className={'form-text-field'}
                                />
                            );
                        }}
                        options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                        getOptionLabel={(option) => {
                            return option.toString();
                        }} componentsProps={{
                        paper: {
                            style: {
                                borderRadius: '0 0 20px 20px'
                            }
                        }
                    }}/>
                    <FormControlLabel
                        name={'isValidCompany'}
                        checked={activityFormik.values.isValidCompany}
                        onChange={activityFormik.handleChange}
                        control={<Checkbox/>}
                        label={'Punct de lucru existent in Resita'}
                    />
                    {
                        activityFormik.values.isValidCompany ?
                            <TextField
                                label={'Adresa punctului de lucru din Resita'}
                                name={'cityAddress'}
                                fullWidth
                                value={activityFormik.values.cityAddress}
                                onChange={activityFormik.handleChange}
                                error={activityFormik.touched.cityAddress && !!activityFormik.errors.cityAddress}
                                helperText={activityFormik.touched.cityAddress && activityFormik.errors.cityAddress}
                                variant={'standard'}
                                className={'form-text-field'}
                            /> : null
                    }
                    <input type={'submit'} style={{display: 'none'}}/>
                </div>
            </form>
        );
    }

    const renderContactForm = () => {
        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    nextStep(true)
                }}>
                <div className={'form-name'}>
                    {currentStepDetails[4].name}
                </div>
                <div className={'form-fields'}>
                    <TextField
                        label={'E-mail de contact'}
                        name={'contactEmail'}
                        fullWidth
                        required
                        value={contactFormik.values.contactEmail}
                        onChange={contactFormik.handleChange}
                        error={contactFormik.touched.contactEmail && !!contactFormik.errors.contactEmail}
                        helperText={contactFormik.touched.contactEmail && contactFormik.errors.contactEmail}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <TextField
                        label={'Website'}
                        name={'website'}
                        fullWidth
                        value={contactFormik.values.website}
                        onChange={contactFormik.handleChange}
                        error={contactFormik.touched.website && !!contactFormik.errors.website}
                        helperText={contactFormik.touched.website && contactFormik.errors.website}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <TextField
                        label={'Număr de telefon'}
                        name={'phoneNumber'}
                        fullWidth
                        value={contactFormik.values.phoneNumber}
                        onChange={contactFormik.handleChange}
                        error={contactFormik.touched.phoneNumber && !!contactFormik.errors.phoneNumber}
                        helperText={contactFormik.touched.phoneNumber && contactFormik.errors.phoneNumber}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <FormControlLabel
                        name={'acceptTerms'}
                        checked={contactFormik.values.acceptTerms}
                        onChange={contactFormik.handleChange}
                        control={<Checkbox/>}
                        label={<p>Sunt de acord cu <NavLink to={'/termeni-si-conditii'}>termenii si conditiile</NavLink>
                        </p>}
                    />
                    {contactFormik.touched.acceptTerms && !!contactFormik.errors.acceptTerms &&
                    <div style={{color: '#d32f2f', fontSize: '0.75rem', fontWeight: 400}}>{contactFormik.errors.acceptTerms}</div>}
                    <input type={'submit'} style={{display: 'none'}}/>
                </div>
            </form>
        );
    }

    const tryNavigate = async (index: number) => {
        let canNavigate = false;
        if (currentStep === 1) {
            let emailIsNotUnique = false;
            await FirebaseUtil.getDocs('Users').then(docs => {
                docs.forEach((doc) => {
                    if (doc.data()['email'] === authenticationFormik.values.email) {
                        emailIsNotUnique = true;
                        return;
                    }
                })
            })
            await FirebaseUtil.getDocs('Companies').then(docs => {
                docs.forEach((doc) => {
                    if (doc.data()['email'] === authenticationFormik.values.email) {
                        emailIsNotUnique = true;
                        return;
                    }
                })
            })
            if (emailIsNotUnique) {
                setEmailNotUnique(true);
                return;
            }
        }

        if (index > currentStep)
            nextStep(false);

        setEmailNotUnique(false);
        const steps = [authenticationFormik.isValid && !emailNotUnique, detailsFormik.isValid && isCuiValid, activityFormik.isValid, contactFormik.isValid];
        switch (index) {
            case 1:
                break;
            case 2:
                if (!steps[0]) return;
                break;
            case 3:
                if (!steps[0] || !steps[1]) return;
                break;
            case 4:
                if (!steps[0] || !steps[1] || !steps[2]) return;
                break;
            case 5:
                if (!steps[0] || !steps[1] || !steps[2] || !steps[3]) return;
                break;
        }
        setCurrentStep(index);
    }

    const nextStep = (withNavigation: boolean) => {
        switch (currentStep) {
            case 1:
                authenticationFormik.handleSubmit();
                break;
            case 2:
                detailsFormik.handleSubmit();
                break;
            case 3:
                activityFormik.handleSubmit();
                break;
            case 4:
                contactFormik.handleSubmit();
                break;
        }

        if (withNavigation) {
            tryNavigate(currentStep + 1);
        }
    }

    const checkCompany = async () => {
        // const url = cuiUrl + detailsFormik.values.cui;
        // let data : any;
        // setValidCompany(null);
        // await fetch(url)
        //     .then(response => response.json())
        //     .then(responseData => data = responseData);
        //
        // if(!data)
        // {
        //     setValidCompany(false);
        //     return;
        // }
        // const isValid = data.status === 200 && data.data['cod_inmatriculare'] === detailsFormik.values.nrOrdine.trim();
        // setValidCompany(isValid);
        // if(isValid){
        try {
            Object.keys(detailsFormik.values).forEach(k => {
                if (typeof detailsFormik.values[k] === 'string')
                    detailsFormik.values[k] = detailsFormik.values[k].trim()
            });
            Object.keys(activityFormik.values).forEach(k => {
                if (typeof activityFormik.values[k] === 'string')
                    activityFormik.values[k] = activityFormik.values[k].trim()
            });
            Object.keys(contactFormik.values).forEach(k => {
                if (typeof contactFormik.values[k] === 'string')
                    contactFormik.values[k] = contactFormik.values[k].trim()
            });
            const newCompany = await createUserWithEmailAndPassword(auth, authenticationFormik.values.email.trim(), authenticationFormik.values.password.trim());
            await FirebaseUtil.write('Companies', newCompany.user.uid, {
                email: authenticationFormik.values.email.trim(),
                ...detailsFormik.values,
                ...contactFormik.values,
                ...activityFormik.values,
                id: newCompany.user.uid,
                createdAt: new Date(),
                rejectionEmail: defaultRejectionMessage,
                applyEmail: defaultThanksForApplyingMessage,
                active: true
            });

            dispatch(snackbarSlice.actions.show({
                message: 'Inregistrare reușită!',
                severity: 'success',
            }));
        } catch (error: any) {
            console.error(error.message);
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Va rugam incercati din nou.',
                severity: 'error',
            }));
            navigate('/conectare');
        }
    }

    useEffect(() => {
        if (emailNotUnique) {
            setEmailNotUnique(false);
        }
    }, [authenticationFormik.values.email]);

    useEffect(() => {
        if (currentStep >= currentStepDetails.length) {
            checkCompany();
        }
    }, [currentStep]);

    useEffect(() => {

    });

    const validCompanyPlaceholder = () => {
        const checkSize = 100;//window.innerWidth / 10;

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'var(--dark-primary)'
            }}>
                <CheckRounded style={{width: checkSize, height: checkSize}}/>
                <div style={{fontSize: 20, color: 'var(--dark-primary)'}}>
                    Companie validata!
                </div>
            </div>
        );
    }

    return !user && !company ? (
        <div className={'register-page-container'}>
            <Button
                className={'new-job-button'}
                style={{
                    color: 'white',
                    border: '1px solid white',
                    padding: '10px 15px 10px 15px',
                    borderRadius: 50,
                    margin: 10,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    textTransform: 'uppercase',
                    transition: '0.35s',
                    boxShadow: 'none'
                }}
                onClick={() => navigate('/conectare')}>
                Înapoi la conectare
            </Button>

            <div className={'steps-container'}>
                <div className={'current-step-details'}>
                    <h2>{currentStep < currentStepDetails.length ? 'Pasul ' + currentStep : 'Finalizare'}</h2>
                    <p>{currentStep < currentStepDetails.length ? currentStepDetails[currentStep].description :
                        'Va rugam asteptati un moment pana verificam autenticitatea companiei.'}</p>
                </div>
                <div className={'text-container'}>
                    <div className={`step-row ${currentStep >= 1 ? 'active' : ''}`} onClick={() => tryNavigate(1)}>
                        <div className={'bullet'}>
                            {
                                currentStep > 1 ? <Check/> : '1'
                            }
                        </div>
                        <div className={'step-name'}>{currentStepDetails[1].name}</div>
                    </div>
                    <div className={`connector ${currentStep >= 2 ? 'active' : ''}`}/>
                    <div className={`step-row ${currentStep >= 2 ? 'active' : ''}`} onClick={() => tryNavigate(2)}>
                        <div className={'bullet'}>
                            {
                                currentStep > 2 ? <Check/> : '2'
                            }
                        </div>
                        <div className={'step-name'}>{currentStepDetails[2].name}</div>
                    </div>
                    <div className={`connector ${currentStep >= 3 ? 'active' : ''}`}/>
                    <div className={`step-row ${currentStep >= 3 ? 'active' : ''}`} onClick={() => tryNavigate(3)}>
                        <div className={'bullet'}>
                            {
                                currentStep > 3 ? <Check/> : '3'
                            }
                        </div>
                        <div className={'step-name'}>{currentStepDetails[3].name}</div>
                    </div>
                    <div className={`connector ${currentStep >= 4 ? 'active' : ''}`}/>
                    <div className={`step-row ${currentStep >= 4 ? 'active' : ''}`} onClick={() => tryNavigate(4)}>
                        <div className={'bullet'}>
                            {
                                currentStep > 4 ? <Check/> : '4'
                            }
                        </div>
                        <div className={'step-name'}>{currentStepDetails[4].name}</div>
                    </div>
                </div>
            </div>
            {
                currentStep < currentStepDetails.length ?
                    <div className={'form-display'}>
                        <div className={`form-container ${currentStep === 2 ? 'wide' : ''}`}>
                            {
                                renderForm()
                            }
                            <div className={'buttons-container'}>
                                {
                                    currentStep > 1 ?
                                        <Button style={{
                                            border: '1px solid var(--dark-primary)',
                                            padding: '5px 7.5px 5px 7.5px',
                                            borderRadius: 12,
                                        }}
                                                onClick={() => setCurrentStep(currentStep - 1)}
                                        >
                                            {'Pasul precedent'}
                                        </Button> : <div/>
                                }
                                <Button style={currentStep < currentStepDetails.length - 1 ? {
                                    border: '1px solid var(--dark-primary)',
                                    padding: '5px 7.5px 5px 7.5px',
                                    borderRadius: 12,
                                } : {
                                    color: 'white',
                                    backgroundColor: 'var(--dark-primary)',
                                    padding: '5px 7.5px 5px 7.5px',
                                    borderRadius: 12,
                                }}
                                        onClick={() => nextStep(true)}
                                >
                                    {currentStep < currentStepDetails.length - 1 ? 'Pasul urmator' : 'Finalizare'}
                                </Button>
                            </div>
                        </div>
                    </div> :
                    <div className={'form-display'}>
                        {
                            validCompany === null ? <CircularProgress
                                size={100}/> : validCompany ? validCompanyPlaceholder() : 'Companie invalida'
                        }
                    </div>
            }
        </div>
    ) : null;
}

const RegisterCompany = MotionHoc(RegisterCompanyComponent);

export default RegisterCompany;