import Authentication, {AuthenticationState} from "./Authentication";
import Snackbar, {SnackbarState} from "./Snackbar";
import Data, {DataState} from "./Data";

export interface ApplicationState{
    auth: AuthenticationState,
    snackbar: SnackbarState,
    data: DataState,
}

export const reducers = {
    auth: Authentication,
    snackbar: Snackbar,
    data: Data
};