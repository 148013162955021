import React, {useState} from 'react';
import MotionHoc from "../MotionHoc";
import {Box, Button, Container, Divider} from "@mui/material";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import RegisterHelp from "./RegisterHelp";
import './Help.scss';
import ProfileHelp from "./ProfileHelp";
import JobsHelp from "./JobsHelp";
import CandidatesHelp from "./CandidatesHelp";
import ApplicationsHelp from "./ApplicationsHelp";

const HelpComponent = () => {
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const [isCandidate, setIsCandidate] = useState<boolean>(!company);
    const [selection, setSelection] = useState<number>(company ? 0 : 1);

    return (
        <Container maxWidth={'xl'} className={'page-container help-page'}>
            <Box display={'flex'} maxWidth={'s'} justifyContent={'space-evenly'} paddingTop={'10px'}
                 paddingBottom={'10px'}>
                <Button style={{
                    fontSize: 18,
                    padding: 10,
                    textTransform: 'uppercase',
                    backgroundColor: isCandidate ? '#1F406D' : undefined,
                    color: isCandidate ? 'white' : 'black'
                }}
                        onClick={() => {
                            setIsCandidate(true);
                            if (selection === 3 || selection === 0) {
                                setSelection(1);
                            }
                        }}>
                    Candidat
                </Button>
                <Button style={{
                    fontSize: 18,
                    padding: 10,
                    textTransform: 'uppercase',
                    backgroundColor: !isCandidate ? '#1F406D' : undefined,
                    color: !isCandidate ? 'white' : 'black'
                }}
                        onClick={() => {
                            setIsCandidate(false);
                        }}>
                    Companie
                </Button>
            </Box>
            <Divider/>
            <Box display={'flex'} maxWidth={'s'} justifyContent={'space-evenly'} paddingTop={'10px'}
                 paddingBottom={'10px'}>
                {
                    !isCandidate && <Button style={{
                        fontSize: 16,
                        padding: '6px 10px',
                        textTransform: 'uppercase',
                        backgroundColor: selection === 0 ? '#1F406D' : undefined,
                        color: selection === 0 ? 'white' : 'black'
                    }}
                            onClick={() => {
                                setSelection(0);
                            }}>
                        Înregistrare
                    </Button>
                }
                <Button style={{
                    fontSize: 16,
                    padding: '6px 10px',
                    textTransform: 'uppercase',
                    backgroundColor: selection === 1 ? '#1F406D' : undefined,
                    color: selection === 1 ? 'white' : 'black'
                }}
                        onClick={() => {
                            setSelection(1);
                        }}>
                    Profil
                </Button>
                <Button style={{
                    fontSize: 16,
                    padding: '6px 10px',
                    textTransform: 'uppercase',
                    backgroundColor: selection === 2 ? '#1F406D' : undefined,
                    color: selection === 2 ? 'white' : 'black'
                }}
                        onClick={() => {
                            setSelection(2);
                        }}>
                    Joburi
                </Button>
                {
                    !isCandidate && <Button style={{
                        fontSize: 16,
                        padding: '6px 10px',
                        textTransform: 'uppercase',
                        backgroundColor: selection === 3 ? '#1F406D' : undefined,
                        color: selection === 3 ? 'white' : 'black'
                    }}
                            onClick={() => {
                                setSelection(3);
                            }}>
                        Candidați
                    </Button>
                }
                <Button style={{
                    fontSize: 16,
                    padding: '6px 10px',
                    textTransform: 'uppercase',
                    backgroundColor: selection === 4 ? '#1F406D' : undefined,
                    color: selection === 4 ? 'white' : 'black'
                }}
                        onClick={() => {
                            setSelection(4);
                        }}>
                    Aplicări
                </Button>
                {/*<Button style={{*/}
                {/*    fontSize: 16,*/}
                {/*    padding: '6px 10px',*/}
                {/*    textTransform: 'uppercase',*/}
                {/*    backgroundColor: selection === 5 ? '#1F406D' : undefined,*/}
                {/*    color: selection === 5 ? 'white' : 'black'*/}
                {/*}}*/}
                {/*        onClick={() => {*/}
                {/*            setSelection(5);*/}
                {/*        }}>*/}
                {/*    Setări*/}
                {/*</Button>*/}
            </Box>
            <Divider/>
            {
                selection === 0 && <RegisterHelp isCandidate={isCandidate} />
            }
            {
                selection === 1 && <ProfileHelp isCandidate={isCandidate} />
            }
            {
                selection === 2 && <JobsHelp isCandidate={isCandidate}/>
            }
            {
                selection === 3 && <CandidatesHelp />
            }
            {
                selection === 4 && <ApplicationsHelp isCandidate={isCandidate} />
            }
        </Container>
    );
}

const Help = MotionHoc(HelpComponent);

export default Help;