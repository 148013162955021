import React from 'react';
import {HelpPageProps} from "./types";
import {Box} from "@mui/material";

const CandidatesHelp = () => {
    return (
        <Box paddingTop={'10px'}>
            <h1 style={{marginBottom: 20}}>CANDIDATI</h1>
            <p>Această pagină de ajutor acoperă diferite părți ale modului în care funcționează PAGINA DE CANDIDATI.</p>
            <p>Pagina de candidati poate fi accesata din bara de navigatie, apasand pe butonul de <b>candidati</b>.
            </p>
            <img src={'images/candidati-1.png'} className={'help-page-vertical-image'}/>
            <div>
                <h2>CARD CANDIDAT</h2>
                <p>Cardul de candidate contine cateva informatii ale candidatului, cum ar fi: poza de profil, numele,
                    varsta, CV, jobul curent, compania la care lucreaza in prezent.</p>
                <p>Poti vedea profilul complet al candidatului apasand pe numelea acestuia.</p>
                <img src={'images/candidati-2.png'} className={'help-page-image'}/>

                <h2>FILTRARE JOBURI</h2>
                <p>Poti filtra candidatii cautand dupa nume sau aplicand anumite filtre prestabilite. Filtrele pot fi
                    modificate
                    folosind butonul de editare filtre.</p>
                <img src={'images/candidati-3.png'} className={'help-page-square-image'}/>
            </div>
        </Box>
    );
}

export default CandidatesHelp;