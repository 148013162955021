import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    TextField
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {defaultRejectionMessage} from "../defaults";

interface DialogProps {
    closeCallback: () => void;
    sendCallback: (newDescription: string) => void;
    userName: string;
}

const RejectionDialog = (props: DialogProps) => {
    const company = useSelector((s: ApplicationState) => s.auth.company)

    const [rejectionEmail, setRejectionEmail] = useState<string>(company?.rejectionEmail ?? defaultRejectionMessage);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const dispatch = useDispatch();

    const saveCallback = async () => {
        setIsLoading(true);
        props.sendCallback(rejectionEmail);
    }

    useEffect(() => {
        setIsFormValid(rejectionEmail.trim().length > 4);
    }, [rejectionEmail])

    return <Dialog
        open={true}
        onClose={(event, reason) => {
            if (reason && reason === 'backdropClick')
                return;
            props.closeCallback();
        }}
        className={'form-dialog'}
        maxWidth={'md'}
        fullWidth
    >
        <DialogTitle>{'Respinge aplicarea lui ' + props.userName}</DialogTitle>
        <DialogContent style={{paddingTop: 5, paddingBottom: 5}}>
            <div className={'form-row'}>
                <TextField
                    onChange={(e) => setRejectionEmail(e.target.value)}
                    label={'Mesaj respingere email'}
                    required
                    type={'text'}
                    fullWidth
                    value={rejectionEmail}
                    variant={'standard'}
                    className={'form-text-field'}
                    multiline
                    minRows={3}
                />
            </div>
        </DialogContent>
        <DialogActions>
            {
                !isLoading ? <>
                        <Button disabled={isLoading} onClick={props.closeCallback}
                                style={{
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.06em',
                                    color: 'black'
                                }}>Anulează</Button>
                        <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                            Respinge
                        </Button>
                    </> :
                    <CircularProgress size={30} style={{marginRight: 20}}/>
            }
        </DialogActions>
    </Dialog>
}

export default RejectionDialog;