import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Button, Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, InputLabel, MenuItem,
    Select,
    TextField,
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import './skillsDialog.scss';
import {snackbarSlice} from "../../../store/Snackbar";
import {ExperienceInformation, WorkTypeEnum} from "../Cards/CardItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import DateUtil from "../../Utils/DateUtil";
import {IndustriesEnum} from "../../CompanyProfile/Dialogs/CompanyProfileDialog";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    job: ExperienceInformation;
    isNewJob?: boolean;
    jobIndex?: number;
}

const ExperienceDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [job, setJob] = useState<ExperienceInformation>({
        ...props.job,
        startDate: props.job.startDate ? DateUtil.getDateFromTimestamp(props.job.startDate) : null,
        endDate: props.job.endDate ? DateUtil.getDateFromTimestamp(props.job.endDate) : null
    });
    const [currentlyWorking, setCurrentlyWorking] = useState(props.job.endDate === null && props.job.startDate !== null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsFormValid(job.positionName.length > 0 &&
            job.companyName.length > 0 &&
            !!job.startDate && !isNaN(job.startDate) &&
            !!job.workType &&
            job.location.length > 0 &&
            !!job.industry &&
            (currentlyWorking || (!!job.endDate && !isNaN(job.endDate))));
    }, [job, currentlyWorking]);

    const saveCallback = async () => {
        setIsLoading(true);
        let userExperience = [...user!.experience ?? []];

        // handleChange(job.startDate, 'startDate');
        // handleChange(job.endDate ?? '', 'endDate');

        if (props.isNewJob) {
            userExperience = [...userExperience, job];
        } else {
            userExperience[props.jobIndex!] = job;
        }

        userExperience = userExperience.sort((a, b) => {
            const aStart = a.startDate ? (a.startDate instanceof Date ? a.startDate : new Date(a.startDate.seconds * 1000))  : null;
            const aEnd = a.endDate ? (a.endDate instanceof Date ? a.endDate : new Date(a.endDate.seconds * 1000))  : null;;
            const bStart = b.startDate ? (b.startDate instanceof Date ? b.startDate : new Date(b.startDate.seconds * 1000))  : null;;
            const bEnd = b.endDate ? (b.endDate instanceof Date ? b.endDate : new Date(b.endDate.seconds * 1000))  : null;;

            if(!aEnd && !bEnd){
                if(aStart!.getTime() > bStart!.getTime())
                {
                    return -1;
                }
                return 1;
            }
            if(!aEnd) {
                return -1;
            }
            if(!bEnd){
                return 1;
            }
            if(aEnd.getTime() > bEnd.getTime())
                return -1;

            return 1;
        });

        await FirebaseUtil.write('Users', user!.id!,
            {
                experience: userExperience
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        );
        props.closeCallback();
    }

    const handleChange = (value: string | WorkTypeEnum | object | IndustriesEnum, key: string) => {
        const newJob = {...job};
        switch (key) {
            case 'positionName':
                newJob.positionName = value as string;
                break;
            case 'companyName':
                newJob.companyName = value as string;
                break;
            case 'workType':
                newJob.workType = value as WorkTypeEnum;
                break;
            case 'startDate':
                newJob.startDate = value as object;
                break;
            case 'endDate':
                newJob.endDate = value as object;
                break;
            case 'description':
                newJob.description = value as string;
                break;
            case 'location':
                newJob.location = value as string;
                break;
            case 'industry':
                newJob.industry = value as IndustriesEnum;
                break;
        }

        setJob(newJob);
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if(reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>
                {
                    props.isNewJob ? 'Adaugă experiență' : 'Editează experiență'
                }
            </DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <>
                    {
                        props.isNewJob &&
                        <div style={{fontSize: 14, color: '#666666', paddingBottom: 15, fontWeight: 300}}>
                            Adaugă orice experiență profesională care crezi ca e relevanta pentru tipul
                            de job pe care il cauti. Daca nu ai experiență profesională,
                            mentioneaza proiectele personale, internship-urile sau orice tip de
                            activitate valoroasa.
                        </div>
                    }
                    <TextField
                        label={'Functie'}
                        placeholder={'Ex: Manager'}
                        required
                        type={'text'}
                        fullWidth
                        value={job.positionName}
                        onChange={(e) => handleChange(e.target.value, 'positionName')}
                        variant={'standard'}
                        className={'form-text-field'}
                        error={false}
                    />
                    <div className={'form-row'} style={{alignItems: 'flex-end'}}>
                        <TextField
                            onChange={(e) => handleChange(e.target.value, 'companyName')}
                            label={'Companie'}
                            required
                            type={'text'}
                            fullWidth
                            value={job.companyName}
                            variant={'standard'}
                            placeholder={'Ex: Nokia'}
                            className={'form-text-field left'}
                        />
                        <div className={'form-text-field right'} style={{width: '100%'}}>
                            <InputLabel id={'select-label'} style={{fontSize: 12}}>Tipul de munca *</InputLabel>
                            <Select
                                onChange={(e) => handleChange(e.target.value as WorkTypeEnum, 'workType')}
                                // label={'Tipul de exprienta'}
                                labelId={'select-label'}
                                required
                                type={'text'}
                                fullWidth
                                value={job.workType}
                                variant={'standard'}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            borderRadius: '0 0 20px 20px'
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={WorkTypeEnum.fullTime}>{WorkTypeEnum.fullTime}</MenuItem>
                                <MenuItem value={WorkTypeEnum.partTime}>{WorkTypeEnum.partTime}</MenuItem>
                                <MenuItem value={WorkTypeEnum.internship}>{WorkTypeEnum.internship}</MenuItem>
                                <MenuItem value={WorkTypeEnum.selfEmployed}>{WorkTypeEnum.selfEmployed}</MenuItem>
                                <MenuItem value={WorkTypeEnum.contract}>{WorkTypeEnum.contract}</MenuItem>
                                <MenuItem value={WorkTypeEnum.freelance}>{WorkTypeEnum.freelance}</MenuItem>
                                <MenuItem value={WorkTypeEnum.personalProject}>{WorkTypeEnum.personalProject}</MenuItem>

                            </Select>
                        </div>
                    </div>
                    <Autocomplete
                        value={job.industry}
                        onChange={(e, v) => {
                            handleChange(v as IndustriesEnum, 'industry')
                        }}
                        fullWidth
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={'Domeniu'}
                                    required
                                    error={!job.industry}
                                    variant={'standard'}
                                    className={'form-text-field'}

                                />
                            );
                        }}
                        options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                        componentsProps={{
                            paper: {
                                style: {
                                    borderRadius: '0 0 20px 20px'
                                }
                            }
                        }}/>
                    <div className={'form-row'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label={'Data inceperii'}
                                inputFormat={'dd/MM/yyyy'}
                                value={job.startDate ? job.startDate : null}
                                onChange={(value) => handleChange(value ?? '', 'startDate')}
                                renderInput={(params) => <TextField
                                    required
                                    variant={'standard'}
                                    type={'date'}
                                    className={'form-text-field left'}
                                    fullWidth
                                    error={params.error}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'ZZ/LL/AAAA',
                                    }}
                                />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                disabled={currentlyWorking}
                                label={'Data finalizarii'}
                                inputFormat={'dd/MM/yyyy'}
                                value={job.endDate && !currentlyWorking ? job.endDate : null}
                                onChange={(value) => {
                                    handleChange(value, 'endDate')
                                }}
                                renderInput={(params) => {
                                    return <TextField
                                        variant={'standard'}
                                        required
                                        type={'date'}
                                        className={'form-text-field right'}
                                        fullWidth
                                        error={params.error}
                                        InputLabelProps={{shrink: currentlyWorking ? true : undefined}}
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: currentlyWorking ? 'Prezent' : 'ZZ/LL/AAAA',
                                        }}
                                    />
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className={'form-text-field'}
                         style={{marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentlyWorking}
                                    onChange={(e) => {
                                        setCurrentlyWorking(e.target.checked)
                                    }}
                                />}
                            label={'Lucrez aici in prezent'}/>
                    </div>
                    <TextField
                        onChange={(e) => handleChange(e.target.value, 'location')}
                        label={'Oras'}
                        required
                        placeholder={'Ex: Resita'}
                        type={'text'}
                        fullWidth
                        value={job.location}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                    <TextField
                        onChange={(e) => handleChange(e.target.value, 'description')}
                        label={'Descriere'}
                        type={'text'}
                        fullWidth
                        value={job.description}
                        variant={'standard'}
                        multiline={true}
                        minRows={2}
                        className={'form-text-field'}
                        placeholder={'Responsabilitati, reusite, ce ai invatat etc.'}
                    />
                </>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default ExperienceDialog;