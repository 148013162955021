import React from 'react';
import {Container} from "@mui/material";
import './terms.scss'
import MotionHoc from "./MotionHoc";

const TermsComponent = () => {
    return (
        <Container maxWidth={'xl'} className={'page-container terms-page'}
                   style={{overflowWrap: 'anywhere'}}>
            <div style={{padding: '30px 20px'}}>
                <h1>Politică de Confidențialitate</h1>
                <h2>Generalități</h2>
                <p>Regulamentul 2016/679 privind protecţia persoanelor fizice &icirc;n ceea ce priveşte prelucrarea
                    datelor
                    cu
                    caracter personal şi privind libera circulaţie a acestor date (General Data Protection
                    Regulation, &icirc;n
                    prezentul document &ndash; GDPR, Regulament sau RGPD) a fost adoptat de Parlamentul European și
                    Consiliul
                    Uniunii Europene &icirc;n 27 aprilie 2016, prevederile acestuia fiind direct
                    aplicabile &icirc;ncep&acirc;nd cu
                    data de 25 mai 2018. Acest Regulament abrogă, &icirc;n mod expres, Directiva
                    95/46/CE, &icirc;nlocuind
                    astfel și
                    prevederile Legii nr. 677/2001 (&icirc;n prezent, abrogate).</p>
                <p>Regulamentul este direct aplicabil &icirc;n toate statele membre, protej&acirc;nd drepturile tuturor
                    persoanelor
                    fizice aflate pe teritoriul Uniunii Europene. Din punct de vedere material, Regulamentul se aplică
                    tuturor
                    operatorilor care prelucrează date cu caracter personal. Regulamentul nu se aplică prelucrării
                    datelor
                    cu
                    caracter personal care privesc persoane juridice şi, &icirc;n special, &icirc;ntreprinderi cu
                    personalitate
                    juridică, inclusiv numele şi tipul de persoană juridică şi datele de contact ale persoanei
                    juridice.</p>
                <p>Datele cu caracter personal sunt definite ca fiind orice informaţii privind o persoană fizică
                    identificată sau
                    identificabilă ("persoana vizată"); o persoană fizică identificabilă este o persoană care poate fi
                    identificată,
                    direct sau indirect, &icirc;n special prin referire la un element de identificare, cum ar fi un
                    nume, un
                    număr
                    de identificare, date de localizare, un identificator online sau la unul sau mai multe elemente
                    specifice,
                    proprii identităţii sale fizice, fiziologice, genetice, psihice, economice, culturale sau
                    sociale.</p>
                <p>Prelucrarea datelor cu caracter personal presupune orice operaţiune sau set de operaţiuni efectuate
                    asupra
                    datelor sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace
                    automatizate, cum
                    ar fi colectarea, &icirc;nregistrarea, organizarea, structurarea, stocarea, adaptarea sau
                    modificarea,
                    extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la
                    dispoziţie &icirc;n
                    orice alt mod, alinierea sau combinarea, restricţionarea, ştergerea sau distrugerea.</p>
                <h2>Identitatea operatorului</h2>
                <p>Av&acirc;nd &icirc;n vedere articolul 4 punctul 7 din Regulament, care definește noțiunea de
                    "operator"
                    ca fiind
                    persoana fizică sau juridică, autoritatea publică, agenţia sau alt organism care, singur
                    sau &icirc;mpreună cu
                    altele, stabileşte scopurile şi mijloacele de prelucrare a datelor cu caracter personal, operatorul
                    care
                    prelucrează date cu caracter personal prin intermediul acestui website este Municipiul Resita, cu
                    sediul &icirc;n
                    Resita, &icirc;nmatriculat la Oficiul Registrului Comerţului X11/2332/1980, av&acirc;nd CUI 3228764,
                    cu date de contact: biroupresa@primariaresita.ro.</p>
                <h2>Colectarea datelor personale</h2><h3>Care sunt datele cu caracter personal colectate</h3>
                <p>Operatorul acestui website colectează, stochează şi prelucrează următoarele date personale ale /
                    referitoare la
                    dvs.:</p>
                <ul>
                    <li>Nume, prenume</li>
                    <li>Adresa de domiciliu și/sau de reședință</li>
                    <li>Date de contact (precum e-mail, telefon, fax)</li>
                    <li>Imagini vizuale sau secvențe (fotografii, video)</li>
                    <li>Date colectate prin platforme de Cereri de angajare</li>
                </ul>
                <p>Datele furnizate de dumneavoastră privind conținutul CV-ului sunt prelucrate &icirc;n temeiul art. 6
                    alin. 1 lit.
                    a) și b) din Regulament. Prelucrarea acestor date are loc astfel:</p>
                <p></p>
                <ul>

                    <li>Prin &icirc;nregistrarea de către dvs. a CV-ului &icirc;n baza noastră de date și prin
                        consimțăm&acirc;ntul
                        expres dat &icirc;n privința accesului angajatorilor/terților la conținutul acestuia
                    </li>
                </ul>
                <p></p>
                <p>Av&acirc;nd &icirc;n vedere că Regulamentul interzice, &icirc;n principal, &ldquo;prelucrarea de date
                    cu
                    caracter
                    personal care dezvăluie originea rasială sau etnică, opiniile politice, confesiunea religioasă sau
                    convingerile
                    filozofice sau apartenenţa la sindicate şi prelucrarea de date genetice, de date biometrice pentru
                    identificarea
                    unică a unei persoane fizice, de date privind sănătatea sau de date privind viaţa sexuală sau
                    orientarea
                    sexuală
                    ale unei persoane fizice&rdquo; (conform art. 9 alin. 1), sunt stabilite apoi situațiile &icirc;n
                    care
                    este
                    permisă prelucrarea unor astfel de date:</p>
                <ol style={{listStyleType: 'lower-alpha'}}>
                    <li>există consimțăm&acirc;ntul explicit al persoanei vizate;</li>
                    <li>prelucrarea este necesară &icirc;n scopul &icirc;ndeplinirii obligaţiilor şi al exercitării unor
                        drepturi
                        specifice ale operatorului sau ale persoanei vizate &icirc;n domeniul ocupării forţei de muncă
                        şi al
                        securităţii sociale şi protecţiei sociale
                    </li>
                    <li>prelucrarea este necesară pentru protejarea intereselor vitale ale persoanei vizate sau ale unei
                        alte
                        persoane fizice, atunci c&acirc;nd persoana vizată se află &icirc;n incapacitate fizică sau
                        juridică
                        de a-şi
                        da consimţăm&acirc;ntul;
                    </li>
                    <li>prelucrarea este efectuată &icirc;n cadrul activităţilor lor legitime şi cu garanţii adecvate de
                        către o
                        fundaţie, o asociaţie sau orice alt organism fără scop lucrativ şi cu specific politic,
                        filozofic,
                        religios
                        sau sindical, cu condiţia ca prelucrarea să se refere numai la membrii sau la foştii membri ai
                        organismului
                        respectiv sau la persoane cu care acesta are contacte permanente &icirc;n legătură cu scopurile
                        sale
                        şi ca
                        datele cu caracter personal să nu fie comunicate terţilor fără consimţăm&acirc;ntul persoanelor
                        vizate;
                    </li>
                    <li>prelucrarea se referă la date cu caracter personal care sunt făcute publice &icirc;n mod
                        manifest de
                        către
                        persoana vizată;
                    </li>
                    <li>prelucrarea este necesară pentru constatarea, exercitarea sau apărarea unui drept &icirc;n
                        instanţă
                        sau ori
                        de c&acirc;te ori instanţele acţionează &icirc;n exerciţiul funcţiei lor judiciare;
                    </li>
                    <li>prelucrarea este necesară din motive de interes public major, &icirc;n baza dreptului Uniunii
                        sau a
                        dreptului intern, care este proporţional cu obiectivul urmărit, respectă esenţa dreptului la
                        protecţia
                        datelor şi prevede măsuri corespunzătoare şi specifice pentru protejarea drepturilor
                        fundamentale şi
                        a
                        intereselor persoanei vizate;
                    </li>
                    <li>prelucrarea este necesară &icirc;n scopuri legate de medicina preventivă sau a muncii, de
                        evaluarea
                        capacităţii de muncă a angajatului, de stabilirea unui diagnostic medical, de furnizarea de
                        asistenţă
                        medicală sau socială sau a unui tratament medical sau de gestionarea sistemelor şi serviciilor
                        de
                        sănătate
                        sau de asistenţă socială, &icirc;n temeiul dreptului Uniunii sau al dreptului intern
                        sau &icirc;n
                        temeiul
                        unui contract &icirc;ncheiat cu un cadru medical şi sub rezerva respectării condiţiilor şi
                        garanţiilor
                        prevăzute la alineatul (3);
                    </li>
                    <li>prelucrarea este necesară din motive de interes public &icirc;n domeniul sănătăţii publice, cum
                        ar
                        fi
                        protecţia &icirc;mpotriva ameninţărilor transfrontaliere grave la adresa sănătăţii sau
                        asigurarea de
                        standarde ridicate de calitate şi siguranţă a asistenţei medicale şi a medicamentelor sau a
                        dispozitivelor
                        medicale, &icirc;n temeiul dreptului Uniunii sau al dreptului intern, care prevede măsuri
                        adecvate
                        şi
                        specifice pentru protejarea drepturilor şi libertăţilor persoanei vizate, &icirc;n special a
                        secretului
                        profesional; sau
                    </li>
                    <li>prelucrarea este necesară &icirc;n scopuri de arhivare &icirc;n interes public, &icirc;n scopuri
                        de
                        cercetare ştiinţifică sau istorică ori &icirc;n scopuri statistic, proporţional cu obiectivul
                        urmărit,
                        respect&acirc;nd esenţa dreptului la protecţia datelor şi prevede măsuri corespunzătoare şi
                        specifice pentru
                        protejarea drepturilor fundamentale şi a intereselor persoanei vizate.
                    </li>
                </ol>
                <h2>Obținerea Consimțământului</h2><h3>Generalități</h3>
                <p>Pentru ca prelucrarea datelor cu caracter personal să fie legală, GDPR prevede ca aceasta să fie
                    efectuată &icirc;n baza unui motiv legitim, precum executarea sau &icirc;ncheierea unui
                    contract, &icirc;ndeplinirea unei obligații legale, sau &icirc;n baza consimțăm&acirc;ntului valabil
                    exprimat de
                    persoana vizată &icirc;n prealabil. &Icirc;n acest din urmă caz, operatorului i se impune obligația
                    de a
                    putea
                    demonstra faptul că persoana &icirc;n cauză și-a dat consimțăm&acirc;ntul pentru prelucrarea
                    respectivă.
                    Consimțăm&acirc;ntul exprimat sub imperiul Directivei 95/46/CE răm&acirc;ne valabil dacă
                    acesta &icirc;ndeplinește condițiile prevăzute de GDPR.</p>
                <p>Acordarea consimțăm&acirc;ntului trebuie să fie realizată printr-o declarație sau printr-o acțiune
                    neechivocă
                    care să constituie o manifestare liber exprimată, specifică, &icirc;n cunoştinţă de cauză şi clară a
                    acordului
                    persoanei vizate pentru prelucrarea datelor sale cu caracter personal. &Icirc;n cazul &icirc;n care
                    consimțăm&acirc;ntul persoanei vizate este dat &icirc;n contextul unei declarații, &icirc;n format
                    electronic
                    sau &icirc;n scris, care se referă și la alte aspecte, cererea privind consimțăm&acirc;ntul trebuie
                    să
                    fie
                    prezentată &icirc;ntr-o formă care o diferențiază &icirc;n mod clar de celelalte aspecte,
                    put&acirc;nd
                    fi
                    realizată chiar și prin bifarea unei căsuțe. Pentru ca prelucrarea datelor cu caracter personal să
                    fie
                    legală,
                    GDPR prevede ca aceasta să fie efectuată &icirc;n baza unui motiv legitim, precum executarea
                    sau &icirc;ncheierea unui contract, &icirc;ndeplinirea unei obligații legale, sau &icirc;n baza
                    consimțăm&acirc;ntului valabil exprimat de persoana vizată &icirc;n prealabil. &Icirc;n acest din
                    urmă
                    caz,
                    operatorului i se impune obligația de a putea demonstra faptul că persoana &icirc;n cauză și-a dat
                    consimțăm&acirc;ntul pentru prelucrarea respectivă. Consimțăm&acirc;ntul exprimat sub imperiul
                    Directivei
                    95/46/CE răm&acirc;ne valabil dacă acesta &icirc;ndeplinește condițiile prevăzute de GDPR.</p>
                <p><h3>Înregistrarea pe site</h3></p>
                <p>Vă puteţi &icirc;nregistra pe acest website pentru a accesa funcţii şi servicii adiţionale oferite de
                    compania
                    noastră. &Icirc;n acest sens, datele introduse de dvs. vor fi utilizate şi prelucrate cu scopul
                    folosirii
                    respectivului serviciu sau funcţii pentru care v-aţi &icirc;nregistrat. Datele obligatorii
                    solicitate
                    la &icirc;nregistrare trebuie furnizate de către dvs. &icirc;n &icirc;ntregime, &icirc;n caz contrar
                    operaţiunea
                    de &icirc;nregistrare urm&acirc;nd a fi respinsă.</p>
                <p>Pentru a vă informa despre modificări importante, cum ar fi cele din sfera site-ului nostru sau
                    despre
                    schimbările tehnice, vom folosi adresa de email specificată de dvs. la
                    momentul &icirc;nregistrării.</p>
                <p>Prelucrarea datelor cu caracter personal, furnizate &icirc;n procedura de &icirc;nregistrare, se face
                    doar cu
                    consimţăm&acirc;ntul dvs. şi cu respectarea dispoziţiilor art. 6 alin. 1 lit. a) GDPR. Puteți să vă
                    revocați
                    oric&acirc;nd acordul, un e-mail informal &icirc;n acest sens fiind suficient. Vom continua să
                    stocăm
                    datele
                    colectate &icirc;n timpul &icirc;nregistrării at&acirc;t timp c&acirc;t veţi
                    răm&acirc;ne &icirc;nregistrat pe
                    acest website, &icirc;nsă perioadele obligatorii de stocare răm&acirc;n valabile şi vor fi
                    respectate.</p>
                <p><h3>Autentificare prin Facebook</h3></p>
                <p>Dacă nu doriţi să vă &icirc;nregistraţi direct pe acest site, puteţi să vă conectaţi prin Facebook
                    (Connect).
                    Acest serviciu este prestat de Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
                    Ireland.</p>
                <p>Pentru a vă conecta utiliz&acirc;nd Facebook, apăsaţi &bdquo;Login with
                    facebook&rdquo; sau &bdquo;Connect with
                    Facebook&rdquo; şi veţi fi redirecţionat automat către această platformă, unde vă vor fi solicitate
                    detaliile
                    privind user-ul şi parola şi astfel vă veţi conecta la acest website cu profilul de
                    Facebook. &Icirc;n
                    acest
                    mod, vom avea acces la toate datele stocate pe Facebook, spre exemplu (şi fără a se limita la):</p>
                <ul>
                    <li>Nume</li>
                    <li>Poza de profil</li>
                    <li>Adresa de email</li>
                    <li>Facebook ID</li>
                    <li>Reţeaua de prieteni</li>
                    <li>Aprecierile (Facebook likes)</li>
                    <li>Ziua de naştere</li>
                    <li>Genul/Sexul</li>
                    <li>Ţara</li>
                    <li>Limba</li>
                </ul>
                <p>Aceste date vor fi folosite pentru a crea şi personaliza contul dvs. pe acest website.</p>
                <p>Pentru mai multe informaţii, puteţi accesa Termenii şi Condiţiile Facebook, precum şi Politica de
                    confidenţialitate, puse la dispoziţie pe <a href="https://www.facebook.com/privacy" target="_blank"
                                                                rel="noopener">https://www.facebook.com/privacy</a> şi
                    pe <a
                        href="https://www.facebook.com/legal/terms" target="_blank"
                        rel="noopener">https://www.facebook.com/legal/terms</a>.</p>
                <p>Av&acirc;nd &icirc;n vedere Hotăr&acirc;rea din 16 iulie 2020 <em>(pronunțată &icirc;n cauza C-311/18
                    -
                    Data
                    Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems)</em>, Curtea Europeană de
                    Justiţie a
                    statuat că protecţia oferită de Scutul de confidențialitate (Privacy Shield) EU &ndash; US nu are un
                    caracter
                    adecvat.</p>
                <p>Prin urmare, transmiterea datelor cu caracter personal către SUA şi alte ţări din afara Spaţiului
                    Economic
                    European (EEA &ndash; European Economic Area) se bazează pe Clauzele Contractuale Standard
                    (SCC &ndash; Standard
                    Contractual Clauses) ale Comisiei Europene. Comisia a emis două seturi de clauze contractuale
                    standard
                    pentru
                    transferurile de date de la operatorii de date din UE către operatorii de date stabiliți &icirc;n
                    afara
                    UE sau a
                    Spațiului Economic European (SEE). De asemenea, a emis şi un set de clauze contractuale pentru
                    transferurile de
                    date de la operatorii din UE către procesatorii stabiliți &icirc;n afara UE sau SEE. Pentru mai
                    multe
                    informaţii
                    privind aceste Clauze, recomandăm să accesaţi <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro"
                        target="_blank"
                        rel="noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.
                </p>
                <p>Facebook foloseşte Clauze Contractuale Standard drept garanţie adecvată privind protecţia
                    datelor, &icirc;n acord
                    cu nivelul de protecţie garantat prin GDPR.</p>
                <p>Pentru mai multe detalii, accesaţi: <a
                    href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                    target="_blank"
                    rel="noopener">https://www.facebook.com/legal/EU_data_transfer_addendum</a>
                </p>
                <h2>Scopul prelucrării datele colectate</h2>
                <p>O parte din datele colectate pe acest site sunt utilizate pentru:</p>
                <p></p>
                <ul>

                    <li>Prestarea serviciilor pe care le oferim &icirc;n beneficiul dumneavoastră (spre exemplu, pentru
                        soluționarea
                        problemelor de orice natură referitoare la produsele și serviciile noastre, pentru asigurarea
                        serviciilor de
                        suport etc.)
                    </li>
                    <li>Informare periodică a utilizatorilor - Vrem să vă ținem la curent cu privire la ofertele
                        noastre. &Icirc;n
                        acest sens, vă putem trimite orice tip de mesaj conțin&acirc;nd informații generale și tematice,
                        informații
                        cu privire la oferte sau promoții, precum și alte comunicări comerciale cum ar fi cercetări de
                        piață
                        și
                        sondaje de opinie. Pentru comunicările de acest tip, avem ca temei consimțăm&acirc;ntul
                        obţinut &icirc;n
                        prealabil de la dumneavoastră. Vă puteți răzg&acirc;ndi și retrage consimțăm&acirc;ntul &icirc;n
                        orice
                        moment.
                    </li>
                </ul>
                <p></p>
                <p>Prelucrarea datelor cu caracter personal se realizează &icirc;n conformitate cu prevederile
                    Regulamentului
                    General privind Protecția Datelor, av&acirc;nd la bază at&acirc;t consimțăm&acirc;ntul persoanei
                    vizate,
                    c&acirc;t și rațiuni de executare conformă a contractelor sau de realizare a intereselor legitime
                    ale
                    operatorului (cu excepţia cazului &icirc;n care prevalează interesele sau drepturile şi libertăţile
                    fundamentale
                    ale persoanei vizate, care necesită protejarea datelor cu caracter personal, &icirc;n special atunci
                    c&acirc;nd
                    persoana vizată este un copil).</p>
                <p><h2>Drepturile utilizatorilor</h2></p>
                <p>Drepturile dumneavoastră privind datele cu caracter personal şi mijloacele de exercitare a acestora
                    sunt:
                    Dreptul
                    de informare, Dreptul de acces, Dreptul la rectificare, Dreptul la ştergerea datelor, Dreptul la
                    restricţionarea
                    prelucrării, Dreptul la portabilitatea datelor, Dreptul la opoziţie, Dreptul de a nu face obiectul
                    unei
                    decizii
                    bazate exclusiv pe prelucrarea automată a datelor, Dreptul de a depune pl&acirc;ngere și de a vă
                    adresa
                    instanțelor judecătorești, Dreptul de retragere a consimțăm&acirc;ntului.</p>
                <ul>
                    <li><strong>Dreptul de informare</strong> - puteți solicita informații privind activitățile de
                        prelucrare a
                        datelor dvs. personale, privind identitatea operatorului și a reprezentantului acestuia sau cu
                        privire la
                        destinatarii datelor dvs.;
                    </li>
                    <li><strong>Dreptul de acces</strong> &ndash; puteţi obţine din partea operatorului o confirmare că
                        se
                        prelucrează sau nu date cu caracter personal care vă privesc şi, &icirc;n caz afirmativ, acces
                        la
                        datele
                        respective şi la următoarele informaţii : scopurile prelucrării; categoriile de date cu caracter
                        personal
                        vizate; destinatarii sau categoriile de destinatari cărora datele cu caracter personal le-au
                        fost
                        sau
                        urmează să le fie divulgate, &icirc;n special destinatari din ţări terţe sau organizaţii
                        internaţionale ;
                        acolo unde este posibil, perioada pentru care se preconizează că vor fi stocate datele cu
                        caracter
                        personal
                        sau, dacă acest lucru nu este posibil, criteriile utilizate pentru a stabili această perioadă ;
                        dreptul de a
                        solicita operatorului rectificarea sau ştergerea datelor cu caracter personal ori
                        restricţionarea
                        prelucrării datelor cu caracter personal sau dreptul de a se opune prelucrării etc.
                    </li>
                    <li><strong>Dreptul la rectificare</strong> - puteți rectifica datele personale inexacte sau le
                        puteți
                        completa;
                    </li>
                    <li><strong>Dreptul la ştergerea datelor</strong> - puteți obține ștergerea datelor, &icirc;n
                        cazul &icirc;n
                        care prelucrarea acestora nu a fost legală sau &icirc;n alte cazuri prevăzute de lege;
                    </li>
                    <li><strong>Dreptul la restricţionarea prelucrării</strong> - puteți solicita restricționarea
                        prelucrării &icirc;n cazul &icirc;n care contestați exactitatea datelor, precum şi &icirc;n alte
                        cazuri
                        prevăzute de lege;
                    </li>
                    <li><strong>Dreptul la portabilitatea datelor</strong> - puteți primi, &icirc;n anumite condiții,
                        datele
                        personale pe care ni le-ați furnizat, &icirc;ntr-un format care poate fi citit automat sau
                        puteți
                        solicita
                        ca respectivele date să fie transmise altui operator
                    </li>
                    <li><strong>Dreptul la opoziţie</strong> - vă puteți opune &icirc;n special, prelucrărilor de date
                        care
                        se &icirc;ntemeiază pe interesul legitim al operatorului;
                    </li>
                    <li><strong>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată a
                        datelor</strong> - puteți cere și obține intervenția umană cu privire la respectiva prelucrare
                        sau
                        vă puteți
                        exprima propriul punct de vedere cu privire la acest tip de prelucrare;
                    </li>
                    <li><strong>Dreptul de a depune pl&acirc;ngere și de a vă adresa instanțelor
                        judecătorești</strong> -
                        puteți
                        depune pl&acirc;ngere față de modalitatea de prelucrare a datelor personale la Autoritatea
                        Națională
                        de
                        Supraveghere a Prelucrării Datelor cu Caracter Personal și / sau vă puteți adresa instanțelor
                        judecătorești
                        pentru respectarea drepturilor dvs.;
                    </li>
                    <li><strong>Dreptul de retragere a consimțăm&acirc;ntului</strong> &ndash; &icirc;n
                        cazurile &icirc;n
                        care
                        prelucrarea se &icirc;ntemeiază pe consimțăm&acirc;ntul dvs., vi-l puteți retrage oric&acirc;nd.
                        Retragerea
                        consimțăm&acirc;ntului va avea efecte doar pentru viitor, prelucrarea efectuată anterior
                        retragerii
                        răm&acirc;n&acirc;nd &icirc;n continuare valabilă.
                    </li>
                </ul>
                <p><h2>Obligațiile operatorului de date</h2><h3>Găzduire/Hosting</h3></p>
                <p>Datele cu caracter personal &icirc;nregistrate pe acest website sunt stocate pe serverele Google
                    Firebase.
                    Prelucrarea datelor furnizate și stocate respectă următoarele prevederi legale:</p>
                <ul>
                    <li>art. 6 alin. 1 lit. a) GDPR - prelucrarea datelor de către Google Firebase&nbsp;se realizează
                        av&acirc;nd la
                        bază consimțăm&acirc;ntul dvs., obținut ulterior unei corecte și complete informări;
                    </li>
                    <li>art. 6 alin. 1 lit. b) GDPR - prelucrarea datelor de către Google Firebase&nbsp;are loc cu
                        scopul &icirc;ndeplinirii obligațiilor contractuale asumate;
                    </li>
                    <li>art. 6 alin. 1 lit. f) GDPR - prelucrarea datelor de către Google Firebase&nbsp;se
                        realizează &icirc;n
                        scopul intereselor legitime urmărite de operator&nbsp;</li>
                </ul>
                <p>Indiferent de scopul pentru care are loc prelucrarea datelor cu caracter personal, sunt respectate
                    principiile de
                    legalitate, echitate și transparență, dar și cel potrivit căruia datele cu caracter personal
                    prelucrate
                    sunt
                    adecvate, relevante şi limitate la ceea ce este necesar &icirc;n raport cu scopurile &icirc;n care
                    sunt
                    prelucrate.</p>
                <p>Pentru mai multe informații privind prelucrarea datelor cu caracter personale de către Google
                    Firebase,
                    accesați <a href="https://firebase.google.com/support/privacy" target="_blank"
                                rel="noopener">https://firebase.google.com/support/privacy</a></p>
                <p></p>
                <p>Avem un contract/o convenție/act juridic (incluz&acirc;nd posibilitatea de includere și agreere a
                    clauzelor din
                    Termenii și Condițiile website-ului) &icirc;ncheiat cu Google Firebase&nbsp;pentru a asigura
                    prelucrarea
                    datelor
                    cu caracter personal &icirc;n conformitate cu reglementările legale &icirc;n domeniu. Respectăm
                    obligaţiile care
                    ne incumbă potrivit articolului 28 din GDPR, prin alegerea unui furnizor extern de servicii care
                    oferă
                    garanţii
                    suficiente pentru punerea &icirc;n aplicare a unor măsuri tehnice şi organizatorice adecvate,
                    astfel &icirc;nc&acirc;t prelucrarea să respecte cerinţele prevăzute &icirc;n regulament şi să
                    asigure
                    protecţia
                    drepturilor dumneavoastră.</p>
                <p></p>
                <p><h3>Criptarea datelor</h3></p>
                <p>Acest site utilizează criptare SSL din raţiuni de securitate şi pentru protecţia transmiterii
                    informaţiilor
                    confidenţiale. Această criptare poate fi recunoscută de dvs. după fereastra de blocare (&bdquo;lock
                    icon&rdquo;)
                    care apare &icirc;n bara browser-ului şi prin schimbarea din http:// &icirc;n https:// a adresei
                    browser-ului
                    respectiv. Odată activată criptarea de acest tip, datele transmise sau transferate nu vor putea fi
                    văzute de
                    terţi.</p>
                <p>Conform GDPR, &icirc;n cazul &icirc;n care &icirc;ncălcarea securităţii datelor cu caracter personal
                    este
                    susceptibilă să genereze un risc ridicat pentru drepturile şi libertăţile dvs., operatorul acestui
                    website vă va
                    informa, fără &icirc;nt&acirc;rzieri nejustificate, cu privire la această &icirc;ncălcare, dacă nu
                    devin
                    incidente prevederile completatoare din acelaşi Regulament (art. 34 alin. 3).</p>
                <p><h3>Responsabilul cu protecția datelor</h3></p>
                <p>Nefiind aplicabile prevederile GDPR privind obligativitatea numirii unui Responsabil cu protecţia
                    datelor
                    (art.
                    37 alin. 1 - <em>potrivit căruia Operatorul şi persoana &icirc;mputernicită de operator desemnează
                        un
                        responsabil cu protecţia datelor ori de c&acirc;te ori:</em></p>
                <ol style={{listStyleType: 'lower-alpha'}}>
                    <li><em>prelucrarea este efectuată de o autoritate sau un organism public, cu excepţia instanţelor
                        care
                        acţionează &icirc;n exerciţiul funcţiei lor jurisdicţionale;</em></li>
                    <li><em>activităţile principale ale operatorului sau ale persoanei &icirc;mputernicite de operator
                        constau &icirc;n operaţiuni de prelucrare care, prin natura, domeniul de aplicare şi/sau
                        scopurile
                        lor,
                        necesită o monitorizare periodică şi sistematică a persoanelor vizate pe scară largă; sau</em>
                    </li>
                    <li><em>activităţile principale ale operatorului sau ale persoanei &icirc;mputernicite de operator
                        constau &icirc;n prelucrarea pe scară largă a unor categorii speciale de date &icirc;n temeiul
                        articolului 9
                        sau a unor date cu caracter personal referitoare la condamnări penale şi infracţiuni, menţionată
                        la
                        articolul 10</em>)
                    </li>
                </ol>
                <p>pentru orice informaţii sau lămuriri privind funcţionarea acestui website, vă rugăm să ne contactaţi
                    la
                    următoarele date:</p>
                <ul>
                    <li>Nume: Primaria Reșița</li>
                    <li>E-mail: contact@resita-jobs.ro</li>
                </ul>
                <p><h3>Evidențele activităților de prelucrare</h3></p>
                <p>Conform Regulamentului GDPR, operatorul sau persoana &icirc;mputernicită de operator ar trebui să
                    păstreze, pe o
                    perioadă rezonabilă, evidenţe ale activităţilor de prelucrare aflate &icirc;n responsabilitatea sa.
                    Astfel,
                    aceste evidențe vor cuprinde următoarele informații:</p>
                <ul>
                    <li>numele şi datele de contact ale operatorului&nbsp;</li>
                    <li>scopurile prelucrării;</li>
                    <li>descrierea categoriilor de persoane vizate şi a categoriilor de date cu caracter personal;</li>
                    <li>categoriile de destinatari cărora le-au fost sau le vor fi divulgate datele cu caracter
                        personal;
                    </li>
                    <li>dacă este cazul:
                        <ul>
                            <li>transferurile de date cu caracter personal</li>
                            <li>termenele-limită preconizate pentru ştergerea diferitelor categorii de date</li>
                            <li>o descriere generală a măsurilor tehnice şi organizatorice de securitate</li>
                        </ul>
                    </li>
                </ul>
                <p>Obligația detaliată anterior nu se aplică unei &icirc;ntreprinderi sau organizaţii cu mai puţin de
                    250 de
                    angajaţi, cu excepţia cazului &icirc;n care prelucrarea pe care o efectuează este susceptibilă să
                    genereze un
                    risc pentru drepturile şi libertăţile persoanelor vizate, prelucrarea nu este ocazională sau
                    prelucrarea
                    include
                    categorii speciale de date sau date cu caracter personal referitoare la condamnări penale şi
                    infracţiuni.</p>
                <h3>Măsuri tehnice și organizatorice adecvate</h3>
                <p>Av&acirc;nd &icirc;n vedere stadiul actual al tehnologiei, contextul şi scopurile prelucrării, precum
                    şi
                    riscurile pentru drepturile şi libertăţile persoanelor fizice, operatorul pune &icirc;n aplicare
                    măsuri
                    tehnice
                    şi organizatorice adecvate pentru a asigura că, &icirc;n mod implicit, sunt prelucrate numai date cu
                    caracter
                    personal care sunt necesare pentru fiecare scop specific al prelucrării.</p>
                <h3>Notificarea autorității de supraveghere &icirc;n cazul &icirc;ncălării securității datelor cu
                    caracter
                    personal</h3>
                <p>Conform art. 33 alin. 1 din GDPR, &icirc;n cazul &icirc;n care are loc o &icirc;ncălcare a
                    securităţii
                    datelor cu
                    caracter personal, vom notifica acest lucru Autorității Naționale de Supraveghere a Prelucrării
                    Datelor
                    cu
                    Caracter Personal fără &icirc;nt&acirc;rzieri nejustificate şi, dacă este posibil, &icirc;n termen
                    de
                    cel mult
                    72 de ore de la data la care am luat cunoştinţă de aceasta, cu excepţia cazului &icirc;n care este
                    puţin
                    probabil să genereze un risc pentru drepturile şi libertăţile persoanelor fizice.</p>
                <h3>Informarea persoanei vizate cu privire la &icirc;ncălcarea securității datelor datelor cu caracter
                    personal</h3>
                <p>Raportat la prevederile art. 34 din GDPR, &icirc;n cazul &icirc;n care &icirc;ncălcarea securităţii
                    datelor cu
                    caracter personal este susceptibilă să genereze un risc ridicat pentru drepturile şi libertăţile
                    persoanelor
                    fizice, vom informa persoana vizată fără &icirc;nt&acirc;rzieri nejustificate cu privire la
                    această &icirc;ncălcare, cu excepția situațiilor &icirc;n care:</p>
                <ul>
                    <li>au fost implementate măsuri de protecţie tehnice şi organizatorice adecvate, iar aceste măsuri
                        au
                        fost
                        aplicate &icirc;n cazul datelor cu caracter personal afectate de &icirc;ncălcarea securităţii
                        datelor cu
                        caracter personal, &icirc;n special măsuri prin care se asigură că datele cu caracter personal
                        devin
                        neinteligibile oricărei persoane care nu este autorizată să le acceseze, cum ar fi criptarea;
                    </li>
                    <li>au fost luate măsuri ulterioare prin care se asigură că riscul ridicat pentru drepturile şi
                        libertăţile
                        persoanelor vizate menţionat anterior nu mai este susceptibil să se materializeze;
                    </li>
                    <li>ar necesita un efort disproporţionat. &Icirc;n această situaţie, se efectuează &icirc;n loc o
                        informare
                        publică sau se ia o măsură similară prin care persoanele vizate sunt informate &icirc;ntr-un mod
                        la
                        fel de
                        eficace.
                    </li>
                </ul>
                <p><h2>Plugins și Tools</h2></p>
                <h3>Google Maps</h3>
                <p>Acest site utilizează Google Maps, un serviciu de mapare şi localizare, prin intermediul unui API.
                    Furnizorul
                    este Google Inc., 1600 Amphitheater Parkway Mountain View, CA 94043, Statele Unite ale Americii.</p>
                <p>Pentru a garanta protecția datelor pe site-ul nostru, veți descoperi că Google Maps a fost dezactivat
                    atunci
                    c&acirc;nd vizitați site-ul nostru pentru prima dată. O conexiune directă la serverele Google nu va
                    fi
                    stabilită &icirc;nainte de activarea autonomă a Hărților Google, adică cu consimțăm&acirc;ntul
                    dvs. &icirc;n
                    conformitate cu articolul 6 alin. 1 lit. a) GDPR. Acest lucru va &icirc;mpiedica transferul datelor
                    către
                    Google &icirc;n timpul primei vizite pe site-ul nostru. După ce ați activat serviciul, Google Maps
                    va
                    stoca
                    adresa IP. De regulă, este transferat ulterior pe un server Google din Statele Unite, unde este
                    stocat.
                    Furnizorul acestui site web nu are control asupra acestui transfer de date odată ce Google Maps a
                    fost
                    activat.</p>
                <p>Av&acirc;nd &icirc;n vedere Hotăr&acirc;rea din 16 iulie 2020 <em>(pronunțată &icirc;n cauza C-311/18
                    -
                    Data
                    Protection Commissioner/Facebook Ireland Limited, Maximillian Schrems)</em>, Curtea Europeană de
                    Justiţie a
                    statuat că protecţia oferită de Scutul de confidențialitate EU &ndash; US (Privacy Shield) nu are un
                    caracter
                    adecvat.</p>
                <p>Prin urmare, transmiterea datelor cu caracter personal către SUA şi alte ţări din afara Spaţiului
                    Economic
                    European (EEA &ndash; European Economic Area) se bazează pe Clauzele Contractuale Standard
                    (SCC &ndash; Standard
                    Contractual Clauses) ale Comisiei Europene. Comisia a emis două seturi de Clauze Contractuale
                    Standard
                    pentru
                    transferurile de date de la operatorii de date din UE către operatorii de date stabiliți &icirc;n
                    afara
                    UE sau a
                    Spațiului Economic European (SEE). De asemenea, a emis şi un set de clauze contractuale pentru
                    transferurile de
                    date de la operatorii din UE către procesatorii stabiliți &icirc;n afara UE sau SEE. Pentru mai
                    multe
                    informaţii
                    privind aceste Clauze, recomandăm să accesaţi <a
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro"
                        target="_blank"
                        rel="noopener">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_ro</a>.&nbsp;
                </p>
                <p>Google Maps foloseşte Clauze Contractuale Standard drept garanţie adecvată privind protecţia
                    datelor, &icirc;n
                    acord cu nivelul de protecţie garantat prin GDPR. Pentru mai multe informaţii, consultați Declarația
                    de
                    confidențialitate a datelor Google la adresa următoare: <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener">https://policies.google.com/privacy</a>
                </p>
                <p><h2>Concluzie</h2></p>
                <p>Prezenta politică privind prelucrarea datelor cu caracter personal este generată &icirc;n
                    conformitate cu
                    prevederile Regulamentului nr. 679/2016 privind protecţia persoanelor fizice &icirc;n ceea ce
                    priveşte
                    prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date, dar şi cu
                    celelalte
                    dispoziţii legale naţionale aplicabile.</p>
                <p>Ne rezervăm dreptul de a face orice completări sau modificări la această politică. Recomandăm
                    consultarea
                    Politicii &icirc;n mod regulat pentru o informare corectă și actualizată &icirc;n ceea ce privește
                    prelucrarea
                    datelor personale.</p>
                <p>Pentru mai multe detalii cu privire la prezenta Politică GDPR, precum şi pentru exercitarea oricărui
                    drept dintre
                    cele menţionate anterior, poate fi trimisă o notificare scrisă la datele de contact indicate mai
                    sus.</p>
            </div>
        </Container>
    );
}

const Terms = MotionHoc(TermsComponent);

export default Terms;