import React, {useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../../Profile/profile.scss';
import MUIRichTextEditor from "mui-rte";
import './companyDescriptionDialog.scss'
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    saveCallback: (newDescription : string) => void;
    description: string;
    isForJob?: boolean;
}

export const htmlToStringifiedState = (html : string) => {
    const contentHTML = htmlToDraft(html);
    const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
    );
    const content = JSON.stringify(convertToRaw(state));
    return content;
}

export const stateToHtml = (state : EditorState) => {
    const rawState = convertToRaw(state.getCurrentContent());
    let html = draftToHtml(rawState);
    // let images: any[] = [];
    // for(let i = 0; rawState.entityMap.hasOwnProperty(i); i++)
    // {
    //    if(rawState.entityMap[i].type === 'IMAGE')
    //    {
    //        html = html.replace('<img src="undefined"', `<img src="${rawState.entityMap[i].data.url}"`);
    //    }
    // }

    return html;
}

const DescriptionDialog = (props: DialogProps) => {
    const company = useSelector((s: ApplicationState) => s.auth.company)

    const [editorState, setEditorState] = useState<EditorState>();
    const [description, setDescription] = useState<string>(htmlToStringifiedState(props.description ?? ''));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const dispatch = useDispatch();

    const controls: string[] = [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'undo',
        'redo',
        'link',
        // 'media',
        'numberList',
        'bulletList',
    ];

    const saveCallback = async () => {
        setIsLoading(true);
        const newDescription = editorState ? stateToHtml(editorState).replace('<a', '<a target="__blank"') : '';
        props.saveCallback(newDescription);
    }

    useEffect(() => {
        if(editorState){
            setIsFormValid(stateToHtml(editorState).trim().length > 8);
        }
    }, [editorState]);

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>{props.isForJob ? 'Editează descrierea jobului' : 'Editează descrierea companiei'}</DialogTitle>
            <DialogContent style={{paddingTop: 5, paddingBottom: 5}}>
                <div className={'rich-text-editor-container'}>
                    <MUIRichTextEditor
                        label={props.isForJob ? 'Adaugă o scurtă descriere a ofertei de recrutare, detalii despre cerințele pe care trebuie sa le indeplineasca aplicanții' +
                            ', precum și informații despre bonusurile si beneficiile oferite unui potențial angajat.'
                            : 'Descrierea companiei...'}
                        defaultValue={description}
                        onChange={(newState) => setEditorState(newState)}
                        controls={controls}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default DescriptionDialog;