import React, {useEffect, useState} from 'react'
import '../JobsFilterCard/jobsFilterCard.scss';
import CardHeader from "../Profile/Cards/CardHeader";
import {CareerLevelEnum, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {useNavigate} from "react-router";
import {Button, Chip} from "@mui/material";
import CandidatesFilterDialog, {AgeInterval} from "./CandidatesFilterDialog";
import {SchoolTypeEnum} from "../Profile/Cards/CardItem";
import {LabelContentItem} from "../Profile/Profile";

interface Props {
    searchParams: URLSearchParams;
    query: string;
    careerLevel: CareerLevelEnum[];
    ageInterval: AgeInterval;
    industries: IndustriesEnum[];
    languages: string[];
    education: SchoolTypeEnum | null;
    skills: LabelContentItem[];
}

const CandidatesFilterCard = (props: Props) => {
    const [showFiltersDialog, setShowFiltersDialog] = useState<boolean>(false);
    const [paramBadges, setParamBadges] = useState([]);
    const navigate = useNavigate();

    const applyFilters = (
        careerLevel: CareerLevelEnum[],
        ageInterval: AgeInterval,
        industries: IndustriesEnum[],
        languages: string[],
        education: SchoolTypeEnum | null,
        skills: LabelContentItem[]
    ) => {
        props.searchParams.delete('level');
        props.searchParams.delete('industry');
        props.searchParams.delete('minAge');
        props.searchParams.delete('maxAge');
        props.searchParams.delete('language');
        props.searchParams.delete('skill');
        props.searchParams.delete('education');

        careerLevel.filter(level => !!level).forEach(level => props.searchParams.append('level', CareerLevelEnum[level].toString().toLowerCase()))
        industries.filter(industry => !!industry).forEach(industry => props.searchParams.append('industry', IndustriesEnum[industry].toString().toLowerCase()))
        if(ageInterval.minAge)
            props.searchParams.set('minAge', ageInterval.minAge.toString());
        if(ageInterval.maxAge)
            props.searchParams.set('maxAge', ageInterval.maxAge.toString());
        languages.filter(language => !!language).forEach(language => props.searchParams.append('language', language))
        if(education)
            props.searchParams.set('education', education);
        skills.filter(skill => !!skill.label.trim()).forEach(skill => props.searchParams.append('skill', skill.label + ';' + skill.content))

        navigate('/candidati?' + props.searchParams.toString())

        setShowFiltersDialog(false);
    }

    const getParamsBadges = () => {
        let badges: any = [];
        if (props.query) {
            badges = [
                ...badges,
                <Chip
                    key={props.query}
                    label={props.query}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
            ];
        }
        if (props.ageInterval.minAge) {
            badges = [
                ...badges,
                <Chip
                    key={props.ageInterval.minAge}
                    label={'De la ' + props.ageInterval.minAge + ' ani'}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
            ];
        }

        if (props.ageInterval.maxAge) {
            badges = [
                ...badges,
                <Chip
                    key={props.ageInterval.maxAge}
                    label={'Pana la ' + props.ageInterval.maxAge + ' ani'}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
            ];
        }
        if (props.education) {
            badges = [
                ...badges,
                <Chip
                    key={props.education}
                    label={props.education}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
            ];
        }
        if (props.languages && props.languages.length > 0) {
            badges = [
                ...badges,
                ...props.languages.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }
        if (props.industries && props.industries.length > 0) {
            badges = [
                ...badges,
                ...props.industries.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }
        if (props.careerLevel && props.careerLevel.length > 0) {
            badges = [
                ...badges,
                ...props.careerLevel.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }
        if(props.skills && props.skills.length > 0){
            badges = [
                ...badges,
                ...props.skills.map(s =>
                    <Chip
                        key={s.label}
                        label={s.label + '-' + s.content}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ]
        }
        setParamBadges(badges);
    }

    useEffect(() => {
        getParamsBadges();
    }, [props.searchParams]);

    return (
        <>
            <div className={'jobs-filter-card'}>
                <CardHeader
                    isOwnProfile={false}
                    editCallback={() => {
                        setShowFiltersDialog(true);
                    }}
                    title={'Filtre'}
                    forceShowEditButton={true}
                />
                <div className={'solid'}/>
                <div className={'filters-container'}>
                    {
                        paramBadges.length === 0 ? 'Niciun filtru selectat' : paramBadges
                    }
                </div>
                {
                    paramBadges.length > 0 &&
                    <div className={'solid'}/>
                }
                {
                    paramBadges.length > 0 && <Button
                        className={'filter-edit-button'}
                        onClick={() => {
                            navigate('/candidati');
                        }}
                    >
                        Elimina filtrele
                    </Button>
                }
            </div>
            {
                showFiltersDialog &&
                <CandidatesFilterDialog
                    saveCallback={applyFilters}
                    closeCallback={() => setShowFiltersDialog(false)}
                    industries={props.industries}
                    careerLevel={props.careerLevel}
                    ageInterval={props.ageInterval}
                    languages={props.languages}
                    education={props.education}
                    skills={props.skills}
                />
            }
        </>
    );
}

export default CandidatesFilterCard;