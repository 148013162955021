import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {reducers} from "./index";

const rootPersistConfig = {
    key: 'root',
    storage: storage,
};


export default function configureStore(history: History) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__){// && process.env.REACT_ENV === 'Development') {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        persistReducer(rootPersistConfig, rootReducer),
        {},
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    const persistor = persistStore(store);
    return { store, persistor };
}
