import React, {useEffect, useState} from 'react'
import './jobsFilterCard.scss';
import CardHeader from "../Profile/Cards/CardHeader";
import JobsFilterDialog from "./JobsFilterDialog";
import {CareerLevelEnum, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {useNavigate} from "react-router";
import {Button, Chip} from "@mui/material";

interface Props {
    searchParams: URLSearchParams;
    industries: IndustriesEnum[];
    careerLevel: CareerLevelEnum[];
    jobType: JobTypeEnum[];
    locationType: LocationTypeEnum[];
    query: string;
}

const JobsFilterCard = (props: Props) => {
    const [showFiltersDialog, setShowFiltersDialog] = useState<boolean>(false);
    const [paramBadges, setParamBadges] = useState([]);
    const navigate = useNavigate();

    const applyFilters = (jobType: JobTypeEnum[],
                          careerLevel: CareerLevelEnum[],
                          locationType: LocationTypeEnum[],
                          industries: IndustriesEnum[]) => {
        props.searchParams.delete('type');
        props.searchParams.delete('level');
        props.searchParams.delete('location');
        props.searchParams.delete('industry');

        jobType.filter(job => !!job).forEach(type => props.searchParams.append('type', JobTypeEnum[type].toString().toLowerCase()))
        careerLevel.filter(level => !!level).forEach(level => props.searchParams.append('level', CareerLevelEnum[level].toString().toLowerCase()))
        locationType.filter(location => !!location).forEach(location => props.searchParams.append('location', LocationTypeEnum[location].toString().toLowerCase()))
        industries.filter(industry => !!industry).forEach(industry => props.searchParams.append('industry', IndustriesEnum[industry].toString().toLowerCase()))

        navigate('/joburi?' + props.searchParams.toString())

        setShowFiltersDialog(false);
    }

    const getParamsBadges = () => {
        let badges: any = [];
        if(props.query){
            badges = [
                ...badges,
                <Chip
                    key={props.query}
                    label={props.query}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
            ];
        }
        if(props.locationType && props.locationType.length > 0){
            badges = [
                ...badges,
                ...props.locationType.map(i =>
                <Chip
                    key={i}
                    label={i}
                    style={{borderRadius: 6, margin: 5}}
                    color={'primary'}
                />
                )
            ];
        }
        if(props.jobType && props.jobType.length > 0){
            badges = [
                ...badges,
                ...props.jobType.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }
        if(props.industries && props.industries.length > 0){
            badges = [
                ...badges,
                ...props.industries.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }
        if(props.careerLevel && props.careerLevel.length > 0){
            badges = [
                ...badges,
                ...props.careerLevel.map(i =>
                    <Chip
                        key={i}
                        label={i}
                        style={{borderRadius: 6, margin: 5}}
                        color={'primary'}
                    />
                )
            ];
        }

        setParamBadges(badges);
    }

    useEffect(() => {
        getParamsBadges();
    }, [props.searchParams]);

    return (
        <>
            <div className={'jobs-filter-card'}>
                <CardHeader
                    isOwnProfile={false}
                    editCallback={() => {
                        setShowFiltersDialog(true);
                    }}
                    title={'Filtre'}
                    forceShowEditButton={true}
                />
                <div className={'solid'}/>
                <div className={'filters-container'}>
                    {
                        paramBadges.length === 0 ? 'Niciun filtru selectat' : paramBadges
                    }
                </div>
                {
                    paramBadges.length > 0 &&
                        <div className={'solid'} />
                }
                {
                    paramBadges.length > 0 && <Button
                        className={'filter-edit-button'}
                        onClick={() => {
                            navigate('/joburi');
                        }}
                    >
                        Elimina filtrele
                    </Button>
                }
            </div>
            {
                showFiltersDialog &&
                <JobsFilterDialog
                    saveCallback={applyFilters}
                    closeCallback={() => setShowFiltersDialog(false)}
                    jobType={props.jobType}
                    careerLevel={props.careerLevel}
                    locationType={props.locationType}
                    industries={props.industries}
                />
            }
        </>
    );
}

export default JobsFilterCard;