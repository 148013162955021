import React from 'react';
import {HelpPageProps} from "./types";
import {Box} from "@mui/material";

const ApplicationsHelp = ({isCandidate}: HelpPageProps) => {
    return (
        <Box paddingTop={'10px'}>
            <h1 style={{marginBottom: 20}}>APLICARI</h1>
            <p>Această pagină de ajutor acoperă diferite părți ale modului în care funcționează APLICARILE.</p>

            {
                isCandidate && <div>
                    <p>Pagina de aplicari poate fi accesata din bara de navigatie, apasand pe butonul de <b>aplicarile
                        mele</b>.
                    </p>
                    <img src={'images/aplicari-1.png'} className={'help-page-vertical-image'}/>

                    <h2>APLICARI VIZIBILE</h2>
                    <p>Poti modifica ce aplicari doresti sa vezi - active sau inactive - prin butonul din dreapta-sus.</p>
                    <img src={'images/aplicari-2.png'} className={'help-square-page-image'}/>

                    <h2>CARD APLICARE</h2>
                    <p>Cardul unei aplicari contine informatii legate de jobul la care ai aplicat, precum si informatiile
                        tale personale.</p>
                    <img src={'images/aplicari-3.png'} className={'help-page-image'}/>
                </div>
            }
            {
                !isCandidate && <div>
                    <p>Pagina de aplicari poate fi accesata din bara de navigatie, apasand pe butonul de <b>aplicari</b>.
                    </p>
                    <img src={'images/aplicari-4.png'} className={'help-page-vertical-image'}/>

                    <h2>APLICARI VIZIBILE</h2>
                    <p>Poti modifica ce aplicari doresti sa vezi - active sau inactive - prin butonul din dreapta-sus.</p>
                    <img src={'images/aplicari-2.png'} className={'help-square-page-image'}/>

                    <h2>CARD APLICARE</h2>
                    <p>Cardul unei aplicari contine informatii legate de candidat, cat si despre job.</p>
                    <p>Pentru a <b>RESPINGE</b> un candidat, poti folosi butonul de respingere. Candidatul va fi informat
                        prin e-mail si va primi mesajul personalizat din partea companiei.</p>
                    <p>Pentru a <b>STERGE</b> o aplicare, poti folosi butonul 'x' din dreapta-sus. Candidatul <b>nu</b> va
                        fi notificat. Recomandam stergerea unei aplicatii fara doar in cazurile in care s-a discutat deja cu
                        candidatul.</p>
                    <img src={'images/aplicari-5.png'} className={'help-page-image'}/>
                </div>
            }
        </Box>
    );
}

export default ApplicationsHelp;