import React from 'react';
import {signOut} from "firebase/auth";
import {auth} from "../../App";
import SnackbarComponent from "../Snackbar";
import Navbar from "../Navbar";

interface Props {
    children?: React.ReactNode;
}

const Layout = (props: Props) => {
    return (
        <>
            <div hidden={!(window.location.pathname !== '/404' &&
                !window.location.pathname.includes('/conectare') &&
                !window.location.pathname.includes('/inregistrare'))}>
                <Navbar/>
            </div>
            <div className={'layout-container'}>
                {props.children}
            </div>
            <SnackbarComponent/>
        </>
    );
};

export default Layout;