import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Job} from "../../models/Job";
import './jobListItem.scss'
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {
    ApartmentRounded,
    LocationOn,
    LocationOnOutlined,
    StarOutline,
    StarOutlined, StarOutlineRounded,
    StarRounded
} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import {ReactComponent as ClockSvg} from '../../images/svgs/clock.svg';
import FullMonths from "../Constants/Months";
import {Button, CircularProgress, IconButton, Tooltip, Zoom} from "@mui/material";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import JobApplyDialog from "../JobApplyDialog";
import {DialogUtil} from "../Utils/DialogUtil";
import FirebaseUtil from "../Firebase/Firebase";
import {v4} from 'uuid';
import {snackbarSlice} from "../../store/Snackbar";
import {authenticationSlice, UserDetails} from "../../store/Authentication";
import {useNavigate} from "react-router";

interface Props {
    job: Job;
    isFromFrontEndQuery?: boolean;
}

export const applyToJob = async (user: UserDetails | null, job: Job, coverLetter: string) => {
    if (!user) {
        return false;
    }

    if(!user.active){
        return false;
    }

    const jobAppId = v4();
    const userApplicationsForJob = await FirebaseUtil.getDocsWithCondition('JobApplications', {
        fieldPath: 'jobId',
        opStr: '==',
        value: job.id
    }, {
        fieldPath: 'userId',
        opStr: '==',
        value: user.id
    });

    const date3MonthsAgo = (new Date().getTime()) / 1000 - 7776000;
    const numberOfJobs = userApplicationsForJob.docs.filter(doc => doc.data().createdAt.seconds > date3MonthsAgo).length;
    if (numberOfJobs > 0) {
        return false;
    }

    await FirebaseUtil.write('JobApplications', jobAppId, {
        id: jobAppId,
        jobId: job.id,
        companyId: job.companyId,
        userId: user!.id,
        coverLetter: coverLetter,
        createdAt: new Date(),
        resolved: false,
        userEmail: user!.email,
        userName: user!.name,
    })
    return true;
}

const JobListItem = ({job, isFromFrontEndQuery}: Props) => {
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const companiesInformation = useSelector((s: ApplicationState) => s.data.companiesInformation);
    const jobCompany = companiesInformation.size > 0 ? companiesInformation?.get(job.companyId) : null;

    const [showApplyDialog, setShowApplyDialog] = useState(false);
    const [showConfirmApplyExternalDialog, setShowConfirmApplyExternalDialog] = useState(false);
    const [isAwaitingRequestResponse, setIsAwaitingRequestResponse] = useState<boolean>(false);
    const [isSettingFavoriteJob, setIsSettingFavoriteJob] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const applyCallback = async (coverLetter: string) => {
        setIsAwaitingRequestResponse(true);
        const result = await applyToJob(user, job, coverLetter);
        if (!result) {
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error'
            }));
        } else {
            dispatch(snackbarSlice.actions.show({
                message: 'Ai aplicat cu succes!',
                severity: 'success'
            }));
            dispatch(authenticationSlice.actions.setUser(
                {
                    userData: {
                        ...user,
                        jobApplicationsIds: [...user?.jobApplicationsIds ?? [], job.id]
                    }
                }));
        }
        setShowApplyDialog(false);
        setIsAwaitingRequestResponse(false);
    }

    const getCreationDate = () => {
        let createdAt = job.createdAt!;
        if (isFromFrontEndQuery)
            createdAt = new Date((job.createdAt! as any).seconds * 1000);
        else
            createdAt = new Date((createdAt as any)._seconds * 1000);
        const myString = createdAt.getDate() + ' ' + FullMonths[createdAt.getMonth()] + ' ' + createdAt.getFullYear();
        return myString;
    }

    const redirectExternal = (option: boolean) => {
        setShowConfirmApplyExternalDialog(false);
        if (!option)
            return;

        const newWindow = window.open(job.externalUrl!, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const addJobToFavorites = async () => {
        if (!user) return;

        setIsSettingFavoriteJob(true);
        const start = Date.now();
        try {
            const isJobFavorite = user.favoriteJobsIds?.includes(job.id);
            await FirebaseUtil.write('Users', user.id!, {
                favoriteJobsIds: isJobFavorite ?
                    user.favoriteJobsIds!.filter(jobId => jobId !== job.id) :
                    [job.id, ...(user.favoriteJobsIds ?? [])]
            });
            const end = Date.now() - start;
            setTimeout(() => {
                setIsSettingFavoriteJob(false);
                dispatch(snackbarSlice.actions.show({
                    message: !isJobFavorite ? 'Job adaugat la favorite!' : 'Job eliminat de la favorite!',
                    severity: 'success'
                }));
            }, 750 - end);
        } catch (e) {
            setIsSettingFavoriteJob(false);
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error'
            }));
        }
    }

    return companiesInformation.size > 0 && jobCompany ? (
        <div className={'list-item'}>
            {
                user &&
                <div className={'favorite-job-container'}>
                    {
                        !isSettingFavoriteJob ?
                            <Tooltip title={!(user.favoriteJobsIds?.includes(job.id)) ? 'Adaugă la favorite' : 'Elimina de la favorite'} arrow TransitionComponent={Zoom}>
                                <IconButton
                                    size={'small'}
                                    className={'favorite-job-button'}
                                    onClick={addJobToFavorites}
                                >
                                    {
                                        user.favoriteJobsIds?.includes(job.id) ?
                                            <StarRounded className={'favorite-job-icon'} color={'primary'}/> :
                                            <StarOutlineRounded className={'favorite-job-icon'} color={'primary'}/>
                                    }
                                </IconButton>
                            </Tooltip> :
                            <CircularProgress
                                className={'favorite-job-circular-progress'}
                            />
                    }
                </div>
            }
            <NavLink
                className={'company-logo'}
                to={`/joburi/${job.id}`}>
                {
                    jobCompany.photoURL ?
                        <img src={jobCompany.photoURL}/> :
                        <ApartmentRounded className={'profile-icon'}/>
                }
            </NavLink>
            <div className={'job-body'}>
                <div className={'job-info-primary'}>
                    <NavLink
                        className={'company-name'}
                        to={`/companii/${job.companyId}`}
                    >
                        {
                            jobCompany.name
                        }
                    </NavLink>
                    <NavLink
                        className={'job-name'}
                        to={`/joburi/${job.id}`}
                    >
                        <h3>
                            {
                                job.title
                            }
                        </h3>
                    </NavLink>
                    <div className={'job-card-info-bottom'}>
                        <NavLink
                            className={'job-badge card'}
                            to={`/joburi?industry=${IndustriesEnum[(job.industry as unknown) as keyof typeof IndustriesEnum]}`}
                        >
                            {job.industry}
                        </NavLink>
                        <div className={'job-type-container'}>
                            <ClockSvg className={'job-type-icon'}/>
                            <div className={'job-type-name'}>
                                {job.jobType}
                            </div>
                        </div>
                        <div className={'job-type-container'} style={{paddingLeft: 16}}>
                            <LocationOnOutlined className={'job-type-icon'} color={'primary'} style={{marginRight: 6}}/>
                            <div className={'job-type-name'}>
                                {job.locationType!.toString().split(' ')[0]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'solid'}/>
                <div className={'job-info-secondary'}>
                    {
                        !company ?
                            !user?.jobApplicationsIds?.find(jobId => jobId === job.id) ?
                                <Button
                                    className={'new-job-button'}
                                    disabled={isAwaitingRequestResponse}
                                    onClick={() => {
                                        if (!user) {
                                            navigate(`/conectare?callbackUrl=/joburi/${job.id}`)
                                            return;
                                        }

                                        if(!user.active){
                                            dispatch(snackbarSlice.actions.show({
                                                message: 'Activeaza-ti contul pentru a putea aplica la joburi!',
                                                severity: 'error',
                                            }))
                                            navigate('/setari');
                                            return;
                                        }

                                        if (job.applyExternal) {
                                            setShowConfirmApplyExternalDialog(true);
                                        } else if (job.needLetter) {
                                            setShowApplyDialog(true)
                                        } else {
                                            applyCallback('');
                                        }
                                    }}
                                >
                                    {job.applyExternal ? 'Aplică extern' : 'Aplică'}
                                </Button> :
                                <Button
                                    className={'applied-to-job-button disable-hover'}
                                    disabled={true}
                                >
                                    Aplicat
                                </Button> :
                            <div/>
                    }
                    {
                        getCreationDate()
                    }
                </div>
            </div>
            {
                showApplyDialog &&
                <JobApplyDialog
                    closeCallback={() => setShowApplyDialog(false)}
                    applyCallback={applyCallback}
                    job={job}
                />
            }
            {
                DialogUtil.confirm(
                    {
                        title: 'Vei fi redirecționat pe o pagină externă. Ești sigur că dorești acest lucru?',
                    },
                    showConfirmApplyExternalDialog,
                    redirectExternal,
                    true
                )
            }
        </div>
    ) : null;
}

export default JobListItem;