import React, {useEffect, useState} from 'react';
import './experienceBar.scss';

interface Props {
    percentage: number,
}

const ExperienceBar = (props: Props) => {
    const [percentage, setPercentage] = useState(0);
    const [lastPercentage, setLastPercentage] = useState(0);

    useEffect(() => {
        setLastPercentage(percentage);
        setPercentage(props.percentage);
    }, [props.percentage]);

    return <div className={'experience-bar'}>
        <div className={'bar'}
             style={{width: `${percentage}%`, transition: `width ${Math.abs(percentage - lastPercentage) / 100 * 2}s ease-in-out`}}/>
    </div>
}

export default ExperienceBar;