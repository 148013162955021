import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import {createTheme, ThemeProvider} from "@mui/material";
import {createBrowserHistory} from 'history';
import configureStore from "./store/configureStore";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";

export const history = createBrowserHistory();

export const ApplicationStore = configureStore(history);
const store = configureStore(history).store;
const persistor = configureStore(history).persistor;

const theme = createTheme({
    shape: {
        borderRadius: 12
    },
    palette: {
        primary: {
            main: '#1F406D',
        },
        secondary: {
            main: '#000'
        },

    },
    typography: {
        fontFamily: [
            "Poppins",
            "Roboto",
            "Helvetica Neue",
            "Arial",
            "sans-serif"
        ].join(",")
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)
;