import React, {useState} from 'react';
import CardHeader from "./CardHeader";
import CardItem, {CardTypeEnum, ExperienceInformation, WorkTypeEnum} from "./CardItem";
import ExperienceDialog from "../Dialogs/ExperienceDialog";
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {DialogUtil} from "../../Utils/DialogUtil";

interface Props {
    rows: ExperienceInformation[];
    isOwnProfile: boolean;
}

const ExperienceInformationCard = (props: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);

    const [showDialog, setShowDialog] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [isNewJob, setIsNewJob] = useState(false);
    const [selectedJob, setSelectedJob] = useState<ExperienceInformation>({
        positionName: '',
        location: '',
        startDate: '',
        workType: WorkTypeEnum.none,
        endDate: '',
        companyName: '',
        description: '',
        industry: null
    });
    const [selectedJobIndex, setSelectedJobIndex] = useState(0);
    const dispatch = useDispatch();

    const confirmDeleteExperience = (result: boolean) => {
        if(result)
        {
            FirebaseUtil.write('Users', user!.id!,
                {
                    experience: user!.experience!.filter((experience, i) => selectedJobIndex !== i)
                }).catch(e => {
                    dispatch(snackbarSlice.actions.show({
                        message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                        severity: 'error',
                    }))
                }
            );
        }
        setShowWarningDialog(false);
    }

    return (
        props.rows.length > 0 || props.isOwnProfile ? <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                hideEditButton={true}
                title={'Experiență'}
            />
            <div className={'solid'}/>
            {
                props.rows?.map((job, index) => {
                    return (
                        <div key={`experience-card-item-${index}`}>
                            <CardItem
                                job={job}
                                cardType={CardTypeEnum.experience}
                                editCallback={() => {
                                    setSelectedJob(job);
                                    setSelectedJobIndex(index);
                                    setIsNewJob(false);

                                    setShowDialog(true);
                                }}
                                deleteCallback={() => {
                                    setSelectedJobIndex(index)
                                    setShowWarningDialog(true);
                                }}
                                isOwnProfile={props.isOwnProfile}
                            />
                            <div className={'solid-thin'}/>
                        </div>
                    );
                })
            }
            <Button onClick={() => {
                setSelectedJob({
                    positionName: '',
                    location: '',
                    startDate: null,
                    workType: WorkTypeEnum.fullTime,
                    endDate: null,
                    companyName: '',
                    description: '',
                    industry: null
                });
                setIsNewJob(true);
                setShowDialog(true);
            }}
                    style={{textTransform: 'uppercase', marginTop: 10}}>
                {
                    props.isOwnProfile &&
                    <>
                        <Add color={'primary'} fontSize={'small'}/>
                        <span>Adaugă experiență</span>
                    </>
                }
            </Button>
            {
                showDialog &&
                <ExperienceDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    isNewJob={isNewJob}
                    job={selectedJob}
                    jobIndex={selectedJobIndex}
                />
            }
            {
                DialogUtil.confirm({
                        title: 'Ești sigur că dorești sa stergi aceasta experiență?',
                    },
                    showWarningDialog,
                    confirmDeleteExperience,
                    true
                )
            }
        </div> : null
    );
}

export default ExperienceInformationCard;