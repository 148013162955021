import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {ApplicationState} from "../store";

const PrivateRoute = ({ children } : any) => {
    const user = useSelector((s : ApplicationState) => s.auth.user);
    const company = useSelector((s : ApplicationState) => s.auth.company);

    return user || company ? children : <Navigate to={'/conectare'} />;
}

export default PrivateRoute;

export const CompanyPrivateRoute = ({ children } : any) => {
    const company = useSelector((s : ApplicationState) => s.auth.company);
    const user = useSelector((s : ApplicationState) => s.auth.user);

    return !!company ? children : <Navigate to={!!user ? '/' : '/conectare'} />;
}

export const UserPrivateRoute = ({ children } : any) => {
    const user = useSelector((s : ApplicationState) => s.auth.user);
    const company = useSelector((s : ApplicationState) => s.auth.company);

    return !!user ? children : <Navigate to={!!company ? '/' : '/conectare'} />;
}