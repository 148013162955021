import React, {useEffect, useState} from 'react';
import CardHeader from "./CardHeader";
import ExperienceBar from "./ExperienceBar";
import {LabelContentItem} from "../Profile";
import SkillsDialog, {SkillLevelEnum} from "../Dialogs/SkillsDialog";

interface Props {
    rows: LabelContentItem[];
    isOwnProfile: boolean;
    title?: string;
    saveCallback: (newSkills: LabelContentItem[]) => void
}

const SkillsInformationCard = (props: Props) => {
    const [notNullRows, setNotNullRows] = useState<LabelContentItem[]>([]);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (props.rows) {
            var rows = props.rows.filter(row => row.content !== null && row.content !== undefined);
            setNotNullRows(rows);
        }
    }, [props.rows]);

    const saveNewSkills = async (newSkills: LabelContentItem[]) => {
        await props.saveCallback(newSkills);
        setShowDialog(false);
    }

    return (
        props.isOwnProfile || notNullRows.length > 0 ? <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => setShowDialog(true)}
                title={props.title ?? 'Aptitudini'}
            />
            <div className={'solid'}/>
            {
                notNullRows.length === 0 &&
                <div>
                    Nicio aptitudine. Adaugă aptitudini folosind butonul de editare.
                </div>
            }
            {
                notNullRows?.map((row, index) => {
                    return (
                        <div key={`skills-row-${index}`} className={'skills-row'}>
                            <div className={'label'}>
                                <div>{row.label}</div>
                                <div>{row.content}</div>
                            </div>
                            <div className={'display'}>
                                <ExperienceBar percentage={(SkillLevelEnum[row.content] as any - 10) / 20 * 25}/>
                            </div>
                        </div>
                    );
                })
            }
            {
                showDialog &&
                <SkillsDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    saveCallback={saveNewSkills}
                    skills={notNullRows}
                />
            }
        </div> : null
    );
}

export default SkillsInformationCard;