import React, {useState} from 'react';
import CardHeader from "../../Profile/Cards/CardHeader";
import './companyMediaInformationCard.scss'
import CompanyMediaDialog from "../Dialogs/CompanyMediaDialog";

interface Props{
    mediaUrls: string[];
    isOwnProfile: boolean;
}

const CompanyMediaInformationCard = (props: Props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    return (
        <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => setShowDialog(true)}
                title={'Galerie'}
            />
            <div className={'solid'}/>
            <div className={'media-body-container'}>
                {
                    props.mediaUrls.map((url, index) => {
                        return <img key={index} src={url} className={'media-image'} />
                    })
                }
                {
                    props.mediaUrls?.length > 0 || <p>Niciun fisier media.</p>
                }
            </div>
            {
                showDialog && <CompanyMediaDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)} />
            }
        </div>
    )
}

export default CompanyMediaInformationCard;