import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import firestore from "firebase/firestore";
import {LabelContentItem} from "../components/Profile/Profile";
import {EducationInformation, ExperienceInformation, TrainingInformation} from "../components/Profile/Cards/CardItem";
import {IndustriesEnum} from "../components/CompanyProfile/Dialogs/CompanyProfileDialog";
import {Job} from "../models/Job";

export interface UserDetails {
    id?: string,
    active?: boolean,
    name?: string,
    email?: string,
    phoneNumber?: string,
    city?: string,
    birthDate?: string,
    cvDownloadUrl?: string,
    photoURL?: string,
    backgroundURL?: string,
    appearInSearches?: boolean,

    biography?: string,
    objective?: string,
    qualification?: string,
    languages?: string,

    skills?: LabelContentItem[],
    experience?: ExperienceInformation[],
    education?: EducationInformation[],
    trainings?: TrainingInformation[],

    jobApplicationsIds?: string[],
    favoriteJobsIds?: string[],
}

export interface CompanyDetails {
    id?: string,
    active?: boolean,
    createdAt?: string,
    address?: string,
    city?: string,
    cityAddress?: string,
    isValidCompany?: boolean,
    companyName?: string,
    contactEmail?: string,
    country?: string,
    cui?: string,
    nrOrdine?: string,
    email?: string,
    employees?: string,
    industries?: IndustriesEnum[],
    phoneNumber?: string,
    website?: string,
    photoURL?: string,
    backgroundURL?: string,
    rejectionEmail?: string,
    applyEmail?: string,

    description?: string,
    mediaUrls?: string[],

    jobs?: Job[],
}

export interface AuthenticationState {
    user: UserDetails | null;
    company: CompanyDetails | null;
    authStateChanged: boolean;
}

const initialState: AuthenticationState = {
    user: null,
    company: null,
    authStateChanged: false
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        setUser: (state: AuthenticationState, {payload}: PayloadAction<{
            userData: firestore.DocumentData | undefined
        }>) => {
            if (!payload.userData) {
                state.company = null;
                state.user = null;
            }

            state.user = {
                ...payload.userData,
            }

            state.company = null;
        },
        setCompany: (state: AuthenticationState, {payload}: PayloadAction<{
            companyData: firestore.DocumentData | undefined
        }>) => {
            if (!payload.companyData) {
                state.company = null;
            }
            state.user = null;

            state.company = {
                ...payload.companyData
            }
        },
        removeUser: (state: AuthenticationState) => {
            state.user = null;
            state.company = null;
        },
        triggerAuthStateChange: (state: AuthenticationState) => {
            state.authStateChanged = !state.authStateChanged;
        }
    }
});

export default authenticationSlice.reducer;