import React from 'react';
import {HelpPageProps} from "./types";
import {Box} from "@mui/material";

const RegisterHelp = ({isCandidate}: HelpPageProps) => {
    return (
        <Box paddingTop={'10px'}>
            <h1 style={{ marginBottom: 20 }}>ÎNREGISTRARE</h1>
            <p>Această pagină de ajutor acoperă diferite părți ale modului în care funcționează ÎNREGISTRAREA.</p>
            <p>Pagina de inregistrare poate fi accesata prin link-ul <a href={'https://resita-jobs.ro/inregistrare-companie'} target={'_blank'}>resita-jobs.ro/inregistrare-companie</a> sau
                de pe pagina de INREGISTRARE a candidatilor.</p>
            <img src={'images/inregistrare-1.png'} className={'help-page-image'}/>
        </Box>
    );
}

export default RegisterHelp;