import React, {useState} from 'react';
import CardHeader from "./CardHeader";
import CardItem, {CardTypeEnum, EducationInformation, SchoolTypeEnum} from "./CardItem";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {DialogUtil} from "../../Utils/DialogUtil";
import EducationDialog from "../Dialogs/EducationDialog";
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import {ApplicationState} from "../../../store";

interface Props {
    rows: EducationInformation[];
    isOwnProfile: boolean;
}

const EducationInformationCard = (props: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);

    const [showDialog, setShowDialog] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [isNewEducation, setIsNewEducation] = useState(false);
    const [selectedEducation, setSelectedEducation] = useState<EducationInformation>({
        grade: 0,
        description: '',
        schoolName: '',
        startDate: '',
        endDate: '',
        schoolType: SchoolTypeEnum.none,
        specialization: ''
    });
    const [selectedEducationIndex, setSelectedEducationIndex] = useState(0);
    const dispatch = useDispatch();

    const confirmDeleteEducation = (result: boolean) => {
        if (result) {
            FirebaseUtil.write('Users', user!.id!,
                {
                    education: user!.education!.filter((experience, i) => selectedEducationIndex !== i)
                }).catch(e => {
                    dispatch(snackbarSlice.actions.show({
                        message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                        severity: 'error',
                    }))
                }
            );
        }
        setShowWarningDialog(false);
    }

    return (
        props.isOwnProfile || props.rows?.length > 0 ? <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => {
                }}
                title={'Studii'}
                hideEditButton={true}
            />
            <div className={'solid'}/>
            {
                props.rows?.map((education, index) => {
                    return (
                        <div key={`education-card-item-${index}`}>
                            <CardItem
                                education={education}
                                cardType={CardTypeEnum.education}
                                editCallback={() => {
                                    setSelectedEducation(education);
                                    setSelectedEducationIndex(index);
                                    setIsNewEducation(false);

                                    setShowDialog(true);
                                }}
                                deleteCallback={() => {
                                    setSelectedEducationIndex(index)
                                    setShowWarningDialog(true);
                                }}
                                isOwnProfile={props.isOwnProfile}
                            />
                            <div className={'solid-thin'}/>
                        </div>
                    );
                })
            }
            <Button onClick={() => {
                setSelectedEducation({
                    grade: 0,
                    description: '',
                    schoolName: '',
                    startDate: '',
                    endDate: '',
                    schoolType: SchoolTypeEnum.none,
                    specialization: ''
                });
                setIsNewEducation(true);
                setShowDialog(true);
            }}
                    style={{textTransform: 'uppercase', marginTop: 10}}>
                {
                    props.isOwnProfile &&
                    <>
                        <Add color={'primary'} fontSize={'small'}/>
                        <span>Adaugă studii</span>
                    </>
                }
            </Button>
            {
                showDialog &&
                <EducationDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    isNewEducation={isNewEducation}
                    education={selectedEducation}
                    educationIndex={selectedEducationIndex}
                />
            }
            {
                DialogUtil.confirm({
                        title: 'Ești sigur că dorești sa stergi aceste studii?',
                    },
                    showWarningDialog,
                    confirmDeleteEducation,
                    true
                )
            }
        </div> : null
    );
}

export default EducationInformationCard;