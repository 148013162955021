import React from 'react';
import {Button} from "@mui/material";
import {useNavigate} from "react-router";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div style={{position: 'relative',height: '100vh', top: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h1 style={{fontWeight: 400}}>Pagina inexistenta</h1>
            <h4 style={{fontWeight: 400}}>Se pare ca ai te-ai pierdut. Incearca sa te intorci la pagina precedenta sau</h4>
            <Button
                onClick={() => {
                    navigate('/');
                }}
                style={{textTransform: 'none', fontSize: 16, marginTop: 10}}>
                Intoarce-te acasa
            </Button>
        </div>
    );
}

export default PageNotFound;