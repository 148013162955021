import React, {useEffect, useState} from 'react';
import {Job} from "../../../models/Job";
import './jobCard.scss';
import {Assignment, Delete} from "@mui/icons-material";
import {Button, Dialog, DialogContent, IconButton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import '../../Profile/Cards/cardItem.scss'
import './jobCard.scss';
import {useNavigate} from "react-router";
import {authenticationSlice, CompanyDetails} from "../../../store/Authentication";
import JobDetailsComponent from "../../JobDetails/JobDetailsComponent";
import {snackbarSlice} from "../../../store/Snackbar";
import FirebaseUtil from "../../Firebase/Firebase";
import {DialogUtil} from "../../Utils/DialogUtil";
import JobApplyDialog from "../../JobApplyDialog";
import {applyToJob} from "../../JobListItem/JobListItem";

interface Props {
    job: Job;
    isOwnProfile: boolean;
    companyProfile: CompanyDetails | undefined;
}

const JobCard = ({job, isOwnProfile, companyProfile}: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [showConfirmApplyExternalDialog, setShowConfirmApplyExternalDialog] = useState(false);
    const [showApplyDialog, setShowApplyDialog] = useState<boolean>(false);
    const [isAwaitingRequestResponse, setIsAwaitingRequestResponse] = useState<boolean>(false);

    const applyCallback = async (coverLetter: string) => {
        setIsAwaitingRequestResponse(true);
        const result = await applyToJob(user, job, coverLetter);
        if (!result) {
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error'
            }));
        } else {
            dispatch(snackbarSlice.actions.show({
                message: 'Ai aplicat cu succes!',
                severity: 'success'
            }));
            dispatch(authenticationSlice.actions.setUser(
                {
                    userData: {
                        ...user,
                        jobApplicationsIds: [...user?.jobApplicationsIds ?? [], job.id]
                    }
                }));
        }
        setShowApplyDialog(false);
        setIsAwaitingRequestResponse(false);
    }

    const deleteJob = async (option: boolean) => {
        setShowConfirmDeleteDialog(false);
        if (!company || !option)
            return;

        try {
            // delete all applications for this job
            // await FirebaseUtil.deleteDocs('JobApplications', {
            //     fieldPath: 'jobId',
            //     opStr: '==',
            //     value: job.id
            // })
            await FirebaseUtil.deleteDoc('Jobs', job.id);
            dispatch(authenticationSlice.actions.setCompany({
                companyData: {
                    ...company,
                    jobs: company.jobs!.filter(j => j.id !== job.id)
                }
            }));
            dispatch(snackbarSlice.actions.show({
                message: 'Job sters cu succes!',
                severity: 'success'
            }));
        } catch (e: any) {
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu!',
                severity: 'error'
            }));
            console.error(e.message)
        }
    }

    const redirectExternal = (option: boolean) => {
        setShowConfirmApplyExternalDialog(false);
        if (!option)
            return;

        const newWindow = window.open(job.externalUrl!, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <div className={'card-item rounded'}>
                <div className={'job-card'}
                     onClick={() => {
                         navigate('/joburi/' + job.id);
                     }}>
                    <div className={'leading-icon-container'}>
                        {
                            companyProfile?.photoURL ?
                                <img src={companyProfile.photoURL!} className={'leading-image'}/> :
                                <Assignment className={'leading-icon'}/>
                        }
                    </div>
                    <div className={'card-item-content-container'}>
                        <div className={'card-title'}>
                            {job.title}
                        </div>
                        <div className={'card-subtitle'}>
                            {job.industry + ' · ' + job.jobType}
                        </div>
                        <div className={'card-location'}>
                            {job.locationType}
                        </div>
                        <div className={'card-location'}>
                            {job.numberOfOpenings + (job.numberOfOpenings > 1 ? ' poziții disponibile' : ' poziție disponibila')}
                        </div>
                    </div>
                </div>
                <div className={'job-card-button-container-large'}>
                    {
                        isOwnProfile ?
                            <>
                                <Button
                                    onClick={async () => {
                                        await FirebaseUtil.write('Jobs', job.id, {
                                            active: !job.active
                                        })
                                        let newJobs = [...company!.jobs ?? []];
                                        newJobs = newJobs.map(j => j.id !== job.id ? j : {
                                            ...j,
                                            active: !job.active
                                        })
                                        dispatch(authenticationSlice.actions.setCompany({
                                            companyData: {
                                                ...company,
                                                jobs: newJobs
                                            }
                                        }))
                                        dispatch(snackbarSlice.actions.show({
                                            message: job.active ? 'Job dezactivat cu succes!' : 'Job activat cu succes!',
                                            severity: 'success'
                                        }))
                                    }}
                                    className={'apply-button'}
                                    style={{
                                        color: 'white',
                                        backgroundColor: job.active ? 'green' : 'red',
                                        padding: '5px 15px',
                                        borderRadius: 12,
                                    }}
                                >
                                    {job.active ? 'Activ' : 'Inactiv'}
                                </Button>
                                <IconButton
                                    onClick={() => setShowConfirmDeleteDialog(true)}
                                    style={{marginLeft: 10}}
                                >
                                    <Delete/>
                                </IconButton>
                            </>
                            :
                            !company ?
                                !user?.jobApplicationsIds?.find(jobId => jobId === job.id) ?
                                    <Button
                                        onClick={() => {
                                            if (!user) {
                                                navigate(`/conectare?callbackUrl=/joburi/${job.id}`)
                                                return;
                                            }

                                            if (!user.active) {
                                                dispatch(snackbarSlice.actions.show({
                                                    message: 'Activeaza-ti contul pentru a putea aplica la joburi!',
                                                    severity: 'error',
                                                }))
                                                navigate('/setari');
                                                return;
                                            }

                                            if (job.applyExternal) {
                                                setShowConfirmApplyExternalDialog(true);
                                            } else if (job.needLetter) {
                                                setShowApplyDialog(true)
                                            } else {
                                                applyCallback('');
                                            }
                                        }}
                                        disabled={isAwaitingRequestResponse}
                                        className={'apply-button'}
                                        style={{
                                            color: 'white',
                                            backgroundColor: 'var(--dark-primary)',
                                            padding: '10px 16px',
                                            borderRadius: 12,
                                        }}
                                    >
                                        {job.applyExternal ? 'Aplică extern' : 'Aplică'}
                                    </Button>
                                    : <Button
                                        className={'applied-to-job-button disable-hover'}
                                        disabled={true}
                                    >
                                        Aplicat
                                    </Button>
                                : null
                    }
                </div>
            </div>

            <div className={'job-card-button-container-small'}>
                {
                    isOwnProfile ?
                        <>
                            <Button
                                onClick={async () => {
                                    await FirebaseUtil.write('Jobs', job.id, {
                                        active: !job.active
                                    })
                                    let newJobs = [...company!.jobs ?? []];
                                    newJobs = newJobs.map(j => j.id !== job.id ? j : {
                                        ...j,
                                        active: !job.active
                                    })
                                    dispatch(authenticationSlice.actions.setCompany({
                                        companyData: {
                                            ...company,
                                            jobs: newJobs
                                        }
                                    }))
                                    dispatch(snackbarSlice.actions.show({
                                        message: job.active ? 'Job dezactivat cu succes!' : 'Job activat cu succes!',
                                        severity: 'success'
                                    }))
                                }}
                                className={'apply-button'}
                                style={{
                                    color: 'white',
                                    backgroundColor: job.active ? 'green' : 'red',
                                    padding: '5px 15px',
                                    borderRadius: 12,
                                }}
                            >
                                {job.active ? 'Activ' : 'Inactiv'}
                            </Button>
                            <IconButton
                                onClick={() => setShowConfirmDeleteDialog(true)}
                                style={{marginLeft: 10}}
                            >
                                <Delete/>
                            </IconButton>
                        </>
                        : !!user ?
                            <Button
                                onClick={() => {
                                }}
                                style={{
                                    color: 'white',
                                    backgroundColor: 'var(--dark-primary)',
                                    padding: '5px 15px',
                                    borderRadius: 12,
                                }}
                            >
                                Aplică
                            </Button> : null
                }
            </div>
            {
                DialogUtil.confirm(
                    {
                        title: 'Ești sigur că dorești sa stergi acest job? Actiunea este ireversibila.',
                    },
                    showConfirmDeleteDialog,
                    deleteJob,
                    true
                )
            }
            {
                DialogUtil.confirm(
                    {
                        title: 'Vei fi redirecționat pe o pagină externă. Ești sigur că dorești acest lucru?',
                    },
                    showConfirmApplyExternalDialog,
                    redirectExternal,
                    true
                )
            }
            {
                showApplyDialog &&
                <JobApplyDialog
                    closeCallback={() => setShowApplyDialog(false)}
                    applyCallback={applyCallback}
                    job={job}
                />
            }
        </>
    );
}

export default JobCard;