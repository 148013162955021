import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import firestore from "firebase/firestore";
import {LabelContentItem} from "../components/Profile/Profile";
import {EducationInformation, ExperienceInformation, TrainingInformation} from "../components/Profile/Cards/CardItem";
import {IndustriesEnum} from "../components/CompanyProfile/Dialogs/CompanyProfileDialog";
import {Job} from "../models/Job";

export interface CompanyCachedInformation {
    name: string,
    photoURL: string,
    email: string
}

export interface DataState {
    companiesInformation: Map<string, CompanyCachedInformation>;
}

const initialState : DataState = {
    companiesInformation: new Map<string, CompanyCachedInformation>()
}

export const dataSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        setCompaniesInformation: (state: DataState, {payload}: PayloadAction<{
            companiesInformation: Map<string, CompanyCachedInformation>
        }>) => {
            state.companiesInformation = new Map(payload.companiesInformation);
        },
    }
});

export default dataSlice.reducer;