import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    TextField
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";

interface DialogProps {
    closeCallback: () => void;
    coverLetter: string | null;
    userName: string;
}

const CoverLetterDialog = (props: DialogProps) => {
    return <Dialog
        open={true}
        onClose={(event, reason) => {
            if (reason && reason === 'backdropClick')
                return;
            props.closeCallback();
        }}
        className={'form-dialog'}
        maxWidth={'md'}
        fullWidth
    >
        <DialogTitle>{'Scrisoare de intenție - ' + props.userName}</DialogTitle>
        <DialogContent style={{paddingTop: 5, paddingBottom: 5}}>
            <div className={'form-row'} style={{justifyContent: 'flex-start'}}
                 dangerouslySetInnerHTML={{__html: props.coverLetter!}}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeCallback}
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                Inchide
            </Button>
        </DialogActions>
    </Dialog>
}

export default CoverLetterDialog;