import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AlertColor} from '@mui/material/Alert'

export interface SnackbarState { open: boolean,
    message: string,
    severity: AlertColor,
    autoHideDuration: number};

const initialState : SnackbarState = {
    open: false,
    message: '',
    severity: 'success',
    autoHideDuration: 3000
}

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        show(state : SnackbarState, { payload } : PayloadAction<{
            message : string,
            severity : AlertColor,
            autoHideDuration?: number
        }>) {
            state.autoHideDuration = payload.autoHideDuration || initialState.autoHideDuration;
            state.message = payload.message
            state.severity = payload.severity
            state.open = true;
        },
        hide(state : SnackbarState){
            state.open = false;
        }
    },
});

export default snackbarSlice.reducer;