import MotionHoc from "../MotionHoc";
import './home.scss'
import React, {useEffect, useState} from "react";
import {ApplicationState} from "../../store";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Button} from "@mui/material";
import {JobsListComponent} from "../JobsList/JobsList";
import JobsLogo from '../../images/resita-jobs-logo.png';

const HomeComponent = () => {
    const company = useSelector((s: ApplicationState) => s.auth.company)
    const user = useSelector((s: ApplicationState) => s.auth.user)
    const isLoggedIn = !!company || !!user;
    const navigate = useNavigate();

    const [filterText, setFilterText] = useState<string>('');

    useEffect(() => {
        const header = document.getElementById('header-container');
        const dim = header?.getBoundingClientRect();
        const eventListener = (e: any) => animateOnMouseOver(e, dim);
        window?.addEventListener("mousemove", eventListener);

        return () => {
            window?.removeEventListener('mousemove', eventListener);
        }
    }, [])

    const animateOnMouseOver = (e: any, dim: any) => {
        if (window.innerWidth <= 768)
            return;

        const circle1 = document.getElementById('circle-1');
        const circle2 = document.getElementById('circle-2');
        const circle3 = document.getElementById('circle-3');
        const circle4 = document.getElementById('circle-4');
        const circle5 = document.getElementById('circle-5');


        if (dim) {
            const cursorPosition = {
                x: e.clientX - Math.round(dim.left),
                y: e.clientY - Math.round(dim.top),
            }

            const fpos = {
                x: 15 * ((cursorPosition.x / dim.width) * 2),
                y: 15 * ((cursorPosition.y / dim.width) * 2),
            }

            if (!!circle1)
                circle1!.style.transform = `translate(${fpos.x * 0.6}px, ${fpos.y * 0.6}px)`;
            if (!!circle2)
                circle2!.style.transform = `translate(${fpos.x * 1.7}px, ${fpos.y * 1.7}px)`;
            if (!!circle3)
                circle3!.style.transform = `translate(${fpos.x * 1.1}px, ${fpos.y * 1.1}px)`;
            if (!!circle4)
                circle4!.style.transform = `translate(${fpos.x * 1.3}px, ${fpos.y * 1.3}px)`;
            if (!!circle5)
                circle5!.style.transform = `translate(${fpos.x * 0.3}px, ${fpos.y * 0.3}px)`;
        }
    }

    return (
        <>
            <div id={'header-container'} className={'header-container'}>
                <div id={'circle-1'} className={'circle'}/>
                <div id={'circle-2'} className={'circle'}/>
                <div id={'circle-3'} className={'circle'}/>
                <div id={'circle-4'} className={'circle'}/>
                <div id={'circle-5'} className={'circle'}/>
                <div className={'section-hero'}>

                    <img className={'resita-jobs-logo'} src={JobsLogo}/>
                    <h1 style={{fontSize: 60}}>
                        Cauți un <span style={{color: 'var(--dark-primary)', textDecoration: 'underline'}}>job</span>?
                    </h1>
                    <p>Aici vei găsi mai multe joburi decât crezi!</p>
                    <div className={'search-text-field'}>
                        <input type="text"
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter' || e.key === 'Return') {
                                       navigate(filterText ? ('/joburi?query=' + filterText) : '/joburi');
                                   }
                               }}
                               className="search-input"
                               placeholder="Căutare joburi"/>
                        <Button
                            className={'button-primary search'}
                            onClick={() => {
                                navigate(filterText ? ('/joburi?query=' + filterText) : '/joburi');
                            }}
                        >
                            Caută job
                        </Button>
                    </div>
                </div>
            </div>
            <JobsListComponent isForHomePage={true}/>
        </>
    );
};

const Home = MotionHoc(HomeComponent);

export default Home;
