import React, {useState} from 'react';
import CardHeader from "../../Profile/Cards/CardHeader";
import DescriptionDialog from "../Dialogs/DescriptionDialog";

interface Props{
    description: string | undefined;
    isOwnProfile: boolean;
    saveCallback: (newDescription: string) => void;
    isForJob?: boolean;
}

const DescriptionInformationCard = (props: Props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    return (
        props.description || props.isOwnProfile ? <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => setShowDialog(true)}
                title={'Descriere'}
            />
            <div className={'solid'}/>
            <div className={'body-container'} dangerouslySetInnerHTML={{__html: props.description ?? 'Nicio descriere.'}}/>
            {
                showDialog && <DescriptionDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    saveCallback={async (newDescription) => {
                        await props.saveCallback(newDescription);
                        setShowDialog(false);
                    }}
                    description={props.description ?? ''}
                    isForJob={props.isForJob}
                />
            }
        </div> : null
    )
}

export default DescriptionInformationCard;