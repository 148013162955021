import React, {useEffect, useState} from 'react';
import CardHeader from "./CardHeader";
import {LabelContentItem} from "../Profile";
import AboutDialog from "../Dialogs/AboutDialog";

interface Props {
    rows: LabelContentItem[];
    isOwnProfile: boolean;
}

const AboutInformationCard = (props: Props) => {
    const [notNullRows, setNotNullRows] = useState<LabelContentItem[]>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        if (props.rows) {
            var rows = props.rows.filter(row => row.content !== null && row.content !== undefined);
            setNotNullRows(rows);
        }
    }, [props.rows]);

    return (
        props.isOwnProfile || notNullRows.length > 0 ? <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => setShowDialog(true)}
                title={'Informații generale'}
            />
            <div className={'solid'}/>
            {
                notNullRows.length === 0 &&
                <div>
                    Nicio informatie. Adaugă informații folosind butonul de editare.
                </div>
            }
            {
                notNullRows?.map((row, index) => {
                    return row.content ? (
                        <div key={`information-row-${index}`} className={'information-row'}>
                            <div className={'label'}>{row.label + ':'}</div>
                            <div className={'content'}>{row.content}</div>
                        </div>
                    ) : null;
                })
            }
            {
                showDialog && <AboutDialog open={true} closeCallback={() => setShowDialog(false)}/>
            }
        </div> : null
    );
}

export default AboutInformationCard;