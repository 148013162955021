import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import './skillsDialog.scss';
import {snackbarSlice} from "../../../store/Snackbar";
import {EducationInformation, SchoolTypeEnum, TrainingInformation} from "../Cards/CardItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import DateUtil from "../../Utils/DateUtil";
import {IndustriesEnum} from "../../CompanyProfile/Dialogs/CompanyProfileDialog";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    training: TrainingInformation;
    isNewTraining?: boolean;
    trainingIndex?: number;
}

const TrainingDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [training, setTraining] = useState<TrainingInformation>({
        ...props.training,
        startDate: props.training.startDate ? DateUtil.getDateFromTimestamp(props.training.startDate) : null,
        endDate: props.training.endDate ? DateUtil.getDateFromTimestamp(props.training.endDate) : null
    });
    const [currentlyStudying, setCurrentlyStudying] = useState(props.training.endDate === null && props.training.startDate !== null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsFormValid(
            training.trainingName.length > 0 &&
            !!training.industry && ((training.industry as unknown) as string).length > 0 &&
            !!training.startDate && !isNaN(training.startDate) &&
            (currentlyStudying || (!!training.endDate && !isNaN(training.endDate)))
        );
    }, [training, currentlyStudying]);

    const saveCallback = async () => {
        setIsLoading(true);
        let userTraining = [...user!.trainings ?? []];

        handleChange(training.startDate, 'startDate');
        handleChange(training.endDate ?? '', 'endDate');

        if (props.isNewTraining) {
            userTraining = [...userTraining, training];
        } else {
            userTraining[props.trainingIndex!] = training;
        }

        await FirebaseUtil.write('Users', user!.id!,
            {
                trainings: userTraining
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        );
        props.closeCallback();
    }

    const handleChange = (value: string | number | object | IndustriesEnum, key: string) => {
        const newTraining = {...training};
        switch (key) {
            case 'startDate':
                newTraining.startDate = value as object;
                break;
            case 'endDate':
                newTraining.endDate = value as object;
                break;
            case 'description':
                newTraining.description = value as string;
                break;
            case 'trainingName':
                newTraining.trainingName = value as string;
                break;
            case 'industry':
                newTraining.industry = value as IndustriesEnum;
                break;
        }

        setTraining(newTraining);
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if(reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>
                {
                    props.isNewTraining ? 'Adaugă training' : 'Editează training'
                }
            </DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <TextField
                    label={'Numele training-ului'}
                    placeholder={'Ex: Curs machiaj'}
                    required
                    type={'text'}
                    fullWidth
                    value={training.trainingName}
                    onChange={(e) => handleChange(e.target.value, 'trainingName')}
                    variant={'standard'}
                    className={'form-text-field'}
                    error={false}
                />
                <Autocomplete
                    value={training.industry}
                    onChange={(e, v) => {
                        handleChange(v as IndustriesEnum, 'industry')
                    }}
                    fullWidth
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label={'Domeniu'}
                                required
                                error={!training.industry}
                                variant={'standard'}
                                className={'form-text-field'}

                            />
                        );
                    }}
                    options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                    componentsProps={{
                        paper: {
                            style: {
                                borderRadius: '0 0 20px 20px'
                            }
                        }
                    }}/>
                <div className={'form-row'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={'Data inceperii'}
                            inputFormat={'dd/MM/yyyy'}
                            value={training.startDate ? training.startDate : null}
                            onChange={(value) => handleChange(value ?? '', 'startDate')}
                            renderInput={(params) => <TextField
                                required
                                variant={'standard'}
                                type={'date'}
                                className={'form-text-field left'}
                                fullWidth
                                error={params.error}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'ZZ/LL/AAAA',
                                }}
                            />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disabled={currentlyStudying}
                            label={'Data finalizarii'}
                            inputFormat={'dd/MM/yyyy'}
                            value={training.endDate && !currentlyStudying ? training.endDate : null}
                            onChange={(value) => {
                                handleChange(value, 'endDate')
                            }}
                            renderInput={(params) => {
                                return <TextField
                                    variant={'standard'}
                                    required
                                    type={'date'}
                                    className={'form-text-field right'}
                                    fullWidth
                                    error={params.error}
                                    InputLabelProps={{shrink: currentlyStudying ? true : undefined}}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: currentlyStudying ? 'Prezent' : 'ZZ/LL/AAAA',
                                    }}
                                />
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className={'form-text-field'}
                     style={{marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentlyStudying}
                                onChange={(e) => {
                                    setCurrentlyStudying(e.target.checked)
                                }}
                            />}
                        label={'Training in desfasurare'}/>
                </div>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'description')}
                    label={'Descriere'}
                    type={'text'}
                    fullWidth
                    value={training.description}
                    variant={'standard'}
                    multiline={true}
                    minRows={2}
                    className={'form-text-field'}
                    placeholder={'Descrie ce ai invatat, de ce ai participat la acest training etc.'}
                />
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default TrainingDialog;