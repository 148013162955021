import React, {useState} from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, IconButton, InputAdornment,
    InputLabel, Slider,
    TextField
} from "@mui/material";
import '../Profile/profile.scss';
import '../JobsFilterCard/filtersDialog.scss'
import {CareerLevelEnum} from "../../models/Job";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {SchoolTypeEnum} from "../Profile/Cards/CardItem";
import {CareerLevelArray} from "../JobsFilterCard/JobsFilterDialog";
import {Add, ArrowForwardRounded, ArrowRight, ArrowRightRounded, Delete} from "@mui/icons-material";
import {SkillLevelEnum} from "../Profile/Dialogs/SkillsDialog";
import {LabelContentItem} from "../Profile/Profile";

export interface AgeInterval {
    minAge: number | null;
    maxAge: number | null;
}

export const SchoolTypeArray: SchoolTypeEnum[] = [
    SchoolTypeEnum.generala,
    SchoolTypeEnum.liceu,
    SchoolTypeEnum.profesionala,
    SchoolTypeEnum.postliceale,
    SchoolTypeEnum.facultate,
    SchoolTypeEnum.masterat,
    SchoolTypeEnum.doctorat,
    SchoolTypeEnum.mba
];

interface Props {
    closeCallback: () => void;
    saveCallback: (
        careerLevel: CareerLevelEnum[],
        ageInterval: AgeInterval,
        industries: IndustriesEnum[],
        languages: string[],
        education: SchoolTypeEnum | null,
        skills: LabelContentItem[]
    ) => void;
    careerLevel: CareerLevelEnum[];
    ageInterval: AgeInterval;
    industries: IndustriesEnum[];
    languages: string[];
    education: SchoolTypeEnum | null;
    skills: LabelContentItem[];
}

const CandidatesFilterDialog = (props: Props) => {
    const [careerLevel, setCareerLevel] = useState<CareerLevelEnum[]>(props.careerLevel);
    const [ageInterval, setAgeInterval] = useState<AgeInterval>(props.ageInterval);
    const [languages, setLanguages] = useState<string[]>(props.languages);
    const [skills, setSkills] = useState<LabelContentItem[]>(props.skills);
    const [industries, setIndustries] = useState<IndustriesEnum[]>(props.industries);
    const [education, setEducation] = useState<SchoolTypeEnum | null>(props.education);

    const [currentLanguage,setCurrentLanguage] = useState<string>('');

    const addLanguage = () => {
        const lower = currentLanguage.trim().toLowerCase();
        const word = currentLanguage.charAt(0).toUpperCase() + lower.slice(1);
        setLanguages((oldLanguages) => {
            return [...oldLanguages, word]
        });
        setCurrentLanguage('');
    }

    return (
        <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>Filtrează candidatii</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <div className={'form-row'} style={{alignItems: 'center'}}>
                    <TextField
                        className={'form-text-field left'}
                        style={{flex: 1}}
                        type={'number'}
                        inputProps={{min: 0}}
                        InputLabelProps={{shrink: true, style: {fontSize: 18}}}
                        fullWidth
                        variant={'standard'}
                        label={'Vârsta minima'}
                        value={ageInterval.minAge ?? ''}
                        onChange={(e) => {
                            setAgeInterval((oldAgeInterval) => {
                                return {...oldAgeInterval, minAge: parseInt(e.target.value)}
                            });
                        }}
                        onBlur={() => {
                            if(ageInterval.maxAge && ageInterval.minAge && ageInterval.minAge > ageInterval.maxAge)
                            {
                                setAgeInterval((oldAgeInterval) => {
                                    return {minAge: oldAgeInterval.maxAge, maxAge: oldAgeInterval.minAge}
                                });
                            }
                        }}
                    />
                    <div style={{fontSize: 24, fontWeight: 300, textAlign: 'center'}}> - </div>
                    <TextField
                        className={'form-text-field right'}
                        type={'number'}
                        style={{flex: 1}}
                        inputProps={{min: 0}}
                        InputLabelProps={{shrink: true, style: {fontSize: 18}}}
                        fullWidth
                        variant={'standard'}
                        label={'Vârsta maxima'}
                        value={ageInterval.maxAge ?? ''}
                        onChange={(e) => {
                            setAgeInterval((oldAgeInterval) => {
                                return {...oldAgeInterval, maxAge: parseInt(e.target.value)}
                            });
                        }}
                        onBlur={() => {
                            if(ageInterval.maxAge && ageInterval.minAge && ageInterval.minAge > ageInterval.maxAge)
                            {
                                setAgeInterval((oldAgeInterval) => {
                                    return {minAge: oldAgeInterval.maxAge, maxAge: oldAgeInterval.minAge}
                                });
                            }
                        }}
                    />
                </div>
                <InputLabel id={'industries-label'} style={{fontSize: 14}}>Limbi vorbite</InputLabel>
                <div className={'form-row industries-container'} style={{alignItems: 'stretch', paddingTop: 5}}>
                    <TextField
                        className={'form-text-field'}
                        style={{flex: 1}}
                        fullWidth
                        placeholder={'Ex: Engleza'}
                        variant={'standard'}
                        value={currentLanguage}
                        onChange={(e) => {
                            setCurrentLanguage(e.target.value)
                        }}
                        onKeyDown={(ev) => {
                            if(ev.key === 'Enter' || ev.key === 'Return'){
                                addLanguage();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        size={'small'}
                                        onClick={addLanguage}>
                                        {currentLanguage.length > 0 && <ArrowForwardRounded />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={'vertical-divider'}/>
                    <div className={'form-text-field'} style={{flex: 1, paddingLeft: 0, minHeight: 60}}>
                        {
                            languages && languages.map(language => {
                                return <Chip
                                    key={language}
                                    label={language}
                                    style={{borderRadius: 6, margin: 5}}
                                    color={'primary'}
                                    onDelete={() => {
                                        setLanguages(languages.filter(lng => lng !== language))
                                    }}
                                />
                            })
                        }
                    </div>
                </div>
                <InputLabel id={'industries-label'} style={{fontSize: 14}}>Experiență in domeniile</InputLabel>
                <div className={'form-row industries-container'} style={{alignItems: 'stretch', paddingTop: 15}}>
                    <Autocomplete
                        multiple
                        value={industries}
                        onChange={(e, v) => {
                            setIndustries(v as IndustriesEnum[]);
                        }}
                        fullWidth
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    variant={'standard'}
                                    className={'form-text-field'}
                                    placeholder={'Caută un domeniu'}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: [],
                                    }}
                                />
                            );
                        }}
                        options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                        getOptionLabel={(option) => {
                            return option.toString();
                        }}
                        style={{flex: 1}}
                        componentsProps={{
                            paper: {
                                style: {
                                    borderRadius: '20px',
                                }
                            }
                        }}/>
                    <div className={'vertical-divider'}/>
                    <div className={'form-text-field'} style={{flex: 1, paddingLeft: 0, minHeight: 60}}>
                        {
                            industries && industries.length > 0 ? industries.map(industry => {
                                return <Chip
                                    key={industry}
                                    label={industry}
                                    style={{borderRadius: 6, margin: 5}}
                                    color={'primary'}
                                    onDelete={() => {
                                        setIndustries(industries.filter(ind => ind !== industry))
                                    }}
                                />
                            }) : <Chip
                                label={'Orice domeniu'}
                                style={{borderRadius: 6, margin: 5, backgroundColor: 'rgba(0,0,0,0.25)', fontWeight: 400}}
                            />
                        }
                    </div>
                </div>
                <div className={'form-row'} style={{alignItems: 'flex-start', paddingTop: 15}}>
                    <div className={'form-text-field left'} style={{flex: 1}}>
                        <InputLabel id={'jobType-label'} style={{fontSize: 14}}>Studii minime</InputLabel>
                        <div className={''}>
                            {
                                SchoolTypeArray.map(schoolType => {
                                    return <div key={schoolType}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={education === schoolType}
                                                    onChange={(e) => {
                                                        if (education !== schoolType) {
                                                            setEducation(schoolType);
                                                        } else {
                                                            setEducation(null);
                                                        }
                                                    }}
                                                />}
                                            label={schoolType}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={'skills-container form-text-field'}>
                    <InputLabel id={'locationType-label'} style={{fontSize: 14, paddingBottom: 6}}>Aptitudini
                        necesare</InputLabel>
                    {
                        skills && skills.length > 0 ? skills.map((skill, index) => {
                            return (
                                <div key={'skill-' + index}>
                                    <div className={'labels-row'}>
                                        <div style={{fontSize: 14, width: '50%', marginRight: 5}}>
                                            Denumire *
                                        </div>
                                        <div className={'skill-level-large'} style={{fontSize: 14}}>
                                            {'Nivel minim - ' + skill.content}
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <TextField
                                            onChange={(e) => {
                                                if(e.target.value.includes(';'))
                                                    e.target.value = e.target.value.replaceAll(';', '');

                                                const newSkills = [...skills];
                                                newSkills[index] = {
                                                    label: e.target.value,
                                                    content: skill.content
                                                }
                                                setSkills(newSkills);
                                            }}
                                            placeholder={'Ex: C++'}
                                            type={'text'}
                                            fullWidth
                                            value={skill.label}
                                            variant={'standard'}
                                            className={'form-text-field left'}
                                            style={{flex: 1, height: 40}}
                                        />
                                        <div className={'form-text-field right fullwidth-mobile'} style={{flex: 1}}>
                                            <div className={'skill-level-small'} style={{fontSize: 14}}>
                                                {'Nivel - ' + skill.content}
                                            </div>
                                            <Slider
                                                key={`slider-${index}`}
                                                className={'skill-slider color-' + SkillLevelEnum[skill.content]}
                                                style={{height: 40, paddingTop: 0}}
                                                step={null}
                                                value={SkillLevelEnum[skill.content] as any}
                                                marks={[
                                                    {
                                                        value: 10,
                                                    },
                                                    {
                                                        value: 30,
                                                    },
                                                    {
                                                        value: 50,
                                                    },
                                                    {
                                                        value: 70,
                                                    },
                                                    {
                                                        value: 90,
                                                    },
                                                ]}
                                                track={false}
                                                onChange={(e, value) => {
                                                    const newSkills = [...skills];
                                                    newSkills[index] = {
                                                        label: skill.label,
                                                        content: SkillLevelEnum[value as any]
                                                    }
                                                    setSkills(newSkills);
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                            height: 80,
                                            width: 40,
                                            paddingLeft: window.innerWidth < 768 ? 0 : 10
                                        }}>
                                            <IconButton onClick={() => {
                                                const newSkills = skills.filter((skill, i) => i !== index);
                                                setSkills(newSkills);
                                            }}>
                                                <Delete/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) : null
                    }
                    {
                        skills.length < 7 &&
                        <Button onClick={() => {
                            setSkills([...skills, {
                                label: '',
                                content: 'Mediu'
                            }]);
                        }} style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                            {
                                <>
                                    <Add color={'primary'} fontSize={'small'}/>
                                    <span>Adaugă aptitudine</span>
                                </>
                            }
                        </Button>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.closeCallback}
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}
                >
                    Anulează
                </Button>
                <Button
                    onClick={() => props.saveCallback(
                        careerLevel,
                        ageInterval,
                        industries,
                        languages,
                        education,
                        skills
                    )}
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}
                >
                    Aplică filtrele
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CandidatesFilterDialog;