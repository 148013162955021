import React, {useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Slider,
    TextField
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../../Profile/profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import {CareerLevelEnum, Job, JobTypeEnum, LocationTypeEnum} from "../../../models/Job";
import {IndustriesEnum} from "../../CompanyProfile/Dialogs/CompanyProfileDialog";
import {useJsApiLoader} from "@react-google-maps/api";
import {geocode, GOOGLE_API_KEY} from "../../../App";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    job: Job;
}

const JobInformationDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);

    const [job, setJob] = useState<Job>(props.job);
    const [jobAddress, setJobAddress] = useState<string>(job.address!);
    const [addressHasError, setAddressHasError] = useState(false);
    const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const dispatch = useDispatch();
    const websiteRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });

    useEffect(() => {
        if (job.address) {
            const autocompleteService = new google.maps.places.AutocompleteService();
            const request: google.maps.places.AutocompletionRequest = {
                input: job.address,
                types: ['address']
            };
            autocompleteService.getPlacePredictions(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    if (results) {
                        handleChange(results[0], 'googleAddress');
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        setAddressHasError(!!job.googleAddress &&
            // !job.googleAddress.address_components.some(component => component.long_name.includes('Reșița') || component.long_name.includes('Resita')));
            !job.googleAddress.description.includes('Resita') && !job.googleAddress.description.includes('Reșița'));
    }, [job.googleAddress]);

    const ref = useRef({
        timeoutId: 0,
        cancel: false,
        clear: () => {
            if (ref.current.timeoutId) {
                clearTimeout(ref.current.timeoutId);
            }
            ref.current.timeoutId = 0;
        },
    });

    useEffect(() => {
        if (isLoaded && jobAddress !== job?.googleAddress?.description) {
            if (isLoaded && jobAddress !== job?.googleAddress?.description) {
                ref.current.clear();
                ref.current.timeoutId = setTimeout(() => {
                    if (jobAddress?.length < 3) {
                        setOptions([]);
                        return;
                    }
                    const autocompleteService = new google.maps.places.AutocompleteService();
                    const request: google.maps.places.AutocompletionRequest = {
                        input: jobAddress,
                        types: ['address']
                    };
                    autocompleteService.getPlacePredictions(request, (results, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            setOptions(results ?? []);
                        }
                    });
                }, 200) as any;
            }
        }
    }, [jobAddress, isLoaded]);

    useEffect(() => {
        const isTitleValid = job.title.trim().length > 0 && job.title.trim().length <= 50;
        const isJobTypeValid = !!job.jobType;
        const isCareerLevelValid = job.careerLevel.length > 0;
        const isOpeningsValid = !!job.numberOfOpenings && job.numberOfOpenings >= 1;
        const isLocationValid = !!job.locationType && (job.locationType === LocationTypeEnum.remote || (!!job.googleAddress && !addressHasError));
        const isSalaryValid = job.confidentialSalary || (!!job.salary && job.salary.length > 0);
        const areSkillsValid = job.skills.length === 0 || job.skills.every(skill => skill.label.length > 0);
        const isExternalApplyValid = !job.applyExternal || (!!job.externalUrl && websiteRegex.test(job.externalUrl));

        setIsFormValid(
            isTitleValid &&
            isJobTypeValid &&
            isCareerLevelValid &&
            isOpeningsValid &&
            isLocationValid &&
            isSalaryValid &&
            areSkillsValid &&
            isExternalApplyValid
        );
    }, [job, addressHasError]);

    const saveCallback = async () => {
        setIsLoading(true);
        let latitude, longitude;
        if (job.googleAddress && job.googleAddress.description.trim().length > 0) {
            await geocode.fromAddress(job.googleAddress!.description).then(
                (response) => {
                    const {lat, lng}: google.maps.LatLngLiteral = response.results[0].geometry.location;
                    latitude = lat;
                    longitude = lng;
                },
                (error) => {
                    console.log(error)
                    console.error('Address not found');
                }
            );
        }
        await FirebaseUtil.write('Jobs', job.id,
            {
                id: job.id,
                title: job.title,
                jobType: job.jobType,
                careerLevel: job.careerLevel,
                locationType: job.locationType,
                salary: job.salary ?? '',
                confidentialSalary: job.confidentialSalary,
                description: job.description,
                industry: job.industry,
                skills: job.skills,
                languages: job.languages,
                numberOfOpenings: job.numberOfOpenings,
                address: job.googleAddress?.description ?? null,
                latitude: latitude ?? null,
                longitude: longitude ?? null,
                applyExternal: job.applyExternal ?? false,
                externalUrl: job.externalUrl ?? '',
                needLetter: job.needLetter ?? false
            }).catch(e => {
                console.error(e.message);
                dispatch(snackbarSlice.actions.show({
                    message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                    severity: 'error',
                }))
            }
        ).finally(() => {
            props.closeCallback();
        });
    }

    const handleChange = (value: any, key: string) => {
        const newJob: Job = {...job};
        newJob[key] = value;

        if(key === 'needLetter' && value === true){
            newJob['applyExternal'] = false;
        }
        if(key === 'applyExternal' && value === true){
            newJob['needLetter'] = false;
        }

        setJob(newJob);
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>Editează informațiile jobului</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <TextField
                    id={'title'}
                    variant={'standard'}
                    fullWidth
                    label={'Titlul jobului'}
                    required
                    value={job.title}
                    onChange={(e) => handleChange(e.target.value, 'title')}
                    className={'form-text-field'}
                />
                <div className={'form-row'}>
                    <div className={'form-text-field left'} style={{width: '100%'}}>
                        <InputLabel id={'jobType-label'} style={{fontSize: 12}}>Nivelul jobului *</InputLabel>
                        <Select
                            onChange={(e) => handleChange(e.target.value as JobTypeEnum, 'jobType')}
                            labelId={'jobType-label'}
                            required
                            fullWidth
                            value={job.jobType}
                            variant={'standard'}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}
                        >
                            <MenuItem value={JobTypeEnum.fulltime}>{JobTypeEnum.fulltime}</MenuItem>
                            <MenuItem value={JobTypeEnum.parttime}>{JobTypeEnum.parttime}</MenuItem>
                            <MenuItem value={JobTypeEnum.internship}>{JobTypeEnum.internship}</MenuItem>
                            <MenuItem value={JobTypeEnum.contract}>{JobTypeEnum.contract}</MenuItem>
                            <MenuItem value={JobTypeEnum.voluntariat}>{JobTypeEnum.voluntariat}</MenuItem>
                        </Select>
                    </div>
                    <div className={'form-text-field right'} style={{width: '100%'}}>
                        <InputLabel id={'careerLevel-label'} style={{fontSize: 12}}>Experiență
                            *</InputLabel>
                        <Select
                            onChange={(e) => handleChange(e.target.value, 'careerLevel')}
                            labelId={'careerLevel-label'}
                            required
                            fullWidth
                            multiple
                            value={job.careerLevel}
                            renderValue={(value) => {
                                return value.length + (value.length === 1 ? ' selecție' : ' selecții');
                            }}
                            variant={'standard'}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}
                        >
                            <MenuItem value={CareerLevelEnum.noexperience}>{CareerLevelEnum.noexperience}</MenuItem>
                            <MenuItem value={CareerLevelEnum.entry}>{CareerLevelEnum.entry}</MenuItem>
                            <MenuItem value={CareerLevelEnum.middle}>{CareerLevelEnum.middle}</MenuItem>
                            <MenuItem value={CareerLevelEnum.senior}>{CareerLevelEnum.senior}</MenuItem>
                            <MenuItem value={CareerLevelEnum.executive}>{CareerLevelEnum.executive}</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className={'form-row'}>
                    <TextField
                        id={'numberOfOpenings'}
                        variant={'standard'}
                        type={'number'}
                        fullWidth
                        label={'Poziții disponibile'}
                        inputProps={{min: 1}}
                        error={job.numberOfOpenings < 1}
                        required
                        value={job.numberOfOpenings}
                        onChange={(e) => handleChange(e.target.value, 'numberOfOpenings')}
                        className={'form-text-field left'}
                    />
                    <div className={'form-text-field right'} style={{width: '100%'}}>
                        <InputLabel id={'locationType-label'} style={{fontSize: 12}}>Tipul jobului *</InputLabel>
                        <Select
                            onChange={(e) => handleChange(e.target.value, 'locationType')}
                            labelId={'locationType-label'}
                            required
                            fullWidth
                            value={job.locationType}
                            variant={'standard'}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}
                        >
                            <MenuItem value={LocationTypeEnum.remote}>{LocationTypeEnum.remote}</MenuItem>
                            <MenuItem value={LocationTypeEnum.hibrid}>{LocationTypeEnum.hibrid}</MenuItem>
                            <MenuItem value={LocationTypeEnum.onsite}>{LocationTypeEnum.onsite}</MenuItem>
                        </Select>
                    </div>
                </div>
                {
                    job.locationType !== LocationTypeEnum.remote && job.locationType !== LocationTypeEnum.none &&
                    <Autocomplete
                        value={job.googleAddress ?? null}
                        onChange={(e, v) => {
                            handleChange(v, 'googleAddress');
                            setJobAddress(v ? v.description : '')
                        }}
                        onInputChange={(event, v) => {
                            setJobAddress(v);
                        }}
                        noOptionsText={'Nicio adresa gasita'}
                        getOptionLabel={(option) => option.description}
                        filterOptions={(x) => x}
                        includeInputInList
                        autoComplete
                        filterSelectedOptions
                        fullWidth
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={'Adresa jobului'}
                                    fullWidth
                                    required
                                    error={addressHasError}
                                    helperText={addressHasError ? 'Adresa job-ului nu este in Reșița' : ''}
                                    variant={'standard'}
                                    className={'form-text-field'}
                                />
                            );
                        }}
                        options={options}
                        componentsProps={{
                            paper: {
                                style: {
                                    borderRadius: '0 0 20px 20px'
                                }
                            }
                        }}/>
                }
                <div className={'solid'}/>
                <div className={'form-row'} style={{justifyContent: 'start'}}>
                    <TextField
                        id={'salary'}
                        variant={'standard'}
                        type={'number'}
                        fullWidth
                        label={'Salariu'}
                        placeholder={'Ex: 2400'}
                        required={!job.confidentialSalary}
                        value={job.salary}
                        disabled={job.confidentialSalary}
                        onChange={(e) => handleChange(e.target.value, 'salary')}
                        className={'form-text-field left'}
                    />
                    <div className={'form-text-field'}
                         style={{
                             marginBottom: 0,
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'flex-end',
                             width: '100%'
                         }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={job.confidentialSalary}
                                    onChange={(e) => {
                                        handleChange(e.target.checked, 'confidentialSalary')
                                    }}
                                />}
                            label={'Confidențial'}/>
                    </div>
                </div>
                <div className={'form-text-field width-50-large left'}>
                    <InputLabel id={'industry-label'} style={{fontSize: 12}}>Domeniu *</InputLabel>
                    <Select
                        onChange={(e) => handleChange(e.target.value as IndustriesEnum, 'industry')}
                        labelId={'industry-label'}
                        required
                        fullWidth
                        value={job.industry}
                        disabled={company?.industries!.length === 1}
                        variant={'standard'}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    borderRadius: '0 0 20px 20px'
                                }
                            }
                        }}
                    >
                        {
                            company?.industries!.map(industry => {
                                return <MenuItem key={industry.toString()} value={industry}>{industry}</MenuItem>
                            })
                        }
                    </Select>
                </div>
                <div className={'solid'}/>

                <div className={'form-text-field'}>
                    <TextField
                        id={'languages'}
                        variant={'standard'}
                        fullWidth
                        label={'Limbi vorbite'}
                        placeholder={'Ex: Romana, Engleza'}
                        value={job.languages}
                        onChange={(e) => handleChange(e.target.value, 'languages')}
                        style={{marginBottom: 10}}
                    />
                    <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Noteaza limbile pe care angajatul
                        trebuie sa le cunoasca, separate prin virgula</InputLabel>
                </div>
                <div className={'form-text-field'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={job.applyExternal ?? false}
                                onChange={(e) => {
                                    handleChange(e.target.checked, 'applyExternal')
                                }}
                            />}
                        label={'Aplicare externa'}/>
                    <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Candidatul va fi redirecționat
                        catre un site extern pentru a finaliza aplicarea </InputLabel>
                </div>
                {
                    job.applyExternal &&
                    <TextField
                        id={'externalUrl'}
                        variant={'standard'}
                        fullWidth
                        label={'URL aplicare externa'}
                        placeholder={'Ex: https://example.com/aplicare'}
                        required
                        value={job.externalUrl ?? ''}
                        error={!!job.externalUrl && job.externalUrl.length > 0 && !websiteRegex.test(job.externalUrl)}
                        helperText={!!job.externalUrl && job.externalUrl.length > 0 && !websiteRegex.test(job.externalUrl) ? 'Introduceți un url valid.' : undefined}
                        onChange={(e) => handleChange(e.target.value, 'externalUrl')}
                        className={'form-text-field'}
                    />
                }
                <div className={'form-text-field'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={job.needLetter ?? false}
                                onChange={(e) => {
                                    handleChange(e.target.checked, 'needLetter')
                                }}
                            />}
                        label={'Scrisoare de intenție'}/>
                    <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Candidatul va trebui sa completeze o scrisoare de intenție
                        cand va aplica pentru acest job </InputLabel>
                </div>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em',
                                        color: 'black'
                                    }}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default JobInformationDialog;