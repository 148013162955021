import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Container, Hidden, IconButton, Tooltip, Zoom} from "@mui/material";
import './profile.scss';
import {ApplicationState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import Email from '@mui/icons-material/Email'
import {
    Add,
    Delete,
    Edit,
    LocationOn,
    PersonRounded,
    Phone,
    VisibilityOffRounded,
    VisibilityRounded, Warning, WarningRounded
} from "@mui/icons-material";
import {ReactComponent as CV} from '../../images/svgs/cv.svg';
import FirebaseUtil from "../Firebase/Firebase";
import {UserDetails} from "../../store/Authentication";
import Background from '../../images/employee-background.jpg';
import MotionHoc from "../MotionHoc";
import {EducationInformation, ExperienceInformation, TrainingInformation} from "./Cards/CardItem";
import DateUtil from "../Utils/DateUtil";
import ProfileDialog from "./Dialogs/ProfileDialog";
import {DialogUtil} from "../Utils/DialogUtil";
import {useFilePicker} from "use-file-picker";
import AboutInformationCard from "./Cards/AboutInformationCard";
import SkillsInformationCard from "./Cards/SkillsInformationCard";
import ExperienceInformationCard from "./Cards/ExperienceInformationCard";
import EducationInformationCard from "./Cards/EducationInformationCard";
import TrainingsInformationCard from "./Cards/TrainingsInformationCard";
import './informationCard.scss';
import {useNavigate} from "react-router";
import {snackbarSlice} from "../../store/Snackbar";

export type LabelContentItem = {
    label: string,
    content?: any,
}

export enum InformationCardTypeEnum {
    about,
    experience,
    education,
    skills,
    training,
    // interests
}

const ProfileComponent = () => {
    let {id} = useParams();
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const [userProfile, setUserProfile] = useState<UserDetails | null | undefined>(id === user?.id ? user : undefined);
    const isOwnProfile = !!user && id === user?.id;

    const [informationRows, setInformationRows] = useState<LabelContentItem[]>([]);
    const [skillsRows, setSkillsRows] = useState<LabelContentItem[]>([]);
    const [experienceInformationRows, setExperienceInformationRows] = useState<ExperienceInformation[]>([]);
    const [educationRows, setEducationRows] = useState<EducationInformation[]>([]);
    const [trainingRows, setTrainingRows] = useState<TrainingInformation[]>([]);

    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [showProfileConfirmDialog, setShowProfileConfirmDialog] = useState(false);
    const [showBackgroundConfirmDialog, setShowBackgroundConfirmDialog] = useState(false);
    const [isProfileFile, setIsProfileFile] = useState(true);

    const [openFileSelector, {filesContent, plainFiles, loading, errors, clear}] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
        limitFilesConfig: {max: 1},
        maxFileSize: 3,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (userProfile) {
            setInformationRows([
                {
                    label: 'Biografie',
                    content: userProfile?.biography
                },
                {
                    label: 'Obiectiv profesional',
                    content: userProfile?.objective,
                },
                {
                    label: 'Calificare',
                    content: userProfile?.qualification,
                },
                {
                    label: 'Limbi cunoscute',
                    content: userProfile?.languages,
                },
            ]);
            setSkillsRows(userProfile?.skills ?? []);
            setExperienceInformationRows(userProfile!.experience ?? []);
            setEducationRows(userProfile.education ?? []);
            setTrainingRows(userProfile.trainings ?? []);
        }
    }, [userProfile]);

    useEffect(() => {
        if (isOwnProfile)
            setUserProfile(user);
        else {
            FirebaseUtil.read('Users', id!.toString()).then((data) => {
                if (window.location.pathname.includes('/candidati') && (!data || !data.active)) {
                    navigate('/404', {replace: true});
                    return;
                }
                setUserProfile(data);
            });
        }
    }, [user]);

    useEffect(() => {
        if (plainFiles?.length > 0) {
            if (isProfileFile)
                changeProfilePicture();
            else
                changeBackgroundPicture()
        }
    }, [plainFiles]);

    const changeProfilePicture = async () => {
        const profilePictureDownloadUrl = await FirebaseUtil.uploadFile(user!.id! + '/profilePicture', plainFiles[0]);
        await FirebaseUtil.write('Users', user!.id!,
            {
                photoURL: profilePictureDownloadUrl,
            });
        clear();
    }

    const changeBackgroundPicture = async () => {
        const backgroundDownloadUrl = await FirebaseUtil.uploadFile(user!.id! + '/background', plainFiles[0]);
        await FirebaseUtil.write('Users', user!.id!,
            {
                backgroundURL: backgroundDownloadUrl,
            });
        clear();
    }

    const confirmProfilePictureDelete = async (option: boolean) => {
        if (option) {
            FirebaseUtil.write('Users', user!.id!, {
                photoURL: ''
            })
        }
        setShowProfileConfirmDialog(false);
    }

    const confirmBackgroundPictureDelete = async (option: boolean) => {
        if (option) {
            FirebaseUtil.write('Users', user!.id!, {
                backgroundURL: ''
            })
        }
        setShowBackgroundConfirmDialog(false);
    }

    const saveNewSkills = async (newSkills: LabelContentItem[]) => {
        await FirebaseUtil.write('Users', user!.id!,
            {
                skills: newSkills
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        )
    }

    const changeUserVisibility = async () => {
        if (!isOwnProfile)
            return;

        try {
            await FirebaseUtil.write('Users', user!.id!, {
                appearInSearches: !user!.appearInSearches
            });
            dispatch(snackbarSlice.actions.show({
                message: 'Ai schimbat vizibilitatea in căutări!',
                severity: 'success',
            }))
        } catch (e: any) {
            console.error(e.message);
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        }
    }

    return (
        <>
            <div className={'header mui-fixed'}>
                {
                    userProfile && <img src={userProfile?.backgroundURL ? userProfile?.backgroundURL : Background}
                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                }
                {
                    isOwnProfile &&
                    <div className={'header-edit-buttons-container mui-fixed'}>
                        <div className={'header-edit-buttons-background'}>
                            <IconButton onClick={() => {
                                setIsProfileFile(false);
                                openFileSelector();
                            }}>
                                <Edit className={'header-icon'}/>
                            </IconButton>
                            {
                                user!.backgroundURL &&
                                <IconButton onClick={() => {
                                    setShowBackgroundConfirmDialog(true)
                                }}
                                            className={'header-icon'}>
                                    <Delete/>
                                </IconButton>
                            }
                        </div>
                    </div>
                }
            </div>
            {
                userProfile && <Container maxWidth={'xl'} className={'page-container'}>
                    <div className={'profile-container-holder'}>
                        <div className={'profile-container rounded'}>
                            {
                                isOwnProfile && <IconButton
                                    className={'edit-button'}
                                    onClick={() => setShowProfileDialog(true)}
                                >
                                    <Edit className={'edit-icon'}/>
                                </IconButton>
                            }
                            {
                                isOwnProfile &&
                                (user!.active ? <IconButton
                                        className={'valid-company-button'}
                                        onClick={changeUserVisibility}>
                                        {
                                            user?.appearInSearches ?
                                                <Tooltip
                                                    title={<span style={{fontSize: 12}}>Ești vizibil în căutări. Companiile îți pot găsi profilul fără să aplici la vreun job</span>}
                                                    TransitionComponent={Zoom}
                                                    placement={'right'}
                                                    arrow>
                                                    <VisibilityRounded color={'primary'}/>
                                                </Tooltip>
                                                :
                                                <Tooltip

                                                    title={<span style={{fontSize: 12}}>Nu ești vizibil în căutări. Companiile nu îți vor putea găsi profilul decât dacă aplici la vreun job</span>}
                                                    TransitionComponent={Zoom}
                                                    placement={'right'}
                                                    arrow>
                                                    <VisibilityOffRounded color={'error'}/>
                                                </Tooltip>
                                        }
                                    </IconButton> :
                                    <IconButton
                                        className={'valid-company-button'}>
                                        {
                                            <Tooltip
                                                title={
                                                    <span style={{fontSize: 12}}>
                                                        Contul tău este inactiv, deci doar tu poti vedea aceasta pagina.
                                                    </span>
                                                }
                                                TransitionComponent={Zoom}
                                                arrow>
                                                <WarningRounded color={'error'}/>
                                            </Tooltip>
                                        }
                                    </IconButton>)
                            }
                            <div className={'profile-picture-container'}>
                                {
                                    userProfile?.photoURL ?
                                        <img src={userProfile?.photoURL} className={'profile-picture'}/> :
                                        <PersonRounded style={{padding: '0.5rem'}} className={'profile-icon'}/>
                                }
                                {
                                    isOwnProfile &&
                                    <div className={'profile-picture-edit-container'}>
                                        {
                                            user!.photoURL &&
                                            <IconButton onClick={() => setShowProfileConfirmDialog(true)}
                                                        className={'edit-icon'}>
                                                <Delete/>
                                            </IconButton>
                                        }
                                        <IconButton onClick={() => {
                                            setIsProfileFile(true);
                                            openFileSelector();
                                        }} className={'edit-icon'}>
                                            {
                                                user!.photoURL ? <Edit/> : <Add/>
                                            }
                                        </IconButton>
                                    </div>
                                }
                            </div>
                            <div className={'profile-name'}>
                                <h2>{userProfile?.name}</h2>
                            </div>
                            <div className={'profile-type'}>
                                {/*'Candidat'*/}
                                {
                                    userProfile?.birthDate &&
                                    <p>{(`${DateUtil.getAge(userProfile?.birthDate!)} ani`)}</p>
                                }
                            </div>
                            <div className={'contact-info'}>
                                <div className={'contact-row'}>
                                    <Email className={'contact-icon'}/>
                                    <Tooltip
                                        title={userProfile?.email ?? ''}
                                        TransitionComponent={Zoom}
                                        arrow>
                                        <div className={'contact-content'}>
                                            <a href={`mailto:${userProfile?.email}`}>
                                                {userProfile?.email}
                                            </a>
                                        </div>
                                    </Tooltip>
                                </div>
                                {
                                    userProfile?.phoneNumber && <div className={'contact-row'}>
                                        <Phone className={'contact-icon'}/>
                                        <Tooltip
                                            title={userProfile?.phoneNumber}
                                            TransitionComponent={Zoom}
                                            arrow>
                                            <div className={'contact-content'}>
                                                <a href={`tel:${userProfile?.phoneNumber}`}>
                                                    {userProfile?.phoneNumber}
                                                </a>
                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                                {
                                    userProfile?.city && <div className={'contact-row'}>
                                        <LocationOn className={'contact-icon'}/>
                                        <div className={'contact-content'} style={{whiteSpace: 'break-spaces'}}>
                                            {userProfile?.city}
                                        </div>
                                    </div>
                                }
                                {
                                    (!!company || isOwnProfile) && userProfile?.cvDownloadUrl &&
                                    <div
                                        className={'contact-row cv-row'}
                                        onClick={() => {
                                            FirebaseUtil.download(userProfile?.cvDownloadUrl!, `CV_${userProfile?.name!.replace(' ', '_')}`)
                                        }}
                                    >
                                        <CV className={'cv-contact-icon'}/>
                                        <div className={'contact-content'}>
                                            {'Descarca CV'}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'information-container rounded'}>
                        <AboutInformationCard
                            rows={informationRows}
                            isOwnProfile={isOwnProfile}
                        />
                        <SkillsInformationCard
                            rows={skillsRows}
                            isOwnProfile={isOwnProfile}
                            saveCallback={saveNewSkills}
                        />
                        <ExperienceInformationCard
                            rows={experienceInformationRows}
                            isOwnProfile={isOwnProfile}
                        />
                        <EducationInformationCard
                            rows={educationRows}
                            isOwnProfile={isOwnProfile}
                        />
                        <TrainingsInformationCard
                            rows={trainingRows}
                            isOwnProfile={isOwnProfile}
                        />
                    </div>
                    {
                        showProfileDialog && <ProfileDialog
                            closeCallback={() => setShowProfileDialog(false)}
                            open={showProfileDialog}
                        />
                    }
                    {
                        DialogUtil.confirm(
                            {
                                title: 'Ești sigur că dorești sa iti stergi fotografia de profil?',
                            },
                            showProfileConfirmDialog,
                            confirmProfilePictureDelete,
                            true
                        )
                    }
                    {
                        DialogUtil.confirm(
                            {
                                title: 'Ești sigur că dorești sa iti stergi fotografia de coperta?',
                            },
                            showBackgroundConfirmDialog,
                            confirmBackgroundPictureDelete,
                            true
                        )
                    }
                </Container>
            }
        </>
    );
}

const Profile = MotionHoc(ProfileComponent);

export default Profile;