import React, {useState} from 'react';
import CardHeader from "./CardHeader";
import CardItem, {CardTypeEnum, TrainingInformation} from "./CardItem";
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {DialogUtil} from "../../Utils/DialogUtil";
import TrainingDialog from "../Dialogs/TrainingDialog";

interface Props{
    rows: TrainingInformation[];
    isOwnProfile: boolean;
}

const TrainingsInformationCard = (props: Props) => {
    const user = useSelector((s : ApplicationState) => s.auth.user);
    const [showDialog, setShowDialog] = useState(false);
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const [isNewJob, setIsNewTraining] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState<TrainingInformation>({
        startDate: '',
        trainingName: '',
        endDate: '',
        description: '',
        industry: null
    });
    const [selectedTrainingIndex, setSelectedTrainingIndex] = useState(0);

    const dispatch = useDispatch();

    const confirmDeleteTraining = (result: boolean) => {
        if(result)
        {
            FirebaseUtil.write('Users', user!.id!,
                {
                    experience: user!.trainings!.filter((training, i) => selectedTrainingIndex !== i)
                }).catch(e => {
                    dispatch(snackbarSlice.actions.show({
                        message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                        severity: 'error',
                    }))
                }
            );
        }
        setShowWarningDialog(false);
    }

    return (
        props.isOwnProfile || props.rows?.length > 0 ? <div className={'information-card rounded'}>
        <CardHeader
            isOwnProfile={props.isOwnProfile}
            editCallback={() => {}}
            title={'Traininguri / Cursuri'}
            hideEditButton={true}
        />
        <div className={'solid'}/>
            {
                props.rows?.map((training, index) => {
                    return (
                        <div key={`experience-card-item-${index}`}>
                            <CardItem
                                training={training}
                                cardType={CardTypeEnum.training}
                                editCallback={() => {
                                    setSelectedTraining(training);
                                    setSelectedTrainingIndex(index);
                                    setIsNewTraining(false);

                                    setShowDialog(true);
                                }}
                                deleteCallback={() => {
                                    setSelectedTrainingIndex(index)
                                    setShowWarningDialog(true);
                                }}
                                isOwnProfile={props.isOwnProfile}
                            />
                            <div className={'solid-thin'}/>
                        </div>
                    );
                })
            }
            <Button onClick={() => {
                setSelectedTraining({
                    startDate: '',
                    trainingName: '',
                    endDate: '',
                    description: '',
                    industry: null
                });
                setIsNewTraining(true);
                setShowDialog(true);
            }}
                    style={{textTransform: 'uppercase', marginTop: 10}}>
                {
                    props.isOwnProfile &&
                    <>
                        <Add color={'primary'} fontSize={'small'}/>
                        <span>Adaugă un training</span>
                    </>
                }
            </Button>
            {
                showDialog &&
                <TrainingDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    isNewTraining={isNewJob}
                    training={selectedTraining}
                    trainingIndex={selectedTrainingIndex}
                />
            }
            {
                DialogUtil.confirm({
                        title: 'Ești sigur că dorești sa stergi acest training?',
                    },
                    showWarningDialog,
                    confirmDeleteTraining,
                    true
                )
            }
    </div> : null
    );
}

export default TrainingsInformationCard;