import React, {useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormHelperText,
    IconButton,
    TextField
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../profile.scss';
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {useFilePicker} from "use-file-picker";
import {Close} from "@mui/icons-material";
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
}

const ProfileDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [birthDate, setBirthDate] = useState<Date | null>(user?.birthDate ? new Date((user?.birthDate as any).seconds * 1000) : null);
    // const [email, setEmail] = useState<string>(user?.email!);
    const [name, setName] = useState<string>(user?.name!);
    const [phone, setPhone] = useState<string>(user?.phoneNumber ?? '');
    const [location, setLocation] = useState<string>(user?.city ?? '');
    const [cvDownloadUrl, setCvDownloadUrl] = useState<string | undefined>(user?.cvDownloadUrl);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [openFileSelector, {filesContent, plainFiles, loading, errors, clear}] = useFilePicker({
        readAs: 'DataURL',
        accept: ['.pdf', '.docx'],
        multiple: false,
        limitFilesConfig: {max: 1},
        maxFileSize: 5,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const isNameValid = name.trim().length > 0;
        const isBirthDateValid = birthDate == null || birthDate < new Date();
        const isPhoneValid = !phone || phone.trim().length <= 10;
        setIsFormValid(isNameValid && isBirthDateValid && isPhoneValid);
    }, [name, birthDate, phone]);

    const saveCallback = async () => {
        setIsLoading(true);
        let cvUrl = cvDownloadUrl ?? '';
        if (!!plainFiles.length) {
            cvUrl = await FirebaseUtil.uploadFile(user!.id! + '/cv', plainFiles[0], false)
        }
        await FirebaseUtil.write('Users', user!.id!,
            {
                name: name.trim(),
                city: location.trim(),
                phoneNumber: phone.trim(),
                birthDate: birthDate ? birthDate! : '',
                cvDownloadUrl: cvUrl.trim()
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        );
        props.closeCallback();
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>Editează datele personale</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <div className={'form-row phone-container'}>
                    <TextField
                        required
                        onChange={(e) => setName(e.target.value)}
                        label={'Nume complet'}
                        type={'text'}
                        fullWidth
                        value={name}
                        variant={'standard'}
                        className={'form-text-field left'}
                        error={!name.length}
                    />
                    <TextField
                        onChange={(e) => setPhone(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 38 || e.keyCode === 40) {
                                e.preventDefault();
                            }
                        }}
                        label={'Număr de telefon'}
                        type={'number'}
                        fullWidth
                        value={phone}
                        variant={'standard'}
                        id={'profile-dialog-phone'}
                        className={'form-text-field right'}
                    />
                </div>
                <div className={'form-row'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            maxDate={new Date()}
                            label={'Data nașterii'}
                            inputFormat={'dd/MM/yyyy'}
                            value={birthDate}
                            onChange={(value) => setBirthDate(value)}
                            renderInput={(params) => <TextField
                                variant={'standard'}
                                type={'date'}
                                className={'form-text-field left'}
                                fullWidth
                                error={params.error}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'ZZ/LL/AAAA'
                                }}
                            />}
                        />
                    </LocalizationProvider>
                    <TextField
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder={'Ex: Resita'}
                        label={'Locație'}
                        type={'text'}
                        fullWidth
                        value={location}
                        variant={'standard'}
                        className={'form-text-field right'}
                    />
                </div>
                <div className={'form-row space-between'}>
                    <TextField
                        value={!!filesContent.length ? filesContent[0].name : cvDownloadUrl ? 'CV incarcat' : ''}
                        aria-readonly={true}
                        focused={false}
                        label={'CV'}
                        variant={'standard'}
                        fullWidth
                        InputProps={{
                            endAdornment: !!filesContent.length &&
                                <IconButton onClick={clear} className={'cv-icon'}><Close/></IconButton>,
                        }}
                        error={!!errors.length}
                    />
                    <Button onClick={() => openFileSelector()}
                            style={{whiteSpace: 'nowrap', width: 160, textTransform: 'none', marginLeft: 10}}>
                        Ataseaza CV
                    </Button>
                    {
                        cvDownloadUrl &&
                        <Button onClick={() => {
                            if (!!plainFiles.length) {
                                clear();
                            } else {
                                setCvDownloadUrl('')
                            }
                        }}
                                style={{
                                    whiteSpace: 'nowrap',
                                    width: 160,
                                    textTransform: 'none',
                                    marginLeft: 10,
                                    color: 'red'
                                }}>
                            Sterge CV
                        </Button>
                    }
                </div>
                <FormHelperText
                    style={{color: 'white'}}>{!!errors.length ? errors[0].fileSizeToolarge ? 'Dimensiunea maxima a fisierului este de 5MB' : 'Eroare, incearca din nou' : ''}</FormHelperText>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em',
                                        color: 'black'
                                    }}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default ProfileDialog;