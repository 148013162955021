import React from 'react';
import {HelpPageProps} from "./types";
import {Box} from "@mui/material";

const ProfileHelp = ({isCandidate}: HelpPageProps) => {
    return (
        <Box paddingTop={'10px'}>
            <h1 style={{marginBottom: 20}}>PROFIL</h1>
            <p>Această pagină de ajutor acoperă diferite părți ale modului în care funcționează PROFILUL.</p>
            <p>Pagina de profil poate fi accesata din bara de navigatie, apasand pe <b>poza de profil</b> sau pe
                butonul <b>Vezi profilul</b>.
            </p>
            <img src={'images/profil-1.png'} className={'help-page-vertical-image'}/>
            {
                isCandidate && <div>
                    <h2>VIZIBILITATE</h2>
                    <p>Daca <b>activezi</b> vizibilitatea, o companie iti poate gasi profilul fara sa aplici la vreun job.</p>
                    <p>Daca <b>nu activezi</b> vizibilitatea, o companie iti poate gasi profilul doar daca aplici la un job postat de aceasta.</p>
                    <img src={'images/profil-2.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE DATE PERSONALE</h2>
                    <p>Iti poti edita datele personale folosind butonul dedicat din coltul dreapta-sus.</p>
                    <img src={'images/profil-3.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE POZA DE PROFIL</h2>
                    <p>Iti poti edita poza de profil apasand pe butonul de editare, sau o poti sterge apasand pe butonul de stergere.</p>
                    <img src={'images/profil-4.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE POZA DE COPERTA</h2>
                    <p>Iti poti edita poza de coperta apasand pe butonul de editare, sau o poti sterge apasand pe butonul de stergere, plasate in coltul din dreapta-sus al paginii.</p>
                    <img src={'images/profil-5.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE INFORMATII GENERALE</h2>
                    <p>Iti poti edita informatiile generale apasand pe butonul de editare, situat in coltul din dreapta-sus al widget-ului.</p>
                    <img src={'images/profil-6.png'} className={'help-page-image'}/>

                    <h2>EDITARE APTITUDINI</h2>
                    <p>Iti poti edita aptitudinile apasand pe butonul de editare, situat in coltul din dreapta-sus al widget-ului.</p>
                    <img src={'images/profil-7.png'} className={'help-page-image'}/>

                    <h2>EDITARE EXPERIENTA</h2>
                    <p>Iti poti adauga experienta profesionala folosind buton de 'ADAUGA EXPERIENTA' si le poti edita pe cele existente folosind
                    butoanele de editare, respectiv de stergere.</p>
                    <img src={'images/profil-8.png'} className={'help-page-image'}/>

                    <h2>EDITARE STUDII</h2>
                    <p>Iti poti adauga studii folosind buton de 'ADAUGA STUDII' si le poti edita pe cele existente folosind
                        butoanele de editare, respectiv de stergere.</p>
                    <img src={'images/profil-9.png'} className={'help-page-image'}/>

                    <h2>EDITARE TRAININGURI / CURSURI</h2>
                    <p>Iti poti adauga TRAININGURI sau CURSURI folosind buton de 'ADAUGA UN TRAINING' si le poti edita pe cele existente folosind
                        butoanele de editare, respectiv de stergere.</p>
                    <img src={'images/profil-10.png'} className={'help-page-image'}/>
                </div>
            }
            {
                !isCandidate && <div>
                    <h2>EDITARE DATE COMPANIE</h2>
                    <p>Poti edita datele companiei folosind butonul dedicat din coltul dreapta-sus.</p>
                    <img src={'images/profil-11.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE POZA DE PROFIL</h2>
                    <p>Poti edita poza de profil apasand pe butonul de editare, sau o poti sterge apasand pe butonul de stergere.</p>
                    <img src={'images/profil-4.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE POZA DE COPERTA</h2>
                    <p>Poti edita poza de coperta apasand pe butonul de editare, sau o poti sterge apasand pe butonul de stergere, plasate in coltul din dreapta-sus al paginii.</p>
                    <img src={'images/profil-5.png'} className={'help-page-square-image'}/>

                    <h2>EDITARE DESCRIERE</h2>
                    <p>Poti edita descrierea companiei apasand pe butonul de editare, situat in coltul din dreapta-sus al widget-ului.</p>
                    <img src={'images/profil-12.png'} className={'help-page-image'}/>

                    <h2>EDITARE GALERIE</h2>
                    <p>Poti adauga sau edita pozele din galerie apasand pe butonul de editare, situat in coltul din dreapta-sus al widget-ului.</p>
                    <img src={'images/profil-13.png'} className={'help-page-image'}/>

                    <h2>EDITARE JOBURI</h2>
                    <p>Poti adauga un job folosind buton 'POSTEAZA UN JOB NOU'.</p>
                    <p>Pentru a vizualiza si edita un job adaugat, apasa pe denumirea lui.</p>
                    <p>Pentru a activa/dezactiva un job, apasa pe butonul 'INACTIV', respectiv 'ACTIV'.</p>
                    <p>Pentru a sterge definitiv un job, apasa pe butonul de stergere.</p>
                    <img src={'images/profil-14.png'} className={'help-page-image'}/>
                </div>
            }
        </Box>
    );
}

export default ProfileHelp;