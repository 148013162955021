import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel} from "@mui/material";
import {CareerLevelEnum, Job, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {Assignment} from "@mui/icons-material";
import '../Profile/profile.scss';
import './jobApplyDialog.scss'
import MUIRichTextEditor from "mui-rte";
import {EditorState} from "draft-js";
import {stateToHtml} from "../CompanyProfile/Dialogs/DescriptionDialog";

interface Props {
    closeCallback: () => void;
    applyCallback: (coverLetter: string) => void;
    job: Job;
}

const controls: string[] = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'undo',
    'redo',
    'link',
    'media',
    'numberList',
    'bulletList',
];
const JobApplyDialog = (props: Props) => {
    const [editorState, setEditorState] = useState<EditorState>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const saveCallback = async () => {
        setIsLoading(true);
        const coverLetter = editorState ? stateToHtml(editorState).replace('<a', '<a target="__blank"') : '';
        await props.applyCallback(coverLetter);
    }

    useEffect(() => {
        if (editorState) {
            setIsFormValid(stateToHtml(editorState).trim().length > 20);
        }
    }, [editorState]);

    return (
        <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle style={{display: 'flex', alignItems: 'center'}}>
                <Assignment color={'primary'} style={{width: 36, height: 36}}/>
                <span style={{fontSize: 24}}>{props.job.title}</span>
            </DialogTitle>
            <DialogContent className={'apply-dialog-container'} style={{paddingTop: 5}}>
                <InputLabel id={'locationType-label'} style={{fontSize: 14, paddingBottom: 6}}>Scrisoare de intenție
                    *</InputLabel>
                <div className={'rich-text-editor-container'}>
                    <div className={'rich-text-editor-container'}>
                        <MUIRichTextEditor
                            label={'Scrisoarea de intenţie trebuie să reflecte stilul personal,' +
                            ' aspectele care te diferenţiază de ceilalţi candidaţi să-ţi contureze unicitatea,' +
                            ' capacitatea de argumentare, profesionalismul şi abilitatea de persuasiune.'}
                            defaultValue={''}
                            onChange={(newState) => setEditorState(newState)}
                            controls={controls}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em',
                                        color: 'black'
                                    }}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Aplică
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    )
}

export default JobApplyDialog;