import React, {useEffect, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
}

const AboutDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [biography, setBiography] = useState<string>(user?.biography ?? '');
    const [objective, setObjective] = useState<string>(user?.objective ?? '');
    const [qualification, setQualification] = useState<string>(user?.qualification ?? '');
    const [languages, setLanguages] = useState<string>(user?.languages ?? '');

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const dispatch = useDispatch();

    useEffect(() => {
        const isQualificationValid = qualification.trim().length < 30;
        setIsFormValid(isQualificationValid);
    }, [qualification, biography, objective, languages]);

    const saveCallback = async () => {
        setIsLoading(true);
        await FirebaseUtil.write('Users', user!.id!,
            {
                biography: biography.trim(),
                qualification: qualification.trim(),
                languages: languages.trim(),
                objective: objective.trim(),
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        ).finally(() => {
            props.closeCallback();
        });
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if(reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>Editează informațiile generale</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <TextField
                    label={'Biografie'}
                    type={'text'}
                    fullWidth
                    value={biography}
                    onChange={(e) => setBiography(e.target.value)}
                    variant={'standard'}
                    multiline={true}
                    minRows={3}
                    className={'form-text-field'}
                    error={false}
                />
                <TextField
                    onChange={(e) => setObjective(e.target.value)}
                    label={'Obiectiv profesional'}
                    type={'text'}
                    fullWidth
                    multiline
                    minRows={2}
                    value={objective}
                    variant={'standard'}
                    className={'form-text-field'}
                />
                <TextField
                    onChange={(e) => setQualification(e.target.value)}
                    label={'Calificare'}
                    type={'text'}
                    fullWidth
                    value={qualification}
                    variant={'standard'}
                    className={'form-text-field'}
                />
                <TextField
                    onChange={(e) => setLanguages(e.target.value)}
                    label={'Limbi cunoscute'}
                    placeholder={'Ex: Romana, Engleza'}
                    type={'text'}
                    fullWidth
                    value={languages}
                    variant={'standard'}
                    className={'form-text-field'}
                />
                <p>* Noteaza limbile vorbite, separate prin virgula</p>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default AboutDialog;