import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {Chip, Container, IconButton, Tooltip, Zoom} from "@mui/material";
import '../Profile/profile.scss';
import './companyProfile.scss';
import {ApplicationState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import Email from '@mui/icons-material/Email'
import {
    Add,
    ApartmentRounded,
    Delete,
    Edit,
    Language,
    LocationOn,
    Phone,
} from "@mui/icons-material";
import FirebaseUtil from "../Firebase/Firebase";
import {CompanyDetails} from "../../store/Authentication";
import Background from '../../images/company-background.jpg';
import MotionHoc from "../MotionHoc";
import {DialogUtil} from "../Utils/DialogUtil";
import {useFilePicker} from "use-file-picker";
import '../Profile/informationCard.scss';
import CompanyProfileDialog, {IndustriesEnum} from "./Dialogs/CompanyProfileDialog";
import CompanyMediaInformationCard from "./Cards/CompanyMediaInformationCard";
import CompanyJobsInformationCard from "./Cards/CompanyJobInformationCard";
import {Job} from "../../models/Job";
import {snackbarSlice} from "../../store/Snackbar";
import DescriptionInformationCard from "./Cards/DescriptionInformationCard";
import {useNavigate} from "react-router";

const CompanyProfileComponent = () => {
    let {id} = useParams();
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const isOwnProfile = id === company?.id;

    const [companyProfile, setCompanyProfile] = useState<CompanyDetails | null | undefined>(id === company?.id ? company : undefined);

    const [showCompanyNotValidWarning, setShowCompanyNotValidWarning] = useState(false);
    const [showCompanyProfileDialog, setShowCompanyProfileDialog] = useState(false);
    const [showProfileConfirmDialog, setShowProfileConfirmDialog] = useState(false);
    const [showBackgroundConfirmDialog, setShowBackgroundConfirmDialog] = useState(false);
    const [isProfileFile, setIsProfileFile] = useState(true);

    const [openFileSelector, {filesContent, plainFiles, loading, errors, clear}] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
        limitFilesConfig: {max: 1},
        maxFileSize: 3,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isOwnProfile)
            setCompanyProfile(company);
        else {
            FirebaseUtil.read('Companies', id!.toString()).then(async (data) => {
                if (!data || !data.active) {
                    navigate('/404', {replace: true});
                    return;
                }
                let docs = await FirebaseUtil.getDocsInOrderWithCondition('Jobs',
                    'createdAt', false, undefined, undefined, undefined,
                    {fieldPath: 'companyId', opStr: '==', value: data?.id},
                    {fieldPath: 'active', opStr: '==', value: true});
                let jobs: Job[] = [];
                docs.forEach(doc => {
                    jobs = [...jobs, {
                        id: doc.data().id,
                        skills: doc.data().skills,
                        confidentialSalary: doc.data().confidentialSalary,
                        salary: doc.data().salary,
                        locationType: doc.data().locationType,
                        numberOfOpenings: doc.data().numberOfOpenings,
                        careerLevel: doc.data().careerLevel,
                        jobType: doc.data().jobType,
                        industry: doc.data().industry,
                        languages: doc.data().languages,
                        description: doc.data().description,
                        title: doc.data().title,
                        address: doc.data().address,
                        active: doc.data().active,
                        createdAt: doc.data().createdAt,
                        companyId: doc.data().companyId,
                        latitude: doc.data().latitude,
                        longitude: doc.data().longitude,
                        externalUrl: doc.data().externalUrl,
                        applyExternal: doc.data().applyExternal,
                        needLetter: doc.data().needLetter
                    }];
                })
                setCompanyProfile({
                    ...data, jobs: jobs
                });
            })
        }
    }, [company]);

    useEffect(() => {
        if (plainFiles?.length > 0) {
            if (isProfileFile)
                changeProfilePicture();
            else
                changeBackgroundPicture()
        }
    }, [plainFiles]);

    const changeProfilePicture = async () => {
        const profilePictureDownloadUrl = await FirebaseUtil.uploadFile(company!.id! + '/profilePicture', plainFiles[0]);
        await FirebaseUtil.write('Companies', company!.id!,
            {
                photoURL: profilePictureDownloadUrl,
            });
        clear();
    }

    const changeBackgroundPicture = async () => {
        const backgroundDownloadUrl = await FirebaseUtil.uploadFile(company!.id! + '/background', plainFiles[0]);
        await FirebaseUtil.write('Companies', company!.id!,
            {
                backgroundURL: backgroundDownloadUrl,
            });
        clear();
    }

    const confirmProfilePictureDelete = async (option: boolean) => {
        if (option) {
            FirebaseUtil.write('Companies', company!.id!, {
                photoURL: ''
            })
        }
        setShowProfileConfirmDialog(false);
    }

    const confirmBackgroundPictureDelete = async (option: boolean) => {
        if (option) {
            FirebaseUtil.write('Companies', company!.id!, {
                backgroundURL: ''
            })
        }
        setShowBackgroundConfirmDialog(false);
    }

    const saveNewDescription = async (newDescription: string) => {
        await FirebaseUtil.write('Companies', company!.id!,
            {
                description: newDescription
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        )
    }

    return (
        <>
            <div className={'header mui-fixed'}>
                {
                    companyProfile &&
                    <img src={companyProfile?.backgroundURL ? companyProfile?.backgroundURL : Background}
                         style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                }
                {
                    isOwnProfile &&
                    <div className={'header-edit-buttons-container mui-fixed'}>
                        <div className={'header-edit-buttons-background'}>
                            <IconButton onClick={() => {
                                setIsProfileFile(false);
                                openFileSelector();
                            }}>
                                <Edit className={'header-icon'}/>
                            </IconButton>
                            {
                                company!.backgroundURL &&
                                <IconButton onClick={() => {
                                    setShowBackgroundConfirmDialog(true)
                                }}
                                            className={'header-icon'}>
                                    <Delete/>
                                </IconButton>
                            }
                        </div>
                    </div>
                }
            </div>
            {
                companyProfile && <Container maxWidth={'xl'} className={'page-container'}>
                    <div className={'profile-container-holder'}>
                        <div className={'profile-container rounded'}>
                            {
                                isOwnProfile && <IconButton
                                    className={'edit-button'}
                                    onClick={() => setShowCompanyProfileDialog(true)}
                                >
                                    <Edit className={'edit-icon'}/>
                                </IconButton>
                            }
                            {/*{*/}
                            {/*    isOwnProfile && !company?.isValidCompany &&*/}
                            {/*    <IconButton*/}
                            {/*        className={'valid-company-button'}*/}
                            {/*        onClick={() => setShowCompanyNotValidWarning(true)}>*/}
                            {/*        <Warning color={'error'}/>*/}
                            {/*    </IconButton>*/}

                            {/*}*/}
                            <div className={'profile-picture-container'}>
                                {
                                    companyProfile?.photoURL ?
                                        <img src={companyProfile?.photoURL} className={'profile-picture'}/> :
                                        <ApartmentRounded style={{padding: '0.5rem'}} className={'profile-icon'}/>
                                }
                                {
                                    isOwnProfile &&
                                    <div className={'profile-picture-edit-container'}>
                                        {
                                            company!.photoURL &&
                                            <IconButton onClick={() => setShowProfileConfirmDialog(true)}
                                                        className={'edit-icon'}>
                                                <Delete/>
                                            </IconButton>
                                        }
                                        <IconButton onClick={() => {
                                            setIsProfileFile(true);
                                            openFileSelector();
                                        }} className={'edit-icon'}>
                                            {
                                                company!.photoURL ? <Edit/> : <Add/>
                                            }
                                        </IconButton>
                                    </div>
                                }
                            </div>
                            <div className={'profile-name'}>
                                <h2>{companyProfile?.companyName}</h2>
                            </div>
                            <div className={'profile-type'}>
                                {companyProfile?.industries?.map((industry, index) => {
                                    return (
                                        <NavLink
                                            to={`/joburi?industry=${IndustriesEnum[industry as unknown as keyof typeof IndustriesEnum]}`}
                                            key={index}
                                        >
                                            <Chip
                                                style={{margin: 4, borderRadius: 6}}
                                                label={industry}
                                                className={'job-badge chip-job-badge'}
                                                key={index}/>
                                        </NavLink>
                                    );
                                })
                                }
                            </div>
                            <div className={'social-icons-row'}>
                                <div className={'contact-info'}>
                                    <div className={'contact-row'}>
                                        <Email className={'contact-icon'}/>
                                        <Tooltip
                                            title={companyProfile?.contactEmail ?? companyProfile?.email ?? ''}
                                            TransitionComponent={Zoom}
                                            arrow>
                                            <div className={'contact-content'}>
                                                <a href={`mailto:${companyProfile?.contactEmail ?? companyProfile?.email}`}>
                                                    {companyProfile?.contactEmail ?? companyProfile?.email ?? ''}
                                                </a>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    {
                                        companyProfile?.phoneNumber && <div className={'contact-row'}>
                                            <Phone className={'contact-icon'}/>
                                            <Tooltip
                                                title={companyProfile?.phoneNumber}
                                                TransitionComponent={Zoom}
                                                arrow>
                                                <div className={'contact-content'}>
                                                    <a href={`tel:${companyProfile?.phoneNumber}`}>
                                                        {companyProfile?.phoneNumber}
                                                    </a>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                    {
                                        companyProfile?.website && <div className={'contact-row'}>
                                            <Language className={'contact-icon'}/>
                                            <Tooltip
                                                title={companyProfile?.website}
                                                TransitionComponent={Zoom}
                                                arrow>
                                                <div className={'contact-content'}>
                                                    <a href={companyProfile?.website} target={'_blank'}>
                                                        {companyProfile?.website.replace('https://', '').replace('http://', '')}
                                                    </a>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    }
                                    {
                                        companyProfile?.isValidCompany && <div className={'contact-row'}>
                                            <LocationOn className={'contact-icon'}/>
                                            <div className={'contact-content'} style={{whiteSpace: 'break-spaces'}}>
                                                {companyProfile?.cityAddress}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'information-container rounded'}>
                        <DescriptionInformationCard
                            description={companyProfile?.description}
                            isOwnProfile={isOwnProfile}
                            saveCallback={saveNewDescription}
                        />
                        {
                            (isOwnProfile || (companyProfile.mediaUrls && companyProfile.mediaUrls.length > 0)) &&
                            <CompanyMediaInformationCard
                                mediaUrls={companyProfile.mediaUrls ?? []}
                                isOwnProfile={isOwnProfile}
                            />
                        }
                        <CompanyJobsInformationCard
                            jobs={companyProfile?.jobs ? companyProfile.jobs : []}
                            isOwnProfile={isOwnProfile}
                            companyProfile={companyProfile}
                        />
                    </div>


                    {/* Dialogs */}
                    {
                        showCompanyProfileDialog && <CompanyProfileDialog
                            closeCallback={() => setShowCompanyProfileDialog(false)}
                            open={showCompanyProfileDialog}
                        />
                    }
                    {
                        DialogUtil.confirm(
                            {
                                title: 'Ești sigur că dorești sa stergi fotografia de profil?',
                            },
                            showProfileConfirmDialog,
                            confirmProfilePictureDelete,
                            true
                        )
                    }
                    {
                        DialogUtil.confirm(
                            {
                                title: 'Ești sigur că dorești sa stergi fotografia de coperta?',
                            },
                            showBackgroundConfirmDialog,
                            confirmBackgroundPictureDelete,
                            true
                        )
                    }
                    {
                        DialogUtil.inform(
                            {
                                title: 'Cont restrictionat',
                                content: 'Pentru a activa acest cont si a putea benefecia de toate ' +
                                    'facilitatile acestei platforme, compania trebuie sa aiba un punct de lucru ' +
                                    'valid in Resita.'
                            },
                            showCompanyNotValidWarning,
                            () => setShowCompanyNotValidWarning(false)
                        )
                    }
                </Container>
            }
        </>
    );
}

const CompanyProfile = MotionHoc(CompanyProfileComponent);

export default CompanyProfile;