import React, {useCallback, useEffect, useRef, useState} from "react";

//All the svg files
import logo from "../../images/logo-resita.png";
import PowerOff from "../../assets/power-off-solid.svg";
import styled from "styled-components";
import {Link, NavLink} from "react-router-dom";
import './Navbar.css'
import {signOut, updateProfile} from "firebase/auth";
import {auth, db} from "../../App";
import {ApplicationState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {
    Add,
    ApartmentRounded,
    Assignment, HelpRounded,
    Home,
    Notifications, PeopleRounded,
    Person, PersonRounded, PrivacyTipRounded,
    Search, Settings, SettingsRounded,
    Star,
    Work
} from "@mui/icons-material";
import {doc, onSnapshot} from "firebase/firestore";
import FirebaseUtil from "../Firebase/Firebase";
import {authenticationSlice} from "../../store/Authentication";
import {Job} from "../../models/Job";
import {useNavigate} from "react-router";
import {Tooltip, Zoom} from "@mui/material";

const Container = styled.div`
  position: fixed;
  top: 10px;
  width: 0;

  .active {
    border-right: 4px solid var(--white);
  }
`;

const Button = styled.button<{ clicked: boolean }>`
  background-color: var(--dark-primary);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);

  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div<{ clicked: boolean }>`
  box-shadow: 0 0 10px 2.5px rgba(0, 0, 0, 0.2);
  background-color: var(--dark-primary);
  width: 3.5rem;
  height: 80vh;
  min-height: 592px;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: space-between;

  position: relative;

  @media (max-width: 768px) {
    transition: width 0.25s ease;
    height: 80vh;
    width: ${(props) => (props.clicked ? "3.5rem" : "0")};
  }
`;

const Logo = styled.div`
  width: 4rem;
  top: 10px;
  position: relative;
  left: -0.25rem;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {

  }
`;

const SlickBar = styled.ul<{ clicked: boolean }>`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-primary);

  padding: 2rem 0;

  position: relative;
  top: -0.5rem;
  left: 0;

  width: ${(props) => (props.clicked ? "14.5rem" : "3.5rem")};
  box-shadow: ${(props) => (props.clicked ? '0 0 15px 2.5px rgba(0, 0, 0, 0.1)' : '')};

  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;

  @media (max-width: 768px) {
    width: ${(props) => (props.clicked ? "15rem" : "0")};
  }
`;

const ProfileSlickBar = styled.ul<{ clicked: boolean }>`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--dark-primary);
  overflow: hidden;

  padding: .5rem 0;

  position: relative;
  bottom: 10px;
  left: 0;

  width: ${(props) => (props.clicked ? "15rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;

  img, svg {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
  }

  svg {
    background-color: var(--darker-primary);
    padding: 0.25rem;
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.clicked ? "15rem" : "0")};
  }
`;

const Item = styled(NavLink)<{ clicked: boolean }>`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  cursor: pointer;
  overflow: hidden;

  display: flex;

  transition: .2s ease-in-out;

  &:hover:not(.active) {
    border-right: 4px solid rgba(256, 256, 256, 0.75);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }

    .MuiSvgIcon-root {
      color: white;
    }
  }

  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg) brightness(78%) contrast(85%);
  }

  .MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;
    color: lightgrey;
  }

  @media (max-width: 768px) {
    transition: 0.4s ease-in-out;
    &.active {
      border-right: ${(props) => (props.clicked ? "4px solid white" : "0px solid white")};
    }
  }
`;

const Text = styled.span<{ clicked: boolean }>`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  white-space: nowrap;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
`;

const ProfileDetails = styled.div<{ clicked: boolean }>`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Profile = styled.div<{ clicked: boolean }>`
  width: ${(props) => (props.clicked ? "auto" : "3rem")};
  height: 3.5rem;

  padding: 0.5rem 1rem;
  //border: 2px solid var(--white); 
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "0" : "0")};

  background-color: var(--dark-primary);
  color: var(--white);

  transition: all 0.3s ease, border-width 0.1s;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border: 2px solid var(--grey);
      padding: 2px;
    }
  }
`;

const Details = styled.div<{ clicked: boolean }>`
  display: ${(props) => (props.clicked ? "flex" : "flex")};
  width: ${(props) => (props.clicked ? "auto" : "0")};

  transition: all 0.3s ease-in-out;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div<{ visible: boolean }>`
  padding: 0 1.5rem;
  width: ${(props) => (props.visible ? "100%" : "0")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    display: inline-block;
    white-space: nowrap;
  }

  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Logout = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  flex: 1;
  padding-right: .75rem;

  img {
    width: 2rem;
    height: 2rem;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg) brightness(100%) contrast(126%);
    transition: all 0.3s ease;
  }

  &:hover {
    img {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const Login = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  flex: 1;
  padding-right: .75rem;

  img {
    width: 2rem;
    height: 2rem;
    filter: invert(59%) sepia(74%) saturate(5966%) hue-rotate(96deg) brightness(114%) contrast(110%);
    transition: all 0.3s ease;
  }

  &:hover {
    img {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
  
  @media (max-width: 768px){
    width: 3.5rem;
    
    img{
      width: 3.5rem;
    }
  }
`;


const Sidebar = () => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const authStateChanged = useSelector((s: ApplicationState) => s.auth.authStateChanged)
    const isCompany = !!company;
    const isAuthenticated = !!user || !!company;
    const first = useRef(true);

    const [click, setClick] = useState<boolean>(false);
    const handleClick = () => setClick(!click);

    const [profileClick, setProfileClick] = useState<boolean>(false);
    const handleProfileClick = useCallback(() => {
        if (!isAuthenticated)
            return;

        if (isCompany) {
            navigate('companii/' + company.id!);
        } else {
            navigate('candidati/' + user!.id!);
        }

        if(window.innerWidth <= 768){
            setClick(false)
        }
    }, [user, company, window.innerWidth]);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setProfileClick(click);
        }
    }, [click])

    const logout = async () => {
        try {
            await signOut(auth);
            setProfileClick(false);
            navigate('/conectare');
        } catch (error: any) {
            console.error(error.message);
        }
    }

    const goToLogin = () => {
        navigate('/conectare');
        if(window.innerWidth <= 768){
            setClick(false)
        }
    }

    useEffect(() => {
        if (first.current) {
            first.current = false;
            return;
        }
        setClick(false);

        if (user) {
            const unsubscribeFromUserChanges = onSnapshot(doc(db, 'Users', user!.id!), async (doc) => {
                let data = await FirebaseUtil.read('Users', user.id!);
                const date3MonthsAgo = (new Date().getTime()) / 1000 - 7776000;
                let jobApplicationsDocs = await FirebaseUtil.getDocsWithCondition('JobApplications', {
                    fieldPath: 'userId',
                    opStr: '==',
                    value: user!.id
                },);
                let jobAppliedToIds = jobApplicationsDocs.docs
                    .filter(jad => jad.data().createdAt.seconds > date3MonthsAgo)
                    .map(jad => jad.data().jobId);

                dispatch(authenticationSlice.actions.setUser({
                    userData: {
                        ...data,
                        jobApplicationsIds: jobAppliedToIds
                    }
                }));
            })

            return unsubscribeFromUserChanges;
        } else if (company) {
            const unsubscribeFromUserChanges = onSnapshot(doc(db, 'Companies', company.id!), async (doc) => {
                let data = await FirebaseUtil.read('Companies', company.id!);
                let docs = await FirebaseUtil.getDocsInOrderWithCondition(
                    'Jobs',
                    'createdAt',
                    false,
                    undefined,
                    undefined,
                    undefined,
                    {fieldPath: 'companyId', opStr: '==', value: company.id!});
                let jobs: Job[] = [];
                docs.forEach(doc => {
                    jobs = [...jobs, {
                        id: doc.data().id,
                        skills: doc.data().skills,
                        confidentialSalary: doc.data().confidentialSalary,
                        salary: doc.data().salary,
                        locationType: doc.data().locationType,
                        numberOfOpenings: doc.data().numberOfOpenings,
                        careerLevel: doc.data().careerLevel,
                        jobType: doc.data().jobType,
                        industry: doc.data().industry,
                        languages: doc.data().languages,
                        description: doc.data().description,
                        title: doc.data().title,
                        address: doc.data().address,
                        active: doc.data().active,
                        createdAt: doc.data().createdAt,
                        companyId: doc.data().companyId,
                        latitude: doc.data().latitude,
                        longitude: doc.data().longitude,
                        externalUrl: doc.data().externalUrl,
                        applyExternal: doc.data().applyExternal,
                        needLetter: doc.data().needLetter
                    }];
                })
                dispatch(authenticationSlice.actions.setCompany({companyData: {...data, jobs: jobs}}));
            });

            return unsubscribeFromUserChanges;
        }
    }, [authStateChanged]);

    return (
        <Container style={{zIndex: 1000}}>
            <Button clicked={click} onClick={() => handleClick()}/>
            <SidebarContainer clicked={click}>
                <NavLink to={'/'} style={{all: 'unset', cursor: 'pointer', overflow: 'hidden'}} onClick={() => {
                    if (window.innerWidth <= 768) {
                        setClick(false)
                    }
                }}>
                    <Logo>
                        <img src={logo}/>
                    </Logo>
                </NavLink>
                <SlickBar
                    clicked={click}
                    onMouseEnter={() => setClick(true)}
                    onMouseLeave={() => setClick(false)}>
                    {
                        !!company &&
                        <Item
                            clicked={click}
                            onClick={() => setClick(false)}
                            to="/adauga-job"
                        >
                            <Add/>
                            <Text clicked={click}>Adaugă job</Text>
                        </Item>
                    }
                    <Item
                        clicked={click}
                        onClick={() => setClick(false)}
                        to="/joburi"
                    >
                        <Search/>
                        <Text clicked={click}>Joburi</Text>
                    </Item>
                    {
                        !!company &&
                        <Item
                            clicked={click}
                            onClick={() => setClick(false)}
                            to="/candidati"
                        >
                            <PeopleRounded/>
                            <Text clicked={click}>Candidați</Text>
                        </Item>
                    }
                    {
                        isAuthenticated &&
                        <Item
                            clicked={click}
                            onClick={() => setClick(false)}
                            to="/aplicari"
                        >
                            <Assignment/>
                            <Text clicked={click}>{isCompany ? 'Aplicări' : 'Aplicările mele'}</Text>
                        </Item>
                    }
                    {
                        !!user &&
                        <Item
                            clicked={click}
                            onClick={() => setClick(false)}
                            to="/favorite"
                        >
                            <Star/>
                            <Text clicked={click}>Favorite</Text>
                        </Item>
                    }
                    {
                        isAuthenticated &&
                        <Item
                            clicked={click}
                            onClick={() => setClick(false)}
                            to="/setari"
                        >
                            <SettingsRounded/>
                            <Text clicked={click}>Setări</Text>
                        </Item>
                    }
                    <Item
                        clicked={click}
                        onClick={() => setClick(false)}
                        to="/termeni-si-conditii"
                    >
                        <PrivacyTipRounded />
                        <Text clicked={click}>Termeni și condiții</Text>
                    </Item>
                    <Item
                        clicked={click}
                        onClick={() => setClick(false)}
                        to="/ajutor"
                    >
                        <HelpRounded/>
                        <Text clicked={click}>Ajutor</Text>
                    </Item>
                </SlickBar>
                {
                    isAuthenticated ?
                        <ProfileSlickBar clicked={profileClick}
                                         onMouseEnter={() => setProfileClick(true)}
                                         onMouseLeave={() => setProfileClick(false)}
                        >
                            <div>
                                {user?.photoURL || company?.photoURL ? <img style={{marginLeft: '0.5rem'}}
                                                                            onClick={() => handleProfileClick()}
                                                                            src={isCompany ? company?.photoURL : user?.photoURL}
                                    /> :
                                    isCompany ?
                                        <ApartmentRounded style={{marginLeft: '.5rem'}}
                                                          onClick={() => handleProfileClick()}/> :
                                        <Person style={{marginLeft: '.5rem'}} onClick={() => handleProfileClick()}/>}
                            </div>
                            <ProfileDetails clicked={profileClick}>
                                <div style={{textAlign: 'center', marginRight: '1rem', flexGrow: 1, maxWidth: '110px'}}>
                                    <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                        {isCompany ? company?.companyName : user?.name}
                                    </div>
                                    <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                        {
                                            isCompany ?
                                                <Link to={`/companii/${company?.id}`}
                                                      onClick={() => {
                                                          setProfileClick(false);
                                                          if (window.innerWidth <= 768) {
                                                              setClick(false);
                                                          }
                                                      }}>Vezi
                                                    profilul</Link> :
                                                <Link to={`/candidati/${user?.id}`} onClick={() => {
                                                    setProfileClick(false);
                                                    if (window.innerWidth <= 768) {
                                                        setClick(false);
                                                    }
                                                }}>Vezi
                                                    profilul</Link>
                                        }
                                    </div>
                                </div>

                                <Logout onClick={logout}>
                                    <Tooltip title={'Deconectare'}
                                             TransitionComponent={Zoom}
                                             arrow>
                                        <img src={PowerOff}/>
                                    </Tooltip>
                                </Logout>
                            </ProfileDetails>
                        </ProfileSlickBar> :
                        <Tooltip title={'Conectare'}
                                 TransitionComponent={Zoom}
                                 arrow>
                            <div className={'login-button'}>
                                <Login
                                    onClick={goToLogin}
                                    style={{paddingRight: 0, cursor: 'pointer'}}>
                                    <img src={PowerOff}/>
                                </Login>
                            </div>
                        </Tooltip>
                }
            </SidebarContainer>
        </Container>
    );
};

export default Sidebar;
