import React, {useEffect, useRef, useState} from 'react';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, Slider, SliderThumb, TextField,
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useSelector} from "react-redux";
import '../profile.scss';
import {LabelContentItem} from '../Profile';
import './skillsDialog.scss';
import {Add, Delete} from "@mui/icons-material";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    saveCallback: (newSkills: LabelContentItem[]) => void;
    skills: LabelContentItem[] | undefined;
}

export enum SkillLevelEnum {
    'Novice' = 10 as any,
    'Incepător' = 30 as any,
    'Mediu' = 50 as any,
    'Avansat' = 70 as any,
    'Expert' = 90 as any
}

const SkillsDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [skills, setSkills] = useState<LabelContentItem[]>(props.skills ?? []);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const saveCallback = async () => {
        setIsLoading(true);
        props.saveCallback(skills.filter(skill => skill.label.length > 0));
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>Editează aptitudinile</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                {
                    skills.length > 0 ? skills.map((skill, index) => {
                        return (
                            <div key={'skill-' + index}>
                                <div className={'labels-row'}>
                                    <div style={{fontSize: 14, width: '50%', marginRight: -5}}>
                                        Denumire
                                    </div>
                                    <div className={'skill-level-large'} style={{fontSize: 14}}>
                                        {'Nivel - ' + skill.content}
                                    </div>
                                </div>
                                <div className={'form-row'}>
                                    <TextField
                                        onChange={(e) => {
                                            const newSkills = [...skills];
                                            newSkills[index] = {
                                                label: e.target.value,
                                                content: skill.content
                                            }
                                            setSkills(newSkills)
                                        }}
                                        placeholder={'Ex: C++'}
                                        type={'text'}
                                        fullWidth
                                        value={skill.label}
                                        variant={'standard'}
                                        className={'form-text-field left'}
                                        style={{flex: 1, height: 40}}
                                    />
                                    <div className={'form-text-field right fullwidth-mobile'} style={{flex: 1}}>
                                        <div className={'skill-level-small'} style={{fontSize: 14}}>
                                            {'Nivel - ' + skill.content}
                                        </div>
                                        <Slider
                                            className={'skill-slider color-' + SkillLevelEnum[skill.content]}
                                            style={{height: 40, paddingTop: 0}}
                                            step={null}
                                            value={SkillLevelEnum[skill.content] as any}
                                            marks={[
                                                {
                                                    value: 10,
                                                },
                                                {
                                                    value: 30,
                                                },
                                                {
                                                    value: 50,
                                                },
                                                {
                                                    value: 70,
                                                },
                                                {
                                                    value: 90,
                                                },
                                            ]}
                                            track={false}
                                            onChange={(e, value) => {
                                                const newSkills = [...skills];
                                                newSkills[index] = {
                                                    label: skill.label,
                                                    content: SkillLevelEnum[value as any]
                                                }
                                                setSkills(newSkills)
                                            }}
                                        />
                                    </div>
                                    <div style={{height: 60, width: 40, paddingLeft: window.innerWidth < 768 ? 0 : 10}}>
                                        <IconButton onClick={() => {
                                            const newSkills = [...skills];
                                            newSkills.splice(index, 1);
                                            setSkills(newSkills);
                                        }}>
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        );
                    }) : null
                }
                <Button onClick={() => setSkills([...skills, {
                    label: '',
                    content: 'Mediu'
                }])} style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                    {
                        <>
                            <Add color={'primary'} fontSize={'small'}/>
                            <span>Adaugă aptitudine</span>
                        </>
                    }
                </Button>
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default SkillsDialog;