import MotionHoc from "../MotionHoc";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {JobApplication} from "../../models/JobApplication";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../App";
import {Button, Checkbox, CircularProgress, Container, FormControlLabel, MenuItem, Select} from "@mui/material";
import ApplicationListItem from "../ApplicationListItem";

const ApplicationsComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let jobId = searchParams.get('jobId');
    let showUnresolvedApplications = searchParams.get('active') !== 'false';

    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const ITEMS_PER_PAGE = 20;

    const navigate = useNavigate();
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const companies = useSelector((s : ApplicationState) => s.data.companiesInformation);

    const [jobApplications, setJobApplications] = useState<JobApplication[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(!companies || !(companies instanceof Map))
            return;

        if (window.location.pathname.includes('aplicari'))
            getJobApplications();
    }, [jobId, showUnresolvedApplications, page, companies]);

    const getJobApplications = async () => {
        setJobApplications([]);
        setResultsCount(0);
        setIsLoading(true);

        try {
            const getJobApplicationsWithFilters = httpsCallable(functions, 'getJobApplicationsWithFilters');
            await getJobApplicationsWithFilters({
                jobId: !!user ? null : jobId,
                companyId: company?.id,
                userId: user?.id,
                resolvedApplications: !showUnresolvedApplications,
                page: page,
                itemsPerPage: ITEMS_PER_PAGE,
                companies: Array.from(companies.keys())
            })
                .then(async (result) => {
                    setJobApplications((result.data as any).jobApplications.map((ja: any) => {
                        return {
                            ...ja,
                            createdAt: new Date(ja.createdAt._seconds * 1000),
                        }
                    }));
                    setResultsCount((result.data as any).resultsCount);
                    setMaxPages(Math.ceil((result.data as any).resultsCount / ITEMS_PER_PAGE));
                });
        } catch (e: any) {
        } finally {
            setIsLoading(false);
        }
    }

    const getPaginationButtons = () => {
        if (maxPages === 0) return;
        let paginationButtons: any[] = [];
        if (page > 1) {
            paginationButtons = [
                <Button
                    key={'prec'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page - 1);
                    }}
                >
                    <span className={'only-large'}>Pagina precedenta</span>
                    <span className={'only-small'}>Prec.</span>
                </Button>
            ];
        }

        paginationButtons = [
            ...paginationButtons,
            <Button
                key={'1'}
                className={`new-job-button pagination-button ${page === 1 ? '' : 'not-selected'}`}
                onClick={() => {
                    setPage(1);
                }}
            >
                1
            </Button>
        ];

        if (page === 2) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'2'}
                    className={'new-job-button pagination-button'}>
                    2
                </Button>
            ];
        } else if (page > 2) {
            paginationButtons = [
                ...paginationButtons,
                '...',
                <Button
                    key={'page-number'}
                    className={'new-job-button pagination-button'}
                    onClick={() => {
                        setPage(page);
                    }}
                >
                    {page}
                </Button>
            ];
        }

        if (page < maxPages) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'next'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    <span className={'only-large'}>Pagina urmatoare</span>
                    <span className={'only-small'}>Urm.</span>
                </Button>
            ];
        }

        return paginationButtons;
    }

    const resolveJobApplication = (jobApplicationId: string) => {
        setJobApplications(
            (oldJobApplications) =>
                oldJobApplications.filter(ja => ja.id !== jobApplicationId));
    }

    return (
        <Container maxWidth={'xl'} className={'page-container'}
                   style={{paddingTop: 30, paddingBottom: 30, position: 'relative'}}>
            <div className={'jobs-header'}>
                {
                    !!company ?
                        <div
                            className={'applications-job-selector-container'}
                            style={{minWidth: '40%'}}>
                            <h2>
                                Aplicări -
                                <span style={{opacity: 0}}>-</span>
                            </h2>
                            <Select
                                variant={'standard'}
                                style={{flex: 1}}
                                className={'applications-job-selector'}
                                value={jobId ?? 'all'}
                                onChange={(e) => {
                                    if (e.target.value !== 'all') {
                                        searchParams.set('jobId', e.target.value);
                                    } else {
                                        searchParams.delete('jobId')
                                    }
                                    navigate(searchParams.toString().length > 0 ? '/aplicari?' + searchParams.toString() : '/aplicari');
                                }}
                            >
                                <MenuItem value={'all'}>Toate joburile</MenuItem>
                                {
                                    company?.jobs?.map((job, index) => {
                                        return <MenuItem key={index} value={job.id}>{job.title}</MenuItem>;
                                    })
                                }
                            </Select>
                        </div> :
                        <div className={'applications-job-selector-container'}>
                            <h2>Aplicările mele</h2>
                        </div>
                }
                <FormControlLabel
                    className={'applications-checkbox'}
                    control={
                        <Checkbox
                            checked={showUnresolvedApplications}
                            onChange={(e) => {
                                searchParams.set('active', e.target.checked.toString());
                                navigate(`/aplicari?${searchParams.toString()}`)
                            }}
                        />}
                    label={'Aplicări active'}
                />
            </div>
            <div className={'jobs-page'}>
                <div className={'jobs-list-container'}>
                    {
                        (jobApplications.length > 0) ?
                            jobApplications.map(jobApplication => {
                                return <ApplicationListItem key={jobApplication.id}
                                                            jobApplication={!!user ? {
                                                                ...jobApplication,
                                                                user: user
                                                            } : jobApplication}
                                                            resolveJobApplicationCallback={resolveJobApplication}
                                                            userJob={!!user ? jobApplication.job : undefined}
                                />
                            }) : isLoading || !companies || !(companies instanceof Map) ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '50vh',
                                    alignItems: 'center'
                                }}>
                                    <CircularProgress size={60}/>
                                </div>
                                :
                                <div style={{paddingTop: 15}}>Nicio aplicare in acest moment</div>
                    }
                    {
                        !isLoading &&
                        <div className={'pagination-container'} style={{position: 'relative'}}>
                            {
                                getPaginationButtons()
                            }
                            {
                                resultsCount > 0 &&
                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: -10,
                                    fontWeight: 400,
                                    color: '#858c95'
                                }}>
                                    {`${(page - 1) * ITEMS_PER_PAGE + 1}-${Math.min(page * ITEMS_PER_PAGE, resultsCount)} din ${resultsCount}`}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </Container>
    )
        ;
};

const Applications = MotionHoc(ApplicationsComponent);

export default Applications;
