import React, {useEffect, useState} from 'react';
import {reauthenticateWithCredential, updateEmail, EmailAuthProvider} from "firebase/auth";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import './settings.scss';
import FirebaseUtil from "../Firebase/Firebase";
import {auth} from "../../App";
import {snackbarSlice} from "../../store/Snackbar";

interface Props {
    expanded: boolean;
    onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    collapse: () => void;
}

const EmailAccordion = (props: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const isCompany = !!company;

    const [newEmail, setNewEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setEmailError('');
        setPasswordError('');
        setPassword('');
        setIsLoading(false);
    }, [props.expanded]);

    const changeAccountEmail = async () => {
        if (isLoading)
            return;

        setIsLoading(true);
        if (!emailRegex.test(newEmail.trim())) {
            setEmailError('Introduceți un email valid');
            return;
        }

        let emailIsNotUnique = false;
        await FirebaseUtil.getDocs('Users').then(docs => {
            docs.forEach((doc) => {
                if (doc.data()['email'] === newEmail.trim()) {
                    emailIsNotUnique = true;
                    return;
                }
            })
        })
        await FirebaseUtil.getDocs('Companies').then(docs => {
            docs.forEach((doc) => {
                if (doc.data()['email'] === newEmail.trim()) {
                    emailIsNotUnique = true;
                    return;
                }
            })
        })
        if (emailIsNotUnique) {
            setEmailError('Un cont exista deja pentru e-mail-ul introdus!');
            setIsLoading(false);
            return;
        }

        const currentEmail = auth.currentUser?.email;
        const credential = EmailAuthProvider.credential(currentEmail!, password);
        try {
            await reauthenticateWithCredential(auth.currentUser!, credential);
        } catch (e: any) {
            dispatch(snackbarSlice.actions.show({
                message: 'Parola incorecta',
                severity: 'error',
            }));
            setEmailError('');
            setPasswordError('');
            setPassword('');
            setIsLoading(false);
            return;
        }

        await updateEmail(auth.currentUser!, newEmail.trim());
        await FirebaseUtil.write(isCompany ? 'Companies' : 'Users', isCompany ? company!.id! : user!.id!, {
            email: newEmail.trim()
        });
        dispatch(snackbarSlice.actions.show({
            message: 'E-mail schimbat cu succes!',
            severity: 'success',
        }));
        props.collapse();
        setNewEmail('');
        setPassword('');
        setIsLoading(false);
    }

    return (
        <Accordion
            expanded={props.expanded}
            onChange={!isLoading ? props.onChange('mail') : () => {
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDownward/>}
                aria-controls="panel1a-content"
            >
                <div>
                    <h3>Adresa de e-mail</h3>
                    <p className={'grey-subtitle'}>
                        {`Adresa de e-mail folosită pentru autentificare: `}
                        <span style={{
                            fontWeight: 500,
                            opacity: 1
                        }}>{isCompany ? company.email : user?.email}</span>
                    </p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                    variant={'standard'}
                    label={'E-mail nou'}
                    fullWidth
                    type={'email'}
                    className={'accordion-text-field'}
                    value={newEmail}
                    error={emailError.length > 0}
                    onChange={(e) => {
                        setNewEmail(e.target.value)
                        setEmailError('');
                    }}
                />
                {
                    emailError.length > 0 &&
                    <p className={'error-paragraph'} style={{color: 'red', fontSize: 14}}>
                        {emailError}
                    </p>
                }
                <TextField
                    variant={'standard'}
                    label={'Parola curenta'}
                    fullWidth
                    type={'password'}
                    className={'accordion-text-field'}
                    value={password}
                    error={passwordError.length > 0}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                    }}
                />
                {
                    passwordError.length > 0 &&
                    <p className={'error-paragraph'} style={{color: 'red', fontSize: 14}}>
                        {passwordError}
                    </p>
                }
            </AccordionDetails>
            <AccordionActions>
                <Button
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}
                    onClick={changeAccountEmail}
                >
                    Salvează
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

export default EmailAccordion;