import React from 'react';
import {HelpPageProps} from "./types";
import {Box} from "@mui/material";

const JobsHelp = ({isCandidate}: HelpPageProps) => {
    return (
        <Box paddingTop={'10px'}>
            <h1 style={{marginBottom: 20}}>JOBURI</h1>
            <p>Această pagină de ajutor acoperă diferite părți ale modului în care funcționează JOBURILE.</p>
            <p>Pagina de joburi poate fi accesata din bara de navigatie, apasand pe butonul de <b>joburi</b>.
            </p>
            <img src={'images/joburi-1.png'} className={'help-page-vertical-image'}/>
            {
                isCandidate && <div>
                    <h2>CARD JOB</h2>
                    <p>Cardul unui job prezinta informatii legate de job, cum ar fi: denumirea companiei, denumirea
                        postului, domeniul jobului, nivelul jobului, locatia jobului, data publicarii.</p>
                    <p>Poti vedea mai multe detalii legate de job, cum ar fi descriere, salariu, nivel de experienta
                        necesar, cunostinte necesare etc.
                        apasand pe titlul jobului.</p>
                    <p>Poti aplica la un job folosind butonul 'APLICA' de pe anuntul jobului.</p>
                    <img src={'images/joburi-2.png'} className={'help-page-image'}/>
                    <p>Poti adauga un job la favorite folosind butonul dedicat din coltul stanga-sus.</p>
                    <img src={'images/joburi-3.png'} className={'help-page-image'}/>

                    <h2>FILTRARE JOBURI</h2>
                    <p>Poti filtra joburile cautand dupa nume sau aplicand anumite filtre prestabilite. Filtrele pot fi
                        modificate
                        folosind butonul de editare filtre.</p>
                    <img src={'images/joburi-4.png'} className={'help-page-square-image'}/>
                </div>
            }
            {
                !isCandidate && <div>
                    <h2>ADAUGARE JOB</h2>
                    <p>Poti adauga un job din 3 locuri diferite:</p>
                    <ul>
                        <li>
                            <p>bara de navigare</p>
                        </li>
                        <img src={'images/joburi-5.png'} className={'help-page-square-image'}/>
                        <li>
                            <p>pagina de profil, widget-ul de joburi</p>
                        </li>
                        <img src={'images/joburi-6.png'} className={'help-page-square-image'}/>
                        <li>
                            <p>pagina de joburi</p>
                        </li>
                        <img src={'images/joburi-7.png'} className={'help-page-image'}/>
                    </ul>

                    <h2>DEZACTIVARE/STERGERE JOB</h2>
                    <p>Un job poate fi dezactivat sau sters din pagina de profil, widgetul de joburi.</p>
                    <img src={'images/joburi-8.png'} className={'help-page-image'}/>

                    <h2>EDITARE DETALII JOB</h2>
                    <p>Detaliile unui job pot fi editate din pagina jobului, de pe butonul de editare al widget-ului de
                        detalii.</p>
                    <img src={'images/joburi-9.png'} className={'help-page-image'}/>

                    <h2>EDITARE DESCRIERE JOB</h2>
                    <p>Descrierea unui job poate fi editata din pagina jobului, de pe butonul de editare al widget-ului de
                        descriere.</p>
                    <img src={'images/joburi-10.png'} className={'help-page-image'}/>

                    <h2>EDITARE APTITUDINI JOB</h2>
                    <p>Aptitudinile unui job pot fi editate din pagina jobului, de pe butonul de editare al widget-ului de
                        aptitudini.</p>
                    <img src={'images/joburi-11.png'} className={'help-page-image'}/>
                </div>
            }
        </Box>
    );
}

export default JobsHelp;