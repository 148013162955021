import React, {useEffect} from "react";
import Login from "./components/Login";
import {getAuth} from "firebase/auth";
import {getPerformance} from "firebase/performance";
import {initializeApp} from 'firebase/app';
import Layout from "./components/Layout/Layout";
import {Route, Routes, Navigate, useLocation, Router} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {authenticationSlice} from "./store/Authentication";
import PrivateRoute, {CompanyPrivateRoute, UserPrivateRoute} from "./components/PrivateRoute";
import {AnimatePresence} from "framer-motion";
import styled from "styled-components";
import {useNavigate} from "react-router";
import Profile from "./components/Profile";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {User} from "@firebase/auth";
import FirebaseUtil from "./components/Firebase/Firebase";
import Favorites from "./components/Favorites";
import {getStorage} from "firebase/storage";
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
import RegisterCompany from "./components/RegisterCompany";
import CompanyProfile from "./components/CompanyProfile";
import PageNotFound from "./components/PageNotFound";
import AddJob from "./components/AddJob";
import JobDetails from "./components/JobDetails";
import Geocode from 'react-geocode';
import Home from "./components/Home";
import {CompanyCachedInformation, dataSlice} from "./store/Data";
import JobsList from "./components/JobsList";
import Applications from "./components/Applications";
import CandidatesList from "./components/CandidatesList";
import Settings from "./components/Settings";
import Terms from "./components/Terms";
import Help from "./components/Help";

const firebaseConfig = {
    apiKey: "AIzaSyA9EMUdW5NokJDjzjYqiPm_UcqNMxUVmfk",
    authDomain: "resita-jobs.firebaseapp.com",
    projectId: "resita-jobs",
    storageBucket: "resita-jobs.appspot.com",
    messagingSenderId: "499962361446",
    appId: "1:499962361446:web:1afc0d28325ebe3de99fa7",
    measurementId: "G-PVLEQ7XYY6"
};

const Pages = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;

  h1 {
    font-size: calc(2rem + 2vw);
    background: linear-gradient(to right, #803bec 30%, #1b1b1b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const app = initializeApp(firebaseConfig);
const perf = getPerformance(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-central2');
// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);
export const storage = getStorage();
export const cuiApiKey = '62b497925d70f57ca7c4ba341ca351338f684c20';
export const cuiUrl = 'https://infocui.ro/system/api/data?key=62b497925d70f57ca7c4ba341ca351338f684c20&cui=';
export const geocode = Geocode;
export const GOOGLE_API_KEY = 'AIzaSyA9EMUdW5NokJDjzjYqiPm_UcqNMxUVmfk';
const GEOCODING_API_KEY = 'AIzaSyB2eHcpL477NSLf0M4iWsNhTa9eMWBcuus';

geocode.setApiKey(GEOCODING_API_KEY);
geocode.setLanguage('ro');
geocode.setRegion('ro');
auth.languageCode = 'ro';

const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        cacheCompaniesInformation();
        const unsubscribe = auth.onAuthStateChanged(async authUser => {
            if (authUser) {
                const doc = await FirebaseUtil.findDoc('Companies', authUser.uid!);
                if (doc.exists()) {
                    await setCompany(authUser);
                } else {
                    await setUser(authUser);
                }
            } else {
                //guest login
                dispatch(authenticationSlice.actions.removeUser());
            }

            dispatch(authenticationSlice.actions.triggerAuthStateChange());
        });

        return unsubscribe;
    }, []);

    const cacheCompaniesInformation = async () => {
        await FirebaseUtil.getDocs('Companies').then(docs => {
            const companiesInformation: Map<string, CompanyCachedInformation> = new Map<string, CompanyCachedInformation>();
            for (let i = 0; i < docs?.length; i++) {
                if (!!docs[i].data().active) {
                    companiesInformation.set(docs[i].data().id, {
                        name: docs[i].data().companyName,
                        photoURL: docs[i].data().photoURL,
                        email: docs[i].data().email
                    })
                }
            }
            dispatch(dataSlice.actions.setCompaniesInformation({companiesInformation: companiesInformation}))
        });
    }

    const setUser = async (authUser: User | null) => {
        if (!authUser) {
            dispatch(authenticationSlice.actions.removeUser());
            return;
        }

        let data = await FirebaseUtil.read('Users', authUser!.uid);
        if (!data) {
            await FirebaseUtil.write('Users', authUser!.uid, {
                id: authUser!.uid,
                email: authUser!.email,
                // contactEmail: authUser!.email,
                name: authUser!.displayName,
                phoneNumber: authUser!.phoneNumber,
                photoURL: authUser.providerData[0].providerId === 'facebook.com' ? authUser!.photoURL + '?type=large&width=400&height=400' : authUser!.photoURL,
            });
            data = await FirebaseUtil.read('Users', authUser!.uid);
        }

        dispatch(authenticationSlice.actions.setUser({userData: data}));
    }

    const setCompany = async (authUser: User | null) => {
        if (!authUser) {
            dispatch(authenticationSlice.actions.removeUser());
            return;
        }

        let data = await FirebaseUtil.read('Companies', authUser!.uid);

        dispatch(authenticationSlice.actions.setCompany({companyData: data}));
    }

    useEffect(() => {
        // Scroll top when location changes
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300)
    }, [location]);


    return (
        <Layout>
            <AnimatePresence exitBeforeEnter={true}>
                <Routes location={location} key={location.pathname}>
                    <Route path={'/conectare'} element={<Login/>}/>
                    <Route path={'/inregistrare'} element={<Login/>}/>
                    <Route path={'/inregistrare-companie'} element={<RegisterCompany/>}/>
                    <Route path={'/404'} element={<PageNotFound/>}/>
                    <Route path={'/'} element={
                        <Home/>
                    }/>
                    <Route path={'/aplicari'} element={
                        <PrivateRoute>
                            <Applications/>
                        </PrivateRoute>
                    }/>
                    <Route path={'/favorite'} element={
                        <UserPrivateRoute>
                            <Favorites/>
                        </UserPrivateRoute>
                    }/>
                    <Route path={'/candidati/:id'} element={
                        <Profile/>
                    }/>
                    <Route path={'/candidati'} element={
                        <CandidatesList/>
                    }/>
                    <Route path={'/companii/:id'} element={
                        <CompanyProfile/>
                    }/>
                    <Route path={'/adauga-job'} element={
                        <CompanyPrivateRoute>
                            <AddJob/>
                        </CompanyPrivateRoute>
                    }/>
                    <Route path={'/joburi/:id'} element={
                        <JobDetails/>
                    }/>
                    <Route path={'/joburi'} element={
                        <JobsList/>
                    }/>
                    <Route path={'/setari'} element={
                        <PrivateRoute>
                            <Settings/>
                        </PrivateRoute>
                    }/>
                    <Route path={'/ajutor'} element={
                        <Help/>
                    }/>
                    <Route path={'/termeni-si-conditii'} element={
                        <Terms/>
                    }/>
                    <Route path={'*'} element={<Navigate to={'/404'} replace={true}/>}/>
                </Routes>
            </AnimatePresence>
        </Layout>
    );
}

export default App;