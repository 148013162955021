import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Container} from "@mui/material";
import JobListItem from "../JobListItem";
import JobsFilterCard from "../JobsFilterCard";
import {useNavigate} from "react-router";
import {ApplicationState} from "../../store";
import {useSelector} from "react-redux";
import FirebaseUtil from "../Firebase/Firebase";
import {CareerLevelEnum, Job, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import './jobsList.scss'
import {useSearchParams} from "react-router-dom";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import MotionHoc from "../MotionHoc";
import {httpsCallable} from 'firebase/functions';
import {functions} from "../../App";

interface Props {
    isForHomePage?: boolean;
}

export const JobsListComponent = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isJobsPage = window.location.pathname.includes('/joburi') && window.location.pathname[7] !== '/';
    const [hasFilters, setHasFilters] = useState(false);

    let query = searchParams.get('query');
    let jobType = searchParams.getAll('type');
    let careerLevel = searchParams.getAll('level');
    let locationType = searchParams.getAll('location');
    let industries = searchParams.getAll('industry');

    const [page, setPage] = useState(1);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const ITEMS_PER_PAGE = 20;

    const navigate = useNavigate();
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const companies = useSelector((s : ApplicationState) => s.data.companiesInformation);

    const [jobs, setJobs] = useState<Job[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [filterText, setFilterText] = useState<string>('');
    const [maxPages, setMaxPages] = useState<number>(0);

    useEffect(() => {
        if(!companies || !(companies instanceof Map))
            return;

        if (isJobsPage) {
            setFilterText(query ? query : '');
            setHasFilters(searchParams.toString().length > 0);
        }

        setIsLoading(true);
        if (props.isForHomePage) {
            getLastJobs();
        } else {
            if (isJobsPage)
                getJobs();
        }
    }, [searchParams, page, companies]);

    const getLastJobs = async () => {
        let docs = await FirebaseUtil.getDocsInOrderWithCondition('Jobs', 'createdAt', false, 30, undefined, undefined, {
            value: true,
            opStr: '==',
            fieldPath: 'active'
        });
        let jobs: Job[] = [];
        let myDocs = docs.docs.filter(doc => companies.has(doc.data().companyId));
        myDocs = myDocs.filter((doc, index) => index < 6);
        myDocs.forEach(doc => {
            jobs = [...jobs, {
                id: doc.data().id,
                skills: doc.data().skills,
                confidentialSalary: doc.data().confidentialSalary,
                salary: doc.data().salary,
                locationType: doc.data().locationType,
                numberOfOpenings: doc.data().numberOfOpenings,
                careerLevel: doc.data().careerLevel,
                jobType: doc.data().jobType,
                industry: doc.data().industry,
                languages: doc.data().languages,
                description: doc.data().description,
                title: doc.data().title,
                address: doc.data().address,
                active: doc.data().active,
                createdAt: doc.data().createdAt,
                companyId: doc.data().companyId,
                latitude: doc.data().latitude,
                longitude: doc.data().longitude,
                externalUrl: doc.data().externalUrl,
                applyExternal: doc.data().applyExternal,
                needLetter: doc.data().needLetter
            }];
        })
        setJobs(jobs);
        setIsLoading(false);
    }

    const getJobs = async () => {
        setJobs([]);
        setResultsCount(0);
        setIsLoading(true);
        const getDocsWithFilters = httpsCallable(functions, 'getDocsWithFilters');
        try {
            await getDocsWithFilters({
                query: query,
                jobType: jobType.map(type => JobTypeEnum[type as any]),
                industries: industries.map(industry => IndustriesEnum[industry as any]),
                careerLevel: careerLevel.map(level => CareerLevelEnum[level as any]),
                locationType: locationType.map(type => LocationTypeEnum[type as any]),
                page: page,
                itemsPerPage: ITEMS_PER_PAGE,
                companies: Array.from(companies.keys()),
            })
                .then(async (result) => {
                    setJobs((result.data as any).jobs);
                    setMaxPages(Math.ceil((result.data as any).resultsCount / ITEMS_PER_PAGE));
                    setResultsCount((result.data as any).resultsCount);
                });
        } catch (e: any) {
        } finally {
            setIsLoading(false);
        }
    }

    const getPaginationButtons = () => {
        if (maxPages === 0) return;
        let paginationButtons: any[] = [];
        if (page > 1) {
            paginationButtons = [
                <Button
                    key={'next'}
                    style={{whiteSpace: 'nowrap'}}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page - 1);
                    }}
                >
                    <span className={'only-large'}>Pagina precedenta</span>
                    <span className={'only-small'}>Prec.</span>
                </Button>
            ];
        }

        paginationButtons = [
            ...paginationButtons,
            <Button
                key={'1'}
                className={`new-job-button pagination-button ${page === 1 ? '' : 'not-selected'}`}
                onClick={() => {
                    // searchParams.set('page', '1')
                    // navigate('/joburi?' + searchParams.toString())
                    setPage(1);
                }}
            >
                1
            </Button>
        ];

        if (page === 2) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'2'}
                    className={'new-job-button pagination-button'}>
                    2
                </Button>
            ];
        } else if (page > 2) {
            paginationButtons = [
                ...paginationButtons,
                '...',
                <Button
                    key={'page-number'}
                    className={'new-job-button pagination-button'}
                    onClick={() => {
                        // searchParams.set('page', page.toString());
                        // navigate('/joburi?' + searchParams.toString())
                        setPage(page);
                    }}
                >
                    {page}
                </Button>
            ];
        }

        if (page < maxPages) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'next'}
                    style={{whiteSpace: 'nowrap'}}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    <span className={'only-large'}>Pagina urmatoare</span>
                    <span className={'only-small'}>Urm.</span>
                </Button>
            ];
        }

        return paginationButtons;
    }

    const searchJobsWithQuery = () => {
        if (filterText)
            searchParams.set('query', filterText);
        else
            searchParams.delete('query');
        // searchParams.delete('page');
        navigate(searchParams.toString().length > 0 ? '/joburi?' + searchParams.toString() : '/joburi');
    }

    return (
        <Container maxWidth={'xl'} className={'page-container'}
                   style={{paddingTop: 30, paddingBottom: 30, position: 'relative'}}>
            {
                !props.isForHomePage &&
                <div className={'jobs-sidebar-small'}>
                    <div className={'sidebar-holder'}>
                        <input type="search"
                               value={filterText}
                               className="sidebar-search-input"
                               placeholder="Căutare joburi"
                               onChange={(e) => setFilterText(e.target.value)}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter' || e.key === 'Return') {
                                       searchJobsWithQuery()
                                   }
                               }}
                        />
                        <Button
                            style={{width: '100%', marginTop: 20}}
                            className={'new-job-button'}
                            onClick={() => {
                                searchJobsWithQuery()
                            }}
                        >
                            Caută job
                        </Button>
                        <JobsFilterCard
                            searchParams={searchParams}
                            jobType={jobType.map(job => JobTypeEnum[job as keyof typeof JobTypeEnum])}
                            careerLevel={careerLevel.map(level => CareerLevelEnum[level as keyof typeof CareerLevelEnum])}
                            industries={industries.map(industry => IndustriesEnum[industry as keyof typeof IndustriesEnum])}
                            locationType={locationType.map(location => LocationTypeEnum[location as keyof typeof LocationTypeEnum])}
                            query={query ?? ''}
                        />
                    </div>
                </div>
            }
            <div className={'jobs-header'}>
                {
                    props.isForHomePage ?
                        <h2>
                            Ultimele <span
                            style={{color: 'var(--dark-primary)', textDecoration: 'underline'}}>joburi</span>
                        </h2> :
                        hasFilters ?
                            <h2>Rezultatele căutării</h2>
                            : <h2>
                                Ultimele <span
                                style={{color: 'var(--dark-primary)', textDecoration: 'underline'}}>joburi</span>
                            </h2>
                }
                {
                    company &&
                    <Button
                        className={'new-job-button'}
                        onClick={() => {
                            navigate('/adauga-job');
                        }}
                    >
                        Posteaza un job
                    </Button>
                }
            </div>
            <div className={'jobs-page'}>
                <div className={'jobs-list-container no-padding'}>
                    {
                        (jobs.length > 0) ?
                            jobs.map(job => {
                                return <JobListItem key={job.id} job={job} isFromFrontEndQuery={props.isForHomePage}/>
                            }) : isLoading || !companies || !(companies instanceof Map) ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: props.isForHomePage ? '20vh' : '50vh',
                                    alignItems: 'center'
                                }}>
                                    <CircularProgress size={60}/>
                                </div>
                                :
                                'Niciun job in acest moment'
                    }
                    {
                        !props.isForHomePage && !isLoading &&
                        <div className={'pagination-container'} style={{position: 'relative'}}>
                            {
                                getPaginationButtons()
                            }
                            {
                                resultsCount > 0 &&
                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: -10,
                                    fontWeight: 400,
                                    color: '#858c95'
                                }}>
                                    {`${(page - 1) * ITEMS_PER_PAGE + 1}-${Math.min(page * ITEMS_PER_PAGE, resultsCount)} din ${resultsCount}`}
                                </div>
                            }
                        </div>
                    }
                    {
                        props.isForHomePage &&
                        <Button
                            className={'new-job-button'}
                            onClick={() => {
                                navigate('/joburi');
                            }}
                        >
                            Vezi toate joburile
                        </Button>
                    }
                </div>
                {
                    !props.isForHomePage &&
                    <div className={'jobs-sidebar-large'}>
                        <div className={'sidebar-holder'}>
                            <h3 className={'sidebar-title'}>
                                Caută joburi
                            </h3>
                            <input type="search"
                                   value={filterText}
                                   className="sidebar-search-input"
                                   placeholder="Căutare joburi"
                                   onChange={(e) => setFilterText(e.target.value)}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter' || e.key === 'Return') {
                                           searchJobsWithQuery()
                                       }
                                   }}
                            />
                            <Button
                                style={{width: '100%', marginTop: 20}}
                                className={'new-job-button'}
                                onClick={() => {
                                    searchJobsWithQuery()
                                }}
                            >
                                Caută job
                            </Button>
                            <JobsFilterCard
                                searchParams={searchParams}
                                jobType={jobType.map(job => JobTypeEnum[job as keyof typeof JobTypeEnum])}
                                careerLevel={careerLevel.map(level => CareerLevelEnum[level as keyof typeof CareerLevelEnum])}
                                industries={industries.map(industry => IndustriesEnum[industry as keyof typeof IndustriesEnum])}
                                locationType={locationType.map(location => LocationTypeEnum[location as keyof typeof LocationTypeEnum])}
                                query={query ?? ''}
                            />
                        </div>
                    </div>
                }
            </div>

        </Container>
    );
}

const JobsList = MotionHoc(JobsListComponent);

export default JobsList;