import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
    sendPasswordResetEmail
} from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import {ReactComponent as SignInSvg} from '../../images/svgs/signin.svg';
import {ReactComponent as SignUpSvg} from '../../images/svgs/signup.svg';
import LogoPng from '../../images/logo6.png';
import './Login.css';
import {auth} from "../../App";
import {useDispatch, useSelector} from "react-redux";
import {snackbarSlice} from "../../store/Snackbar";
import {useNavigate} from "react-router";
import {ApplicationState} from "../../store";
import {NavLink, useSearchParams} from "react-router-dom";
import FirebaseUtil from "../Firebase/Firebase";
import MotionHoc from "../MotionHoc";
import {Button, Checkbox, FormControlLabel, IconButton} from "@mui/material";
import {HelpRounded} from "@mui/icons-material";

type inputType =
    'signinEmail'
    | 'signinPassword'
    | 'signupEmail'
    | 'signupPassword'
    | 'signupConfirmPassword'
    | 'signupName';

interface LoginInformation {
    email: string,
    password: string,
}

interface RegisterInformation {
    name: string,
    nameError: string,
    email: string,
    emailError: string,
    password: string,
    passwordError: string,
    confirmPassword: string,
    confirmPasswordError: string,
}

const LoginComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let callbackUrl = searchParams.get('callbackUrl');
    const isSignInPage = window.location.pathname === '/conectare';

    const [isLogin, setIsLogin] = useState(isSignInPage);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const companies = useSelector((s: ApplicationState) => s.data.companiesInformation);

    const [loginInformation, setLoginInformation] = useState<LoginInformation>({
        email: '',
        password: ''
    });
    const [registerInformation, setRegisterInformation] = useState<RegisterInformation>(
        {
            name: '',
            nameError: 'Introduceți numele si cel putin un prenume',
            email: '',
            emailError: 'Introduceți un e-mail valid.',
            password: '',
            passwordError: 'Parola trebuie sa aiba minim 6 caractere.',
            confirmPassword: '',
            confirmPasswordError: ''
        });
    const [showRegisterErrors, setShowRegisterErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState<boolean>(true);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [numberOfJobs, setNumberOfJobs] = useState<number | null>();

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const signInBtn = document.querySelector("#sign-in-btn");
        const signUpBtn = document.querySelector("#sign-up-btn");
        const container = document.querySelector(".login-container");

        signUpBtn?.addEventListener('click', () => {
            container?.classList.add("sign-up-mode");
            setIsLogin(false);
        });

        signInBtn?.addEventListener('click', () => {
            container?.classList.remove("sign-up-mode");
            setIsLogin(true);
        });
    }, []);

    useEffect(() => {
        if (user || company) {
            navigate('/', {
                replace: true
            });
            dispatch(snackbarSlice.actions.show({
                message: 'Conectare reușită!',
                severity: 'success',
            }));
        }
    }, []);

    useEffect(() => {
        if (!companies || !(companies instanceof Map))
            return;

        FirebaseUtil.getDocsWithCondition('Jobs', {
            fieldPath: 'active',
            opStr: '==',
            value: true
        }).then(docs => {
            let jobs = docs.docs.filter(doc => companies.has(doc.data().companyId));
            setNumberOfJobs(jobs.length);
        })
    }, [companies]);

    useEffect(() => {
        setTimeout(() => {
            setShowRegisterErrors(false);
        }, 1000)
    }, [isLogin])

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (isLogin) {
            if (!isResetPassword)
                login();
            else
                resetPassword();
        } else {
            register();
        }
    }

    const handleRegisterValidation = () => {
        return registerInformation.emailError === ''
            && registerInformation.passwordError === ''
            && registerInformation.confirmPasswordError === ''
            && registerInformation.nameError === ''
            && acceptTerms;
    }

    const redirectToHome = (isCompany: boolean, id: string) => {
        if (callbackUrl) {
            navigate(callbackUrl);
        } else {
            if (!isCompany) {
                navigate('/candidati/' + id);
                return;
            }
            navigate('/companii/' + id);
        }
    }

    const register = async () => {
        if (isLoading)
            return;

        if (!handleRegisterValidation()) {
            setShowRegisterErrors(true);
            return;
        }

        setIsLoading(true);

        try {
            // await setPersistence(auth, browserSessionPersistence);
            const userCredential = await createUserWithEmailAndPassword(auth, registerInformation.email, registerInformation.password);
            await FirebaseUtil.write('Users', userCredential.user.uid, {
                id: userCredential.user.uid,
                email: registerInformation.email,
                name: registerInformation.name.replace(/\s+/g, ' '),
                phoneNumber: null,
                city: null,
                photoURL: null,
                birthDate: null,
                appearInSearches: false,
                createdAt: new Date(),
                active: true,
            });
            dispatch(snackbarSlice.actions.show({
                message: 'Inregistrare reușită!',
                severity: 'success',
            }));
            redirectToHome(false, userCredential.user.uid);
        } catch (error: any) {
            console.error(error.message);
            dispatch(snackbarSlice.actions.show({
                message: 'Un cont exista deja pentru e-mail-ul introdus!',
                severity: 'error',
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const resetPassword = async () => {
        if (isLoading)
            return;

        setIsLoading(true);

        try {
            if (!re.test(loginInformation.email)) {
                throw Error('E-mail invalid!');
            }
            await sendPasswordResetEmail(auth, loginInformation.email);
            dispatch(snackbarSlice.actions.show({
                message: 'Un link pentru resetare a fost trimis pe e-mail-ul introdus, daca exista un cont pentru acesta!',
                severity: 'success',
            }));
            setIsResetPassword(false);
        } catch (error: any) {
            console.error(error.message);
            dispatch(snackbarSlice.actions.show({
                message: 'E-mail invalid!',
                severity: 'error',
            }));
        } finally {
            setIsLoading(false);
            clearLoginInformation();
        }
    }


    const login = async () => {
        if (isLoading || (loginInformation.email === '' && loginInformation.password === '')) {
            dispatch(snackbarSlice.actions.show({
                message: 'Completați toate câmpurile și încercați din nou.',
                severity: 'error',
            }));
            return;
        }

        setIsLoading(true);

        try {
            if (rememberMe)
                await setPersistence(auth, browserLocalPersistence);
            else
                await setPersistence(auth, browserSessionPersistence);
            const user = await signInWithEmailAndPassword(auth, loginInformation.email, loginInformation.password);
            dispatch(snackbarSlice.actions.show({
                message: 'Conectare reușită!',
                severity: 'success',
            }));
            redirectToHome(companies.has(user.user.uid), user.user.uid);
        } catch (error: any) {
            console.error(error.message);
            dispatch(snackbarSlice.actions.show({
                message: 'Date incorecte!',
                severity: 'error',
            }));
            clearLoginInformation();
        } finally {
            setIsLoading(false);
        }
    }

    const loginWithGoogle = async () => {
        var googleProvider = new GoogleAuthProvider();
        if (rememberMe)
            await setPersistence(auth, browserLocalPersistence);
        else
            await setPersistence(auth, browserSessionPersistence);
        signInWithPopup(auth, googleProvider).then(
            (result) => {
                dispatch(snackbarSlice.actions.show({
                    message: 'Conectare reușită!',
                    severity: 'success',
                }));
                redirectToHome(false, result.user.uid);
            }
        ).catch((err) => {
            console.error(err.message);
        });
    }

    const loginWithFacebook = async () => {
        var facebookProvider = new FacebookAuthProvider();
        if (rememberMe)
            await setPersistence(auth, browserLocalPersistence);
        else
            await setPersistence(auth, browserSessionPersistence);
        facebookProvider.setCustomParameters({
            'display': 'popup'
        })
        signInWithPopup(auth, facebookProvider).then(
            (result) => {
                dispatch(snackbarSlice.actions.show({
                    message: 'Conectare reușită!',
                    severity: 'success',
                }));
                redirectToHome(false, result.user.uid);
            }
        ).catch((err) => {
            console.error(err.message);
        });
    }


    const clearLoginInformation = () => {
        setLoginInformation({
            ...loginInformation,
            password: ''
        });
    }

    const handleChange = (e: any, target: inputType) => {
        let error = '';
        switch (target) {
            case "signinEmail":
                if (!re.test(e.target.value)) {
                    error = 'Introduceți un e-mail valid.';
                }
                setLoginInformation({...loginInformation, email: e.target.value as string});
                break;
            case 'signinPassword':
                setLoginInformation({...loginInformation, password: e.target.value as string});
                break;
            case 'signupName':
                if (!e.target.value.trim().includes(' ')) {
                    error = 'Introduceți numele si cel putin un prenume';
                }
                // if (e.target.value.trim().length > 50) {
                //     error = 'Acest camp poate avea maxim 50 caractere';
                // }
                setRegisterInformation({
                    ...registerInformation,
                    name: e.target.value as string,
                    nameError: error,
                });
                break;
            case "signupEmail":
                if (!re.test(e.target.value)) {
                    error = 'Introduceți un e-mail valid';
                }
                setRegisterInformation({
                    ...registerInformation,
                    email: e.target.value as string,
                    emailError: error,
                });
                break;
            case 'signupPassword':
                if (e.target.value.length < 6) {
                    error = 'Parola trebuie sa aibă minim 6 caractere';
                }
                setRegisterInformation({
                    ...registerInformation,
                    password: e.target.value as string,
                    passwordError: error
                });
                break;
            case 'signupConfirmPassword':
                if (e.target.value !== registerInformation.password) {
                    error = 'Parolele nu coincid.';
                }
                setRegisterInformation({
                    ...registerInformation,
                    confirmPassword: e.target.value as string,
                    confirmPasswordError: error,
                });
                break;
        }
    }

    return (
        <div className={`login-container ${isSignInPage ? '' : 'sign-up-mode'}`}>
            <IconButton className={`login-help-button ${isLogin ? 'login' : 'signup'}`}
                        onClick={() => navigate('/ajutor')}
            >
                <HelpRounded style={{color: 'white'}}/>
            </IconButton>
            <div className={'forms-container'}>
                <div className={'signin-signup'}>
                    <form action={''} className={'sign-in-form'} onSubmit={onSubmit} autoComplete={'on'}>
                        <NavLink to={'/'}>
                            <img src={LogoPng} className={'logo'}/>
                        </NavLink>
                        <h2 className={'title'}>
                            {!isResetPassword ? 'Conectare' : 'Resetare parola'}
                        </h2>
                        <div className={'input-field'}>
                            <i className={'fas fa-user'}/>
                            <input type={'email'} placeholder={'E-mail'}
                                   value={loginInformation.email}
                                   onChange={(e) => {
                                       handleChange(e, 'signinEmail');
                                   }}/>
                        </div>
                        {
                            !isResetPassword &&
                            <div className={'input-field'}>
                                <i className={'fas fa-lock'}/>
                                <input type={'password'} placeholder={'Parola'}
                                       value={loginInformation.password}
                                       onChange={(e) => {
                                           handleChange(e, 'signinPassword');
                                       }}
                                />
                            </div>
                        }
                        <NavLink
                            style={{margin: '7.5px 0'}}
                            to={'#'}
                            onClick={() => setIsResetPassword(!isResetPassword)}
                        >
                            {!isResetPassword ? 'Ai uitat parola?' : 'Înapoi la conectare'}
                        </NavLink>
                        {/*<div style={{width: '100%', maxWidth: '380px', marginLeft: 48}}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={*/}
                        {/*            <Checkbox*/}
                        {/*                checked={rememberMe}*/}
                        {/*                onChange={async (e) => {*/}
                        {/*                    setRememberMe(e.target.checked);*/}
                        {/*                    // if(e.target.checked){*/}
                        {/*                    //     await setPersistence(auth, browserLocalPersistence);*/}
                        {/*                    //     return;*/}
                        {/*                    // }*/}
                        {/*                    //*/}
                        {/*                    // await setPersistence(auth, browserSessionPersistence);*/}
                        {/*                }}*/}
                        {/*            />}*/}
                        {/*        label={'Ramai conectat'}/>*/}
                        {/*</div>*/}
                        <input type={'submit'} style={{display: 'none'}}/>
                        <div onClick={onSubmit} className={'submit-button button button-solid'}>
                            {!isResetPassword ? 'Conectează-te' : 'Resetează parola'}
                        </div>
                        {
                            !isResetPassword &&
                            <>
                                <p className={'social-text'}>Conectează-te folosind o platformă socială</p>
                                <div className={'social-media'}>
                                    <a onClick={loginWithFacebook} className="social-icon">
                                        <i className={'fab fa-facebook-f no-margin'}/>
                                    </a>
                                    <a onClick={loginWithGoogle} className="social-icon">
                                        <i className={'fab fa-google no-margin'}/>
                                    </a>
                                </div>
                            </>
                        }
                    </form>
                    <form action={''} className={'sign-up-form'} onSubmit={onSubmit}>
                        <NavLink to={'/'}>
                            {/*<Logo className={'logo'}/>*/}
                            <img src={LogoPng} className={'logo'}/>
                        </NavLink>
                        <h2 className={'title'}>
                            Înregistrare
                        </h2>
                        <div className={'input-field'}>
                            <i className={'fas fa-user'}/>
                            <input type={'text'} placeholder={'Nume complet'}
                                   value={registerInformation.name}
                                   onChange={(e) => {
                                       handleChange(e, 'signupName');
                                   }}/>
                        </div>
                        {showRegisterErrors && <span className={'error'}>{registerInformation.nameError}</span>}
                        <div className={'input-field'}>
                            <i className={'fas fa-envelope'}/>
                            <input type={'email'} placeholder={'E-mail'}
                                   value={registerInformation.email}
                                   onChange={(e) => {
                                       handleChange(e, 'signupEmail');
                                   }}/>
                        </div>
                        {showRegisterErrors && <span className={'error'}>{registerInformation.emailError}</span>}
                        <div className={'input-field'}>
                            <i className={'fas fa-lock'}/>
                            <input type={'password'} placeholder={'Parola'}
                                   value={registerInformation.password}
                                   autoComplete={'new-password'}
                                   onChange={(e) => {
                                       handleChange(e, 'signupPassword');
                                   }}/>
                        </div>
                        {showRegisterErrors && <span className={'error'}>{registerInformation.passwordError}</span>}
                        <div className={'input-field'}>
                            <i className={'fas fa-lock'}/>
                            <input type={'password'} placeholder={'Confirmă parola'}
                                   value={registerInformation.confirmPassword}
                                   autoComplete={'new-password'}
                                   onChange={(e) => {
                                       handleChange(e, 'signupConfirmPassword');
                                   }}/>
                        </div>
                        {showRegisterErrors &&
                        <span className={'error'}>{registerInformation.confirmPasswordError}</span>}
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTerms}
                                        onChange={async (e) => {
                                            setAcceptTerms(e.target.checked);
                                        }}
                                    />}
                                label={<p>Sunt de acord cu <NavLink to={'/termeni-si-conditii'}>termenii si
                                    condițiile</NavLink></p>}/>
                        </div>
                        {showRegisterErrors && !acceptTerms &&
                        <span className={'error'}>Acest camp este obligatoriu.</span>}
                        <input type={'submit'} style={{display: 'none'}}/>
                        <div onClick={onSubmit} className={'submit-button button button-solid'}>
                            Înregistrează-te
                        </div>

                        <p className={'register-text'}>Ești o companie și dorești să recrutezi?
                            {/*Înregistrează-te <NavLink*/}
                            {/*    to={'/inregistrare-companie'}>aici</NavLink>!*/}
                        </p>
                        <NavLink
                            to={'/inregistrare-companie'}>
                            <button className={'button primary-border'}>
                                Înregistrează-ți compania
                            </button>
                        </NavLink>
                    </form>
                </div>
            </div>
            <div className="panels-container">
                <div className="panel left-panel">
                    <div className="content">
                        <h3>
                            Nu ai cont?
                        </h3>
                        <p className={'content-text'}>
                            Înregistrează-te acum pentru a găsi cele mai noi oportunități de angajare cu ajutorul
                            celor <span>{numberOfJobs ?? '...'}</span> de locuri de muncă disponibile.
                        </p>
                        <button className={'button transparent'} id={'sign-up-btn'}>
                            Înregistrează-te
                        </button>
                    </div>

                    <SignInSvg className={'image'}/>
                </div>
                <div className="panel right-panel">
                    <div className="content">
                        <h3>
                            Ai deja cont?
                        </h3>
                        <p className={'content-text'}>
                            Conectează-te si află ce s-a mai întâmplat cât timp ai fost plecat.
                        </p>
                        <button className={'button transparent'} id={'sign-in-btn'}>
                            Conectează-te
                        </button>
                    </div>

                    <SignUpSvg className={'image'}/>
                </div>
            </div>
        </div>
    );
}

const Login = MotionHoc(LoginComponent);

export default Login;