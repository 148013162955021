import {Button, IconButton} from "@mui/material";
import {Assignment, Edit} from "@mui/icons-material";
import React, {useState} from "react";
import {snackbarSlice} from "../../../store/Snackbar";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {Job} from "../../../models/Job";
import './cardHeader.scss'
import '../../JobListItem/jobListItem.scss';
import '../../CompanyProfile/Cards/jobCard.scss';
import JobApplyDialog from "../../JobApplyDialog";
import {DialogUtil} from "../../Utils/DialogUtil";
import {applyToJob} from "../../JobListItem/JobListItem";
import {useNavigate} from "react-router";
import {authenticationSlice} from "../../../store/Authentication";

interface Props {
    title: string;
    subtitle?: string;
    isOwnProfile: boolean;
    editCallback?: () => void;
    hideEditButton?: boolean;
    job?: Job;
    forceShowEditButton?: boolean;
}

const CardHeader = (props: Props) => {
    const dispatch = useDispatch();
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const navigate = useNavigate();

    const [showApplyDialog, setShowApplyDialog] = useState(false);
    const [showConfirmApplyExternalDialog, setShowConfirmApplyExternalDialog] = useState(false);
    const [isAwaitingRequestResponse, setIsAwaitingRequestResponse] = useState<boolean>(false);

    const redirectExternal = (option: boolean) => {
        setShowConfirmApplyExternalDialog(false);
        if (!option || !props.job)
            return;

        const newWindow = window.open(props.job.externalUrl!, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const applyCallback = async (coverLetter: string) => {
        setIsAwaitingRequestResponse(true);
        const result = await applyToJob(user, props.job!, coverLetter);
        if (!result) {
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error'
            }));
        } else {
            dispatch(snackbarSlice.actions.show({
                message: 'Ai aplicat cu succes!',
                severity: 'success'
            }));
            dispatch(authenticationSlice.actions.setUser(
                {
                    userData: {
                        ...user,
                        jobApplicationsIds: [...user?.jobApplicationsIds ?? [], props.job!.id]
                    }
                }));
        }
        setShowApplyDialog(false);
        setIsAwaitingRequestResponse(false);
    }

    return (
        <>
            <div className={'information-card-header'}>
                <div className={'header-div'}>
                    {
                        props.job &&
                        <Assignment color={'primary'} className={'leading-icon'}/>
                    }
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {
                                props.subtitle ? <h2>{props.title}</h2> : <h3>{props.title}</h3>
                            }
                            {
                                props.job && (props.isOwnProfile || !props.job.active) && <Button
                                    disabled
                                    style={{
                                        color: 'white',
                                        backgroundColor: props.job.active ? 'green' : 'red',
                                        padding: '2.5px 15px',
                                        marginLeft: 14,
                                        borderRadius: 12,
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em'
                                    }}
                                >
                                    {props.job.active ? 'Activ' : 'Inactiv'}
                                </Button>
                            }
                        </div>
                        {
                            props.subtitle &&
                            <p style={{color: "grey"}}>{props.subtitle}</p>
                        }
                    </div>
                </div>
                {
                    !!props.job && props.job.active && !!user &&
                    (!user?.jobApplicationsIds?.find(jobId => jobId === props.job!.id) ?
                        <Button
                            disabled={isAwaitingRequestResponse}
                            onClick={() => {
                                if (!user) {
                                    navigate(`/conectare?callbackUrl=/joburi/${props.job!.id}`)
                                    return;
                                }

                                if (!user.active) {
                                    dispatch(snackbarSlice.actions.show({
                                        message: 'Activeaza-ti contul pentru a putea aplica la joburi!',
                                        severity: 'error',
                                    }))
                                    navigate('/setari');
                                    return;
                                }

                                if (!(!!props.job && props.job.active && !!user &&
                                    (!user?.jobApplicationsIds?.find(jobId => jobId === props.job!.id))))
                                    return;

                                if (props.job!.applyExternal) {
                                    setShowConfirmApplyExternalDialog(true);
                                } else if (props.job!.needLetter) {
                                    setShowApplyDialog(true)
                                } else {
                                    applyCallback('');
                                }
                            }}
                            className={'apply-button'}
                            style={{
                                textTransform: 'uppercase',
                                color: 'white',
                                fontSize: 14,
                                letterSpacing: '0.08em',
                                fontWeight: 700,
                                backgroundColor: 'var(--dark-primary)',
                                padding: '10px 16px',
                                borderRadius: 12,
                            }}
                        >
                            {props.job.applyExternal ? 'Aplică extern' : 'Aplică'}
                        </Button> :
                        <Button
                            className={'applied-to-job-button disable-hover'}
                            disabled={true}
                        >
                            Aplicat
                        </Button>)
                }
                {
                    ((props.isOwnProfile && !props.hideEditButton) || (props.forceShowEditButton)) &&
                    <IconButton
                        className={'information-card-edit-button'}
                        onClick={props.editCallback}>
                        <Edit color={'secondary'}/>
                    </IconButton>
                }
                {
                    props.job && showApplyDialog &&
                    <JobApplyDialog
                        closeCallback={() => setShowApplyDialog(false)}
                        applyCallback={applyCallback}
                        job={props.job}
                    />
                }
                {
                    DialogUtil.confirm(
                        {
                            title: 'Vei fi redirecționat pe o pagină externă. Ești sigur că dorești acest lucru?',
                        },
                        showConfirmApplyExternalDialog,
                        redirectExternal,
                        true
                    )
                }
            </div>
            {
                props.job && props.isOwnProfile &&
                <Button
                    className={'new-job-button'}
                    style={{
                        marginTop: 10,
                        fontSize: 14,
                        padding: '10px 20px',
                        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 5px 5px 0 rgba(0, 0, 0, 0.2)'
                    }}
                    onClick={() => navigate('/aplicari?jobId=' + props.job!.id)}
                >
                    Vezi aplicările
                </Button>
            }
        </>
    );
}

export default CardHeader