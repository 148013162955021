import React from 'react';
import './cardItem.scss';
import {Apartment, Delete, Edit, School} from "@mui/icons-material";
import {ReactComponent as CertifiedIcon} from "../../../images/svgs/certified.svg";
import DateUtil from "../../Utils/DateUtil";
import {IconButton} from "@mui/material";
import {IndustriesEnum} from "../../CompanyProfile/Dialogs/CompanyProfileDialog";

export enum WorkTypeEnum {
    none = '' as any,
    partTime = 'Part-time' as any,
    fullTime = 'Full-time' as any,
    internship = 'Internship' as any,
    selfEmployed = 'Propriul angajat' as any,
    freelance = 'Freelance' as any,
    contract = 'Contract' as any,
    personalProject = 'Proiect personal' as any
}

export enum CardTypeEnum {
    experience,
    education,
    training,
}

export enum SchoolTypeEnum {
    none = '',
    generala = 'Școală generală',
    liceu = 'Liceu',
    profesionala = 'Școală profesională',
    postliceale = 'Studii postliceale',
    facultate = 'Facultate',
    masterat = 'Masterat',
    doctorat = 'Doctorat',
    mba = 'MBA'
}

export interface ExperienceInformation {
    positionName: string,
    companyName: string,
    workType: WorkTypeEnum,
    industry: IndustriesEnum | null,
    startDate: any,
    endDate: any,
    location: string,
    description?: string,
}

export interface EducationInformation {
    schoolName: string,
    startDate: any,
    endDate: any,
    specialization: string,
    schoolType: SchoolTypeEnum,
    grade?: number,
    description?: string,
}

export interface TrainingInformation {
    trainingName: string,
    startDate: any,
    endDate: any,
    industry: IndustriesEnum | null,
    description?: string,
}

interface Props {
    job?: ExperienceInformation,
    education?: EducationInformation,
    training?: TrainingInformation,
    cardType: CardTypeEnum,
    editCallback: () => void,
    deleteCallback: () => void,
    isOwnProfile: boolean;
}

const CardItem = (props: Props) => {

    switch (props.cardType) {
        case CardTypeEnum.experience:
            return props.job ? (
                <div className={'card-item'}>
                    <div className={'leading-icon-container'}>
                        <Apartment className={'leading-icon'}/>
                    </div>
                    <div className={'card-item-content-container'}>
                        <div className={'card-title'}>
                            {props.job.positionName + ' @ ' + props.job.companyName}
                        </div>
                        <div className={'card-subtitle'}>
                            {props.job.industry + ' · ' + props.job.workType}
                        </div>
                        <div className={'card-time-span'}>
                            {DateUtil.getMonthYearFromString(props.job.startDate) + ' - '
                            + (props.job.endDate ? DateUtil.getMonthYearFromString(props.job.endDate!) : 'Prezent')
                            + ' · ' + DateUtil.getTimePassed(props.job.startDate!, props.job.endDate)}
                        </div>
                        {
                            props.job.location ? <div className={'card-location'}>
                                {props.job.location}
                            </div> : null
                        }
                        {
                            props.job.description ? <div className={'card-description'}>
                                {props.job.description}
                            </div> : null
                        }
                    </div>
                    {
                        props.isOwnProfile &&
                        <div className={'card-item-edit-container'}>
                            <IconButton onClick={props.editCallback}>
                                <Edit
                                    className={'edit-icon'}
                                />
                            </IconButton>
                            <IconButton onClick={props.deleteCallback}>
                                <Delete
                                    className={'edit-icon'}
                                />
                            </IconButton>
                        </div>
                    }
                </div>
            ) : null;
        case CardTypeEnum.education:
            return props.education ? (
                <div className={'card-item'}>
                    <div className={'leading-icon-container'}>
                        <School className={'leading-icon'}/>
                    </div>
                    <div className={'card-item-content-container'}>
                        <div className={'card-title'}>
                            {props.education.schoolName}
                        </div>
                        <div className={'card-subtitle'}>
                            {props.education.schoolType + ' · ' + props.education.specialization}
                        </div>
                        <div className={'card-time-span'}>
                            {DateUtil.getMonthYearFromString(props.education.startDate) + ' - '
                            + (props.education.endDate ? DateUtil.getMonthYearFromString(props.education.endDate!) : 'Prezent')}
                        </div>
                        {
                            props.education.grade ? <div className={'card-location'}>
                                {'Medie absolvire: ' + props.education.grade}
                            </div> : null
                        }
                        {
                            props.education.description ? <div className={'card-description'}>
                                {props.education.description}
                            </div> : null
                        }
                    </div>
                    {
                        props.isOwnProfile && <div className={'card-item-edit-container'}>
                            <IconButton onClick={props.editCallback}>
                                <Edit
                                    className={'edit-icon'}
                                />
                            </IconButton>
                            <IconButton onClick={props.deleteCallback}>
                                <Delete
                                    className={'edit-icon'}
                                />
                            </IconButton>
                        </div>
                    }
                </div>
            ) : null;
        case CardTypeEnum.training:
            return props.training ? (
                <div className={'card-item'}>
                    <div className={'leading-icon-container'}>
                        <CertifiedIcon className={'leading-icon dark-blue'}/>
                    </div>
                    <div className={'card-item-content-container'}>
                        <div className={'card-title'}>
                            {props.training.trainingName}
                        </div>
                        <div className={'card-subtitle'}>
                            {'Domeniu: ' + props.training.industry}
                        </div>
                        <div className={'card-time-span'}>
                            {DateUtil.getMonthYearFromString(props.training.startDate) + ' - '
                            + (props.training.endDate ? DateUtil.getMonthYearFromString(props.training.endDate!) : 'Prezent')}
                        </div>
                        {
                            props.training.description ? <div className={'card-description'}>
                                {props.training.description}
                            </div> : null
                        }
                    </div>
                    {
                        props.isOwnProfile &&
                        <div className={'card-item-edit-container'}>
                            <IconButton onClick={props.editCallback}>
                                <Edit
                                    className={'edit-icon'}
                                />
                            </IconButton>
                            <IconButton onClick={props.deleteCallback}>
                                <Delete
                                    className={'edit-icon'}
                                />
                            </IconButton>
                        </div>
                    }
                </div>
            ) : null;
        default:
            return null;
    }
}

export default CardItem;