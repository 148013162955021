import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Job} from "../../models/Job";
import '../JobListItem/jobListItem.scss'
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {
    ApartmentRounded, Engineering,
    LocationOn,
    LocationOnOutlined, PersonRounded,
    StarOutline,
    StarOutlined, StarOutlineRounded,
    StarRounded
} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import {ReactComponent as ClockSvg} from '../../images/svgs/clock.svg';
import FullMonths from "../Constants/Months";
import {Button, CircularProgress, IconButton, Tooltip, Zoom} from "@mui/material";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import JobApplyDialog from "../JobApplyDialog";
import {DialogUtil} from "../Utils/DialogUtil";
import FirebaseUtil from "../Firebase/Firebase";
import {v4} from 'uuid';
import {snackbarSlice} from "../../store/Snackbar";
import {authenticationSlice, UserDetails} from "../../store/Authentication";
import {useNavigate} from "react-router";
import DateUtil from "../Utils/DateUtil";
import {ReactComponent as CV} from "../../images/svgs/cv.svg";

interface Props {
    candidate: UserDetails;
}

const CandidateListItem = ({candidate}: Props) => {
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);

    const [showApplyDialog, setShowApplyDialog] = useState(false);
    const [showConfirmApplyExternalDialog, setShowConfirmApplyExternalDialog] = useState(false);
    const [isAwaitingRequestResponse, setIsAwaitingRequestResponse] = useState<boolean>(false);
    const [isSettingFavoriteJob, setIsSettingFavoriteJob] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div className={'list-item'}>
            <NavLink
                className={'company-logo'}
                to={`/candidati/${candidate.id}`}>
                {
                    candidate.photoURL ?
                        <img src={candidate.photoURL}/> :
                        <PersonRounded className={'profile-icon'}/>
                }
            </NavLink>
            <div className={'job-body'} style={{alignItems: 'stretch', overflow: 'hidden'}}>
                <div className={'job-info-primary'}>
                    <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <div className={'company-name'} style={{cursor: 'default'}}>
                            {
                                // get most recent experience or add a title field in profile
                                // now they are being sorted when changed in user profile
                                candidate.experience && candidate.experience[0] &&
                                (candidate.experience[0].positionName + ' @ ' +
                                    candidate.experience[0].companyName)
                            }
                        </div>
                        <NavLink
                            className={'job-name'}
                            to={`/candidati/${candidate.id}`}
                            style={{marginBottom: 8}}>
                            <h3>
                                {
                                    candidate.name + (candidate.birthDate ? ' · ' + DateUtil.getAge(candidate.birthDate as any, !!company) + ' ani' : '')
                                }
                            </h3>
                        </NavLink>
                    </div>
                    <div className={'job-card-info-bottom'}>
                        {
                            candidate.qualification &&
                            <div className={'job-type-container'} style={{marginRight: 16}}>
                                <Engineering className={'job-type-icon'} color={'primary'} style={{marginRight: 6}}/>
                                <div className={'job-type-name'}>
                                    {candidate.qualification}
                                </div>
                            </div>
                        }
                        {
                            !!company && candidate.cvDownloadUrl &&
                            <Tooltip title={'Descarca CV'}
                                     TransitionComponent={Zoom}
                                     arrow>
                                <div
                                    className={'job-badge card svg-hover-white'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: 0,
                                        padding: '6px 12px'
                                    }}
                                    onClick={() => {
                                        FirebaseUtil.download(candidate?.cvDownloadUrl!, `CV_${candidate?.name!.replace(' ', '_')}`)
                                    }}
                                >
                                    <CV
                                        className={'job-type-icon'}
                                        color={'primary'}
                                        style={{
                                            marginRight: 6,
                                            width: 20,
                                            height: 20
                                        }}/>
                                    {'CV'}
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidateListItem;