import {LabelContentItem} from "../components/Profile/Profile";
import {IndustriesEnum} from "../components/CompanyProfile/Dialogs/CompanyProfileDialog";

export enum JobTypeEnum {
    none = '' as any,
    parttime = 'Part-time' as any,
    fulltime = 'Full-time' as any,
    internship = 'Internship' as any,
    contract = 'Contract' as any,
    voluntariat = 'Voluntariat' as any
}

export enum CareerLevelEnum {
    none = '' as any,
    noexperience = 'Fara experienta' as any,
    entry = 'Entry (0-2 ani)' as any,
    middle = 'Middle (2-5 ani)' as any,
    senior = 'Senior (5-10 ani)' as any,
    executive = 'Executive (>10 ani)' as any,
}

export const CareerLevelMap: Map<CareerLevelEnum, number> = new Map<CareerLevelEnum, number>([
    [CareerLevelEnum.noexperience, 0],
    [CareerLevelEnum.entry, 24],
    [CareerLevelEnum.middle, 60],
    [CareerLevelEnum.senior, 120],
    [CareerLevelEnum.executive, 999999],
]);

export enum LocationTypeEnum {
    none = '' as any,
    remote = 'Remote (de acasa)' as any,
    hibrid = 'Hibrid (de acasa si fizic)' as any,
    onsite = 'On-site (fizic, la sediu)' as any,
}

export interface Job{
    [index: string]:any,
    id: string,
    title: string,
    numberOfOpenings: number,
    jobType: JobTypeEnum,
    careerLevel: CareerLevelEnum[],
    locationType?: LocationTypeEnum,
    salary?: string,
    confidentialSalary: boolean,
    description: string,
    industry: IndustriesEnum,
    skills: LabelContentItem[],
    languages: string,
    address: string | null,
    googleAddress?: google.maps.places.AutocompletePrediction | null,
    active?: boolean;
    companyId: string;
    createdAt?: Date;
    latitude?: number;
    longitude?: number;
    applyExternal?: boolean;
    externalUrl?: string;
    needLetter?: boolean;
}