import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import './skillsDialog.scss';
import {snackbarSlice} from "../../../store/Snackbar";
import {EducationInformation, SchoolTypeEnum} from "../Cards/CardItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import DateUtil from "../../Utils/DateUtil";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
    education: EducationInformation;
    isNewEducation?: boolean;
    educationIndex?: number;
}

const EducationDialog = (props: DialogProps) => {
    const user = useSelector((s: ApplicationState) => s.auth.user)

    const [education, setEducation] = useState<EducationInformation>({
        ...props.education,
        startDate: props.education.startDate ? DateUtil.getDateFromTimestamp(props.education.startDate) : null,
        endDate: props.education.endDate ? DateUtil.getDateFromTimestamp(props.education.endDate) : null
    });
    const [currentlyStudying, setCurrentlyStudying] = useState(props.education.endDate === null && props.education.startDate !== null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsFormValid(
            education.schoolName.length > 0 &&
            education.schoolType !== SchoolTypeEnum.none &&
            education.specialization.length > 0 &&
            !!education.startDate && !isNaN(education.startDate) &&
            (currentlyStudying || (!!education.endDate && !isNaN(education.endDate)))
        );
    }, [education, currentlyStudying]);

    const saveCallback = async () => {
        setIsLoading(true);
        let userEducation = [...user!.education ?? []];

        handleChange(education.startDate, 'startDate');
        handleChange(education.endDate ?? '', 'endDate');

        if (props.isNewEducation) {
            userEducation = [...userEducation, education];
        } else {
            userEducation[props.educationIndex!] = education;
        }

        await FirebaseUtil.write('Users', user!.id!,
            {
                education: userEducation
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        );
        props.closeCallback();
    }

    const handleChange = (value: string | number | object, key: string) => {
        const newEducation = {...education};
        switch (key) {
            case 'grade':
                newEducation.grade = value as number;
                break;
            case 'startDate':
                newEducation.startDate = value as object;
                break;
            case 'endDate':
                newEducation.endDate = value as object;
                break;
            case 'description':
                newEducation.description = value as string;
                break;
            case 'schoolName':
                newEducation.schoolName = value as string;
                break;
            case 'schoolType':
                newEducation.schoolType = value as SchoolTypeEnum;
                break;
            case 'specialization':
                newEducation.specialization = value as string;
                break;
        }

        setEducation(newEducation);
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if(reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>
                {
                    props.isNewEducation ? 'Adaugă studii' : 'Editează studiile'
                }
            </DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <TextField
                    label={'Numele institutiei'}
                    placeholder={'Ex: Universitatea de Vest Timisoara'}
                    required
                    type={'text'}
                    fullWidth
                    value={education.schoolName}
                    onChange={(e) => handleChange(e.target.value, 'schoolName')}
                    variant={'standard'}
                    className={'form-text-field'}
                    error={false}
                />
                <div className={'form-row'} style={{alignItems: 'flex-end'}}>
                    <div className={'form-text-field left'} style={{width: '100%'}}>
                        <InputLabel id={'select-label'} style={{fontSize: 12}}>Tipul institutiei *</InputLabel>
                        <Select
                            onChange={(e) => handleChange(e.target.value as SchoolTypeEnum, 'schoolType')}
                            labelId={'select-label'}
                            required
                            type={'text'}
                            fullWidth
                            value={education.schoolType}
                            variant={'standard'}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}
                        >
                            <MenuItem value={SchoolTypeEnum.generala}>{SchoolTypeEnum.generala}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.liceu}>{SchoolTypeEnum.liceu}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.profesionala}>{SchoolTypeEnum.profesionala}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.postliceale}>{SchoolTypeEnum.postliceale}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.facultate}>{SchoolTypeEnum.facultate}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.masterat}>{SchoolTypeEnum.masterat}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.doctorat}>{SchoolTypeEnum.doctorat}</MenuItem>
                            <MenuItem value={SchoolTypeEnum.mba}>{SchoolTypeEnum.mba}</MenuItem>
                        </Select>
                    </div>
                    <TextField
                        onChange={(e) => handleChange(e.target.value, 'specialization')}
                        label={'Specializare / Profil'}
                        required
                        type={'text'}
                        fullWidth
                        value={education.specialization}
                        variant={'standard'}
                        placeholder={'Ex: Matematica-Informatica'}
                        className={'form-text-field right'}
                    />
                </div>
                <div className={'form-row'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={'Data inceperii'}
                            inputFormat={'dd/MM/yyyy'}
                            value={education.startDate ? education.startDate : null}
                            onChange={(value) => handleChange(value ?? '', 'startDate')}
                            renderInput={(params) => <TextField
                                required
                                variant={'standard'}
                                type={'date'}
                                className={'form-text-field left'}
                                fullWidth
                                error={params.error}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'ZZ/LL/AAAA',
                                }}
                            />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disabled={currentlyStudying}
                            label={'Data finalizarii'}
                            inputFormat={'dd/MM/yyyy'}
                            value={education.endDate && !currentlyStudying ? education.endDate : null}
                            onChange={(value) => {
                                handleChange(value, 'endDate')
                            }}
                            renderInput={(params) => {
                                return <TextField
                                    variant={'standard'}
                                    required
                                    type={'date'}
                                    className={'form-text-field right'}
                                    fullWidth
                                    error={params.error}
                                    InputLabelProps={{shrink: currentlyStudying ? true : undefined}}
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: currentlyStudying ? 'Prezent' : 'ZZ/LL/AAAA',
                                    }}
                                />
                            }}
                        />
                    </LocalizationProvider>
                </div>
                <div className={'form-text-field'}
                     style={{marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentlyStudying}
                                onChange={(e) => {
                                    setCurrentlyStudying(e.target.checked)
                                }}
                            />}
                        label={'Studiez in prezent'}/>
                </div>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'grade')}
                    label={'Nota finala'}
                    type={'number'}
                    InputProps={{inputProps: {min: 1, max: 10}}}
                    fullWidth
                    value={education.grade}
                    variant={'standard'}
                    className={'form-text-field'}
                    error={!!education.grade && (education.grade < 1 || education.grade > 10)}
                />
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'description')}
                    label={'Descriere'}
                    type={'text'}
                    fullWidth
                    value={education.description}
                    variant={'standard'}
                    multiline={true}
                    minRows={2}
                    className={'form-text-field'}
                    placeholder={'Descrie ce ai invatat, cu ce consideri ca te-au ajutat aceste studii.'}
                />
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default EducationDialog;