import React, {useState} from 'react';
import {
    Autocomplete,
    Button,
    Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, IconButton,
    InputLabel, TextField
} from "@mui/material";
import '../Profile/profile.scss';
import './filtersDialog.scss'
import {CareerLevelEnum, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";

interface Props {
    closeCallback: () => void;
    saveCallback: (jobType: JobTypeEnum[],
                   careerLevel: CareerLevelEnum[],
                   locationType: LocationTypeEnum[],
                   industries: IndustriesEnum[]) => void;
    industries: IndustriesEnum[];
    careerLevel: CareerLevelEnum[];
    jobType: JobTypeEnum[];
    locationType: LocationTypeEnum[];
}

export const JobTypeArray: JobTypeEnum[] = [
    JobTypeEnum.fulltime,
    JobTypeEnum.parttime,
    JobTypeEnum.internship,
    JobTypeEnum.contract,
    JobTypeEnum.voluntariat,
];

export const CareerLevelArray: CareerLevelEnum[] = [
    CareerLevelEnum.noexperience,
    CareerLevelEnum.entry,
    CareerLevelEnum.middle,
    CareerLevelEnum.senior,
    CareerLevelEnum.executive,
];

export const LocationTypeArray: LocationTypeEnum[] = [
    LocationTypeEnum.remote,
    LocationTypeEnum.hibrid,
    LocationTypeEnum.onsite,
];


const JobsFilterDialog = (props: Props) => {
    const [careerLevel, setCareerLevel] = useState<string | CareerLevelEnum[]>(props.careerLevel);
    const [jobType, setJobType] = useState<string | JobTypeEnum[]>(props.jobType);
    const [locationType, setLocationType] = useState<string | LocationTypeEnum[]>(props.locationType);
    const [industries, setIndustries] = useState<IndustriesEnum[] | undefined>(props.industries);

    return (
        <Dialog
            open={true}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>Filtrează joburile</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <div className={'form-row'} style={{alignItems: 'flex-start'}}>
                    <div className={'form-text-field left'} style={{flex: 1}}>
                        <InputLabel id={'jobType-label'} style={{fontSize: 14}}>Nivelul jobului</InputLabel>
                        <div className={''}>
                            {
                                JobTypeArray.map(type => {
                                    return <div key={type}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(jobType as JobTypeEnum[]).includes(type as JobTypeEnum)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setJobType([...(jobType as JobTypeEnum[]), (type as JobTypeEnum)])
                                                        } else {
                                                            setJobType((jobType as JobTypeEnum[]).filter(job => job !== type))
                                                        }
                                                    }}
                                                />}
                                            label={type}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={'form-text-field left'} style={{flex: 1}}>
                        <InputLabel id={'jobType-label'} style={{fontSize: 14}}>Experiență</InputLabel>
                        <div className={''}>
                            {
                                CareerLevelArray.map(level => {
                                    return <div key={level}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(careerLevel as CareerLevelEnum[]).includes(level as CareerLevelEnum)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCareerLevel([...(careerLevel as CareerLevelEnum[]), (level as CareerLevelEnum)])
                                                        } else {
                                                            setCareerLevel((careerLevel as CareerLevelEnum[]).filter(lvl => lvl !== level))
                                                        }
                                                    }}
                                                />}
                                            label={level}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={'form-text-field left'} style={{flex: 1}}>
                        <InputLabel id={'jobType-label'} style={{fontSize: 14}}>Tipul jobului</InputLabel>
                        <div className={''}>
                            {
                                LocationTypeArray.map(type => {
                                    return <div key={type}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(locationType as LocationTypeEnum[]).includes(type as LocationTypeEnum)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setLocationType([...(locationType as LocationTypeEnum[]), (type as LocationTypeEnum)])
                                                        } else {
                                                            setLocationType((locationType as LocationTypeEnum[]).filter(location => location !== type))
                                                        }
                                                    }}
                                                />}
                                            label={type}/>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <InputLabel id={'industries-label'} style={{fontSize: 14}}>Domenii</InputLabel>
                <div className={'form-row industries-container'} style={{alignItems: 'stretch', paddingTop: 5}}>
                    <Autocomplete
                        multiple
                        getOptionDisabled={(option) => !!industries && industries.length >= 10}
                        value={industries}
                        onChange={(e, v) => {
                            setIndustries(v as IndustriesEnum[]);
                        }}
                        fullWidth
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    variant={'standard'}
                                    className={'form-text-field'}
                                    placeholder={'Caută un domeniu'}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: [],
                                    }}
                                />
                            );
                        }}
                        options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                        getOptionLabel={(option) => {
                            return option.toString();
                        }}
                        style={{flex: 1}}
                        componentsProps={{
                            paper: {
                                style: {
                                    borderRadius: '20px',
                                }
                            }
                        }}/>
                    <div className={'vertical-divider'}/>
                    <div className={'form-text-field'} style={{flex: 1, paddingLeft: 0, minHeight: 60}}>
                        {
                            industries && industries.map(industry => {
                                return <Chip
                                    key={industry}
                                    label={industry}
                                    style={{borderRadius: 6, margin: 5}}
                                    color={'primary'}
                                    onDelete={() => {
                                        setIndustries(industries.filter(ind => ind !== industry))
                                    }}
                                />
                            })
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.closeCallback}
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}
                >
                    Anulează
                </Button>
                <Button
                    onClick={() => props.saveCallback(
                        (jobType as JobTypeEnum[]),
                        (careerLevel as CareerLevelEnum[]),
                        (locationType as LocationTypeEnum[]), industries ?? [])}
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}
                >
                    Aplică filtrele
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default JobsFilterDialog;