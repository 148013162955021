import {Alert, Slide, Snackbar} from '@mui/material';
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from "../../store";
import {snackbarSlice} from "../../store/Snackbar";

const SnackbarComponent = () => {
    const {open, message, autoHideDuration, severity} = useSelector((s: ApplicationState) => s.snackbar);

    const dispatch = useDispatch();
    const handleClose = (e: any, isFromSnackbar: boolean) => {
        if(e && isFromSnackbar)
            return;
        dispatch(snackbarSlice.actions.hide());
    }

    return <Snackbar
        key={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={(e) => {
            handleClose(e, true);
        }}
    >
        <Alert
            variant='filled'
            elevation={6}
            onClose={(e) => {
                handleClose(e, false);
            }}
            severity={severity}>
            {message}
        </Alert>
    </Snackbar>
};

export default SnackbarComponent