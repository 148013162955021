import React, {useEffect, useState} from 'react';
import MotionHoc from "../MotionHoc";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {Job} from "../../models/Job";
import {db} from '../../App';
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {Button, CircularProgress, Container} from "@mui/material";
import JobListItem from "../JobListItem";

const FavoritesComponent = () => {
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState<number>(0);
    const ITEMS_PER_PAGE = 20;

    const user = useSelector((s: ApplicationState) => s.auth.user);
    const companies = useSelector((s : ApplicationState) => s.data.companiesInformation);

    const [jobs, setJobs] = useState<Job[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(!companies || !(companies instanceof Map))
            return;

        getJobs();
    }, [companies, page]);

    const getJobs = async () => {
        setJobs([]);
        setIsLoading(true);

        try {
            let q = query(collection(db, 'Jobs'), where('active', '==', true), orderBy('createdAt', 'desc'));
            q = query(q, where('id', 'in', user?.favoriteJobsIds ?? []));

            const docs = await getDocs(q);
            let myDocs = docs.docs.filter(doc => companies.has(doc.data().companyId));
            myDocs = myDocs.filter((job, index) =>
                index >= (page - 1) * ITEMS_PER_PAGE &&
                index < page * ITEMS_PER_PAGE);

            setJobs(myDocs.map(doc => {
                return {
                    id: doc.data().id,
                    skills: doc.data().skills,
                    confidentialSalary: doc.data().confidentialSalary,
                    salary: doc.data().salary,
                    locationType: doc.data().locationType,
                    numberOfOpenings: doc.data().numberOfOpenings,
                    careerLevel: doc.data().careerLevel,
                    jobType: doc.data().jobType,
                    industry: doc.data().industry,
                    languages: doc.data().languages,
                    description: doc.data().description,
                    title: doc.data().title,
                    address: doc.data().address,
                    active: doc.data().active,
                    createdAt: doc.data().createdAt,
                    companyId: doc.data().companyId,
                    latitude: doc.data().latitude,
                    longitude: doc.data().longitude,
                    externalUrl: doc.data().externalUrl,
                    applyExternal: doc.data().applyExternal,
                    needLetter: doc.data().needLetter
                };
            }));

            setIsLoading(false);
            if (docs.docs) {
                setMaxPages(Math.ceil(docs.docs.length / ITEMS_PER_PAGE));
            } else {
                setMaxPages(1)
                return;
            }
        } catch (e: any) {
            setIsLoading(false);
            console.error(e.message)
        }
    }

    const getPaginationButtons = () => {
        if (maxPages === 0) return;
        let paginationButtons: any[] = [];
        if (page > 1) {
            paginationButtons = [
                <Button
                    key={'prec'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page - 1);
                    }}
                >
                    <span className={'only-large'}>Pagina precedenta</span>
                    <span className={'only-small'}>Prec.</span>
                </Button>
            ];
        }

        paginationButtons = [
            ...paginationButtons,
            <Button
                key={'1'}
                className={`new-job-button pagination-button ${page === 1 ? '' : 'not-selected'}`}
                onClick={() => {
                    setPage(1);
                }}
            >
                1
            </Button>
        ];

        if (page === 2) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'2'}
                    className={'new-job-button pagination-button'}>
                    2
                </Button>
            ];
        } else if (page > 2) {
            paginationButtons = [
                ...paginationButtons,
                '...',
                <Button
                    key={'page-number'}
                    className={'new-job-button pagination-button'}
                    onClick={() => {
                        setPage(page);
                    }}
                >
                    {page}
                </Button>
            ];
        }

        if (page < maxPages) {
            paginationButtons = [
                ...paginationButtons,
                <Button
                    key={'next'}
                    className={'new-job-button not-selected pagination-button'}
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    <span className={'only-large'}>Pagina urmatoare</span>
                    <span className={'only-small'}>Urm.</span>
                </Button>
            ];
        }

        return paginationButtons;
    }

    return (
        <Container maxWidth={'xl'} className={'page-container'}
                   style={{paddingTop: 30, paddingBottom: 30, position: 'relative'}}>
            <div className={'jobs-header'}>
                <h2>
                    Joburi <span
                    style={{color: 'var(--dark-primary)', textDecoration: 'underline'}}>favorite</span>
                </h2>
            </div>
            <div className={'jobs-list-container'}>
                {
                    (jobs.length > 0) ?
                        jobs.map(job => {
                            return <JobListItem key={job.id} job={job} isFromFrontEndQuery={true}/>
                        }) : isLoading ?
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '50vh',
                                alignItems: 'center'
                            }}>
                                <CircularProgress size={60}/>
                            </div>
                            :
                            'Niciun job in acest moment'
                }
                <div className={'pagination-container'}>
                    {
                        getPaginationButtons()
                    }
                </div>
            </div>
        </Container>
    )
}

const Favorites = MotionHoc(FavoritesComponent);

export default Favorites;