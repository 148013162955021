import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../../Profile/profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import './companyMediaDialog.scss'
import {Add, Close} from "@mui/icons-material";
import {useFilePicker} from "use-file-picker";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
}

const CompanyMediaDialog = (props: DialogProps) => {
    const company = useSelector((s: ApplicationState) => s.auth.company)

    const [mediaUrls, setMediaUrls] = useState<string[]>(company?.mediaUrls ?? []);
    const [newMediaUrls, setNewMediaUrls] = useState<string[]>([]);
    const [newMediaFiles, setNewMediaFiles] = useState<File[]>([]);
    const [openFileSelector, {filesContent, plainFiles, loading, errors, clear}] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: true,
        limitFilesConfig: {max: 9 - mediaUrls.length - newMediaUrls.length},
        maxFileSize: 3,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const dispatch = useDispatch();

    const saveCallback = async () => {
        setIsLoading(true);
        let media = mediaUrls;
        for (const file of newMediaFiles) {
            const downloadUrl = await FirebaseUtil.uploadFile(`${company!.id!}/media/${file.name + new Date()}`, file);
            media = [...media, downloadUrl];
        }
        await FirebaseUtil.write('Companies', company!.id!,
            {
                mediaUrls: media
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        ).finally(() => {
            props.closeCallback();
        });
    }

    const removeMedia = (index: number) => {
        const newUrls = mediaUrls.filter((url, i) => i !== index);
        setMediaUrls(newUrls);
    }

    const removeNewMedia = (index: number) => {
        const newUrls = newMediaUrls.filter((url, i) => i !== index);
        const newFiles = newMediaFiles.filter((url, i) => i !== index);
        setNewMediaUrls(newUrls);
        setNewMediaFiles(newFiles);
    }

    useEffect(() => {
        if(errors.length > 0){
            if(errors[0].fileSizeToolarge)
            {
                dispatch(snackbarSlice.actions.show({
                    message: 'Fisierul este prea mare. Va rugam incarcati un fisier de maxim 1MB.',
                    severity: 'error'
                }));
            } else if(errors[0].maxLimitExceeded){
                dispatch(snackbarSlice.actions.show({
                    message: 'Eroare la incarcare. Puteti incarca maxim 9 poze.',
                    severity: 'error'
                }));
            } else {
                console.error(errors)
            }
        }
    }, [errors]);

    useEffect(() => {
        if(filesContent.length > 0 && plainFiles.length > 0){
            setNewMediaUrls([...newMediaUrls, ...filesContent.map(file => file.content)]);
            setNewMediaFiles([...newMediaFiles, ...plainFiles]);
            clear();
        }
    }, [filesContent, plainFiles])

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
        >
            <DialogTitle>Editează continutul media</DialogTitle>
            <DialogContent className={'media-body-container'} style={{
                paddingTop: 5, paddingBottom: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center'
            }}>
                {
                    mediaUrls.map((url, index) => {
                        return (
                            <div key={`media-${index}`} style={{position: 'relative'}}
                                 className={'media-image-container'}>
                                <IconButton
                                    disableRipple
                                    className={'delete-button'}
                                    onClick={() => removeMedia(index)}
                                >
                                    <Close fontSize={'small'}/>
                                </IconButton>
                                <img src={url}/>
                            </div>
                        );
                    })
                }
                {
                    newMediaUrls.map((url, index) => {
                        return (
                            <div key={`media-${index}`} style={{position: 'relative'}}
                                 className={'media-image-container'}>
                                <IconButton
                                    disableRipple
                                    className={'delete-button'}
                                    onClick={() => removeNewMedia(index)}
                                >
                                    <Close fontSize={'small'}/>
                                </IconButton>
                                <img src={url}/>
                            </div>
                        );
                    })
                }
                {
                    mediaUrls.length + newMediaUrls.length < 9 &&
                    <div className={'media-image-container'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton
                            className={'add-media-button'}
                            onClick={openFileSelector}
                        >
                            <Add fontSize={'large'}/>
                        </IconButton>
                    </div>
                }
            </DialogContent>
            <InputLabel style={{paddingLeft: 20}}>* Puteti incarca maxim 9 poze</InputLabel>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em', color: 'black'}}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default CompanyMediaDialog;