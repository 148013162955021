import React, {useEffect, useLayoutEffect, useState} from 'react';
import '../JobListItem/jobListItem.scss';
import './applicationListItems.scss';
import {JobApplication} from "../../models/JobApplication";
import {NavLink} from "react-router-dom";
import {
    ApartmentRounded, CloseRounded,
    Engineering,
    LocationOnOutlined, MailOutline,
    PersonRounded
} from "@mui/icons-material";
import {ReactComponent as Arrow} from '../../images/svgs/next-arrow.svg';
import FullMonths from "../Constants/Months";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import DateUtil from "../Utils/DateUtil";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {ReactComponent as ClockSvg} from "../../images/svgs/clock.svg";
import {Button, IconButton, Tooltip, Zoom} from "@mui/material";
import {snackbarSlice} from "../../store/Snackbar";
import RejectionDialog from "./RejectionDialog";
import FirebaseUtil from "../Firebase/Firebase";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../App";
import {ReactComponent as CV} from '../../images/svgs/cv.svg';
import CoverLetterDialog from "./CoverLetterDialog";
import {Job} from "../../models/Job";
import {DialogUtil} from "../Utils/DialogUtil";

interface Props {
    jobApplication: JobApplication;
    resolveJobApplicationCallback: (jobApplicationId: string) => void;
    userJob?: Job;
}

const ApplicationListItem = ({jobApplication, resolveJobApplicationCallback, userJob}: Props) => {
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const companiesInformation = useSelector((s: ApplicationState) => s.data.companiesInformation);
    const job = !!company ? company?.jobs?.find(j => j.id === jobApplication.jobId)! : userJob;

    const dispatch = useDispatch();

    const [showRejectionDialog, setShowRejectionDialog] = useState<boolean>(false);
    const [showCoverLetterDialog, setShowCoverLetterDialog] = useState<boolean>(false);
    const [showDeleteJobDialog, setShowDeleteJobDialog] = useState<boolean>(false);

    console.log(companiesInformation);

    const rejectCandidate = async (message: string) => {
        if (!company)
            return;

        const rejectCandidate = httpsCallable(functions, 'rejectCandidate');
        await rejectCandidate({
            rejectionMessage: message,
            userEmail: jobApplication.user.email,
            jobName: job?.title,
            companyName: company!.companyName,
            jobApplicationId: jobApplication.id,
            userName: jobApplication.user.name,
        })
            .then((result) => {
                if ((result.data as any).status !== 'success') {
                    console.error((result.data as any).error);
                    dispatch(snackbarSlice.actions.show({
                        message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                        severity: 'error',
                    }))
                    return;
                }

                resolveJobApplicationCallback(jobApplication.id);
                dispatch(snackbarSlice.actions.show({
                    message: 'Aplicare respinsa!',
                    severity: 'success',
                }))
            });
        setShowRejectionDialog(false);
    }

    const deleteJobApplication = async (resolve: boolean) => {
        if (!resolve || !company) {
            setShowDeleteJobDialog(false);
            return;
        }
        await FirebaseUtil.write('JobApplications', jobApplication.id, {
            resolved: true,
            rejected: false,
        })

        resolveJobApplicationCallback(jobApplication.id);
        dispatch(snackbarSlice.actions.show({
            message: 'Aplicare dezactivata!',
            severity: 'success',
        }));
        setShowDeleteJobDialog(false);
    }

    const getCreationDate = () => {
        let createdAt = jobApplication.createdAt!;
        const myString = createdAt.getDate() + ' ' + FullMonths[createdAt.getMonth()] + ' ' + createdAt.getFullYear();
        return myString;
    }

    return (
        <div className={'list-item'}>
            {
                company && !jobApplication.resolved &&
                <IconButton
                    className={'delete-application-button'}
                    size={'small'}
                    onClick={() => setShowDeleteJobDialog(true)}
                >
                    <CloseRounded className={'delete-application-icon'}/>
                </IconButton>
            }
            <div className={'application-creation-date'}>
                {
                    getCreationDate()
                }
            </div>
            <NavLink
                className={'company-logo'}
                to={`/candidati/${jobApplication.user.id}`}>
                {
                    jobApplication.user.photoURL ?
                        <img src={jobApplication.user.photoURL}/> :
                        <PersonRounded className={'profile-icon'}/>
                }
            </NavLink>
            <div className={'job-body'} style={{alignItems: 'stretch', overflow: 'hidden'}}>
                <div className={'job-info-primary'}>
                    <div className={'company-name'} style={{cursor: 'default'}}>
                        {
                            // get most recent experience or add a title field in profile
                            // now they are being sorted when changed in user profile
                            jobApplication.user.experience && jobApplication.user.experience[0] &&
                            (jobApplication.user.experience[0].positionName + ' @ ' +
                                jobApplication.user.experience[0].companyName)
                        }
                    </div>
                    <NavLink
                        className={'job-name'}
                        to={`/candidati/${jobApplication.user.id}`}>
                        <h3>
                            {
                                jobApplication.user.name + (jobApplication.user.birthDate ? ' · ' + DateUtil.getAge(jobApplication.user.birthDate as any, !!company) + ' ani' : '')
                            }
                        </h3>
                    </NavLink>
                    <div className={'job-card-info-bottom application-card-info-bottom'}>
                        {
                            jobApplication.user.qualification &&
                            <div className={'job-type-container'} style={{marginRight: 16}}>
                                <Engineering className={'job-type-icon'} color={'primary'} style={{marginRight: 6}}/>
                                <div className={'job-type-name'}>
                                    {jobApplication.user.qualification}
                                </div>
                            </div>
                        }
                        {
                            !!company && jobApplication.user.cvDownloadUrl &&
                            <Tooltip title={'Descarca CV'}
                                     TransitionComponent={Zoom}
                                     arrow>
                                <div
                                    className={'job-badge card svg-hover-white'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: 0,
                                        padding: '6px 12px'
                                    }}
                                    onClick={() => {
                                        FirebaseUtil.download(jobApplication.user?.cvDownloadUrl!, `CV_${jobApplication.user?.name!.replace(' ', '_')}`)
                                    }}
                                >
                                    <CV
                                        className={'job-type-icon'}
                                        color={'primary'}
                                        style={{
                                            marginRight: 6,
                                            width: 20,
                                            height: 20
                                        }}/>
                                    {'CV'}
                                </div>
                            </Tooltip>
                        }
                    </div>

                </div>
            </div>
            <div className={'application-arrow'}>
                {
                    !!company && jobApplication.coverLetter && jobApplication.coverLetter.length > 0 &&
                    <Tooltip title={'Scrisoare de intenție'}
                             TransitionComponent={Zoom}
                             arrow>
                        <IconButton
                            style={{position: 'absolute', top: -24, left: '50%', transform: 'translateX(-50%)'}}
                            className={'new-job-button'}
                            onClick={() => {
                                setShowCoverLetterDialog(true)
                            }}
                        >
                            <MailOutline style={{width: 20, height: 20}}/>
                        </IconButton>
                    </Tooltip>
                }
                <Arrow className={'only-large'}/>
                <Arrow className={'rotate-down only-small'}/>
                {
                    ((!!job && !!company) || jobApplication.resolved) && <Button
                        className={'new-job-button reject-button'}
                        disabled={jobApplication.resolved}
                        style={jobApplication.resolved ? {
                            backgroundColor: "grey"
                        } : undefined}
                        onClick={() => {
                            setShowRejectionDialog(true);
                        }}
                    >
                        {!jobApplication.resolved ? 'Respinge' : (!!company ? (!!jobApplication.rejected ? 'Respins' : 'Dezactivat') : 'Inactiv')}
                    </Button>
                }
            </div>
            {
                !!job ?
                    <div className={'job-body'} style={{overflow: "hidden"}}>
                        <div className={'job-info-primary'} style={{marginRight: 0}}>
                            <NavLink
                                className={'company-name'}
                                to={`/companii/${job?.companyId}`}
                                style={{textAlign: 'end'}}
                            >
                                {
                                    !!company ? company!.companyName : companiesInformation.size > 0 ? companiesInformation.get(jobApplication.job.companyId)?.name : ''
                                }
                            </NavLink>
                            <NavLink
                                className={'job-name'}
                                to={`/joburi/${job?.id}`}
                                style={{textAlign: 'end'}}
                            >
                                <h3 style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {
                                        job?.title
                                    }
                                </h3>
                            </NavLink>
                            <div className={'job-card-info-bottom'} style={{justifyContent: 'flex-end'}}>
                                <div className={'job-type-container'} style={{paddingRight: 16}}>
                                    <LocationOnOutlined className={'job-type-icon'} color={'primary'}
                                                        style={{marginRight: 6}}/>
                                    <div className={'job-type-name'}>
                                        {job?.locationType!.toString().split(' ')[0]}
                                    </div>
                                </div>
                                <div className={'job-type-container'}>
                                    <ClockSvg className={'job-type-icon'}/>
                                    <div className={'job-type-name'}>
                                        {job?.jobType}
                                    </div>
                                </div>
                                <NavLink
                                    className={'job-badge card'}
                                    to={`/joburi?industry=${IndustriesEnum[(job?.industry as unknown) as keyof typeof IndustriesEnum]}`}
                                    style={{marginRight: 0, marginLeft: 16}}
                                >
                                    {job?.industry}
                                </NavLink>
                            </div>
                        </div>
                    </div> :
                    <div className={'job-body'} style={{overflow: "hidden"}}>
                        <div className={'job-info-primary'} style={{width: '100%'}}>
                            {
                                !!company ? <NavLink
                                    className={'company-name'}
                                    to={company ? `/companii/${company?.id}` : '#'}
                                    style={{textAlign: 'end'}}
                                >
                                    {
                                        company ? company.companyName : ''
                                    }
                                </NavLink> : <div style={{height: 22}}/>
                            }
                            <div className={'job-name disable-hover'} style={{textAlign: 'end'}}>
                                <h3>
                                    {
                                        'Job inexistent'
                                    }
                                </h3>
                            </div>
                        </div>
                    </div>
            }
            {
                !!job || !!company ?
                    <NavLink
                        className={'company-logo is-application-logo'}
                        style={{marginRight: 0, marginTop: 10}}
                        to={!!job ? `/joburi/${job?.id}` : `companii/${company?.id}`}>
                        {
                            !!company ? (company?.photoURL ?
                                    <img src={company.photoURL}/> :
                                    <ApartmentRounded className={'profile-icon'}/>) :
                                (companiesInformation.size > 0 ?
                                    companiesInformation.get(jobApplication.job.companyId)?.photoURL ?
                                        <img src={companiesInformation.get(jobApplication.job.companyId)!.photoURL}/> :
                                        <ApartmentRounded className={'profile-icon'}/> :
                                    null)
                        }
                    </NavLink> :
                    <div className={'company-logo is-application-logo'} style={{marginRight: 0, marginTop: 10}}>
                        <ApartmentRounded className={'profile-icon'}/>
                    </div>
            }
            {
                showRejectionDialog &&
                <RejectionDialog
                    closeCallback={() => setShowRejectionDialog(false)}
                    sendCallback={rejectCandidate}
                    userName={jobApplication.user!.name!}
                />
            }
            {
                showCoverLetterDialog &&
                <CoverLetterDialog
                    closeCallback={() => setShowCoverLetterDialog(false)}
                    coverLetter={jobApplication.coverLetter}
                    userName={jobApplication.user!.name!}
                />
            }
            {
                showDeleteJobDialog &&
                DialogUtil.confirm({
                        title: 'Dezactivare aplicare - ' + job?.title,
                        content: 'Ești sigur că dorești sa dezactivezi aceasta aplicare? Candidatul nu va fi notificat.',
                    },
                    true,
                    deleteJobApplication)
            }
        </div>
    );
}

export default ApplicationListItem;