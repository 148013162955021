import React, {useCallback, useEffect, useRef, useState} from 'react';
import './addJob.scss';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {useNavigate} from "react-router";
import {
    Autocomplete,
    Button,
    Checkbox, CircularProgress,
    Container, FormControlLabel, IconButton,
    InputLabel,
    MenuItem,
    Select, Slider,
    TextField,
} from "@mui/material";
import {CareerLevelEnum, Job, JobTypeEnum, LocationTypeEnum} from "../../models/Job";
import {v4} from 'uuid';
import {convertToRaw, EditorState} from "draft-js";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import {stateToHtml} from "../CompanyProfile/Dialogs/DescriptionDialog";
import FirebaseUtil from "../Firebase/Firebase";
import {snackbarSlice} from "../../store/Snackbar";
import {authenticationSlice} from "../../store/Authentication";
import MUIRichTextEditor from "mui-rte";
import {SkillLevelEnum} from "../Profile/Dialogs/SkillsDialog";
import {Add, Delete} from "@mui/icons-material";
import MotionHoc from "../MotionHoc";
import {geocode, GOOGLE_API_KEY} from "../../App";
import {useJsApiLoader} from "@react-google-maps/api";

const AddJobComponent = () => {
    const company = useSelector(((s: ApplicationState) => s.auth.company));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // if (!company) {
    //     navigate('/404', {replace: true});
    // }

    const [job, setJob] = useState<Job>({
        id: v4(),
        jobType: JobTypeEnum.none,
        description: '',
        careerLevel: [],
        languages: '',
        locationType: LocationTypeEnum.none,
        numberOfOpenings: 1,
        title: '',
        confidentialSalary: false,
        skills: [],
        industry: company?.industries![0] || IndustriesEnum.achizitii,
        address: null,
        companyId: company!.id!,
        active: true,
        createdAt: new Date(),
    });
    const [editorState, setEditorState] = useState<EditorState>();
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [jobAddress, setJobAddress] = useState<string>('');
    const [addressHasError, setAddressHasError] = useState(false);

    const handleChange = (value: any, key: string) => {
        const newJob: Job = {...job};
        newJob[key] = value;

        if(key === 'needLetter' && value === true){
            newJob['applyExternal'] = false;
        }
        if(key === 'applyExternal' && value === true){
            newJob['needLetter'] = false;
        }

        setJob(newJob);
    }

    const controls: string[] = [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'undo',
        'redo',
        'link',
        // 'media',
        'numberList',
        'bulletList',
    ];
    const websiteRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });


    useEffect(() => {
        const isTitleValid = job.title.trim().length > 0 && job.title.trim().length <= 50;
        const isJobTypeValid = !!job.jobType;
        const isCareerLevelValid = job.careerLevel.length > 0;
        const isOpeningsValid = !!job.numberOfOpenings && job.numberOfOpenings >= 1;
        const isLocationValid = !!job.locationType && (job.locationType === LocationTypeEnum.remote || (!!job.googleAddress && !addressHasError));
        const isDescriptionValid = job.description.trim().length > 8;
        const isSalaryValid = job.confidentialSalary || (!!job.salary && job.salary.length > 0);
        const areSkillsValid = job.skills.length === 0 || job.skills.every(skill => skill.label.length > 0);
        const isExternalApplyValid = !job.applyExternal || (!!job.externalUrl && websiteRegex.test(job.externalUrl));

        setIsFormValid(
            isTitleValid &&
            isJobTypeValid &&
            isCareerLevelValid &&
            isOpeningsValid &&
            isLocationValid &&
            isDescriptionValid &&
            isSalaryValid &&
            areSkillsValid &&
            isExternalApplyValid
        );
    }, [job, addressHasError]);

    useEffect(() => {
        if (editorState) {
            setJob({
                ...job,
                description: stateToHtml(editorState)
            });
        }
    }, [editorState]);

    useEffect(() => {
        setAddressHasError(!!job.googleAddress &&
            // !job.googleAddress.address_components.some(component => component.long_name.includes('Reșița') || component.long_name.includes('Resita')));
            !job.googleAddress.description.includes('Resita') && !job.googleAddress.description.includes('Reșița'));
    }, [job.googleAddress]);

    const ref = useRef({
        timeoutId: 0,
        cancel: false,
        clear: () => {
            if (ref.current.timeoutId) {
                clearTimeout(ref.current.timeoutId);
            }
            ref.current.timeoutId = 0;
        },
    });

    useEffect(() => {
        if (isLoaded && jobAddress !== job?.googleAddress?.description) {
            ref.current.clear();
            ref.current.timeoutId = setTimeout(() => {
                if (jobAddress.length < 3) {
                    setOptions([]);
                    return;
                }
                const autocompleteService = new google.maps.places.AutocompleteService();
                const request: google.maps.places.AutocompletionRequest = {
                    input: jobAddress,
                    types: ['address']
                };
                autocompleteService.getPlacePredictions(request, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        setOptions(results ?? []);
                    }
                });
            }, 200) as any;
        }
    }, [jobAddress, isLoaded]);

    const addJob = async () => {
        setIsLoading(true);
        // let caseSearchArray = job.title.toLowerCase().trim().split(' ');
        // caseSearchArray = [...caseSearchArray, ...company!.companyName!.toLowerCase().trim().split(' ')];
        try {
            let latitude, longitude;
            if (job.googleAddress && job.googleAddress?.description?.trim().length > 0) {
                await geocode.fromAddress(job.googleAddress!.description).then(
                    (response) => {
                        const {lat, lng}: google.maps.LatLngLiteral = response.results[0].geometry.location;
                        latitude = lat;
                        longitude = lng;
                    },
                    (error) => {
                        console.error('Address not found');
                    }
                );
            }

            await FirebaseUtil.write('Jobs', job.id, {
                companyId: job.companyId,
                id: job.id,
                title: job.title,
                jobType: job.jobType,
                careerLevel: job.careerLevel,
                locationType: job.locationType,
                salary: job.salary ?? '',
                confidentialSalary: job.confidentialSalary,
                description: job.description,
                industry: job.industry,
                skills: job.skills,
                languages: job.languages,
                numberOfOpenings: job.numberOfOpenings,
                address: job.googleAddress?.description ?? null,
                active: true,
                createdAt: new Date(),
                // caseSearch: caseSearchArray,
                latitude: latitude ?? null,
                longitude: longitude ?? null,
                applyExternal: job.applyExternal ?? false,
                externalUrl: job.externalUrl ?? '',
                needLetter: job.needLetter ?? false
            });
            dispatch(authenticationSlice.actions.setCompany({
                companyData: {
                    ...company!,
                    jobs: !!company!.jobs ? [job, ...company!.jobs] : [job]
                }
            }));
        } catch (e: any) {
            console.error(e.message)
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Va rugam incercati din nou.',
                severity: 'error'
            }));
        } finally {
            navigate('/companii/' + company!.id);
        }
    }

    return (
        <>
            <div className={'background-container'}/>
            <Container maxWidth={'lg'} className={'form-page-container'}>
                <div className={'page-title'}>Adaugă un job nou</div>
                <div className={'container-form'}>
                    <TextField
                        id={'title'}
                        variant={'standard'}
                        fullWidth
                        label={'Titlul jobului'}
                        required
                        value={job.title}
                        error={job.title?.length > 50}
                        helperText={job.title?.length > 50 ? 'Titlul poate contine maxim 50 caractere.' : undefined}
                        onChange={(e) => handleChange(e.target.value, 'title')}
                        className={'form-text-field'}
                    />
                    <div className={'form-row'}>
                        <div className={'form-text-field left'} style={{width: '100%'}}>
                            <InputLabel id={'jobType-label'} style={{fontSize: 12}}>Nivelul jobului *</InputLabel>
                            <Select
                                onChange={(e) => handleChange(e.target.value as JobTypeEnum, 'jobType')}
                                labelId={'jobType-label'}
                                required
                                fullWidth
                                value={job.jobType}
                                variant={'standard'}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            borderRadius: '0 0 20px 20px'
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={JobTypeEnum.fulltime}>{JobTypeEnum.fulltime}</MenuItem>
                                <MenuItem value={JobTypeEnum.parttime}>{JobTypeEnum.parttime}</MenuItem>
                                <MenuItem value={JobTypeEnum.internship}>{JobTypeEnum.internship}</MenuItem>
                                <MenuItem value={JobTypeEnum.contract}>{JobTypeEnum.contract}</MenuItem>
                                <MenuItem value={JobTypeEnum.voluntariat}>{JobTypeEnum.voluntariat}</MenuItem>
                            </Select>
                        </div>
                        <div className={'form-text-field right'} style={{width: '100%'}}>
                            <InputLabel id={'careerLevel-label'} style={{fontSize: 12}}>Experiență
                                *</InputLabel>
                            <Select
                                onChange={(e) => handleChange(e.target.value, 'careerLevel')}
                                labelId={'careerLevel-label'}
                                required
                                fullWidth
                                multiple
                                value={job.careerLevel}
                                renderValue={(value) => {
                                    return value.length + (value.length === 1 ? ' selecție' : ' selecții');
                                }}
                                variant={'standard'}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            borderRadius: '0 0 20px 20px'
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={CareerLevelEnum.noexperience}>{CareerLevelEnum.noexperience}</MenuItem>
                                <MenuItem value={CareerLevelEnum.entry}>{CareerLevelEnum.entry}</MenuItem>
                                <MenuItem value={CareerLevelEnum.middle}>{CareerLevelEnum.middle}</MenuItem>
                                <MenuItem value={CareerLevelEnum.senior}>{CareerLevelEnum.senior}</MenuItem>
                                <MenuItem value={CareerLevelEnum.executive}>{CareerLevelEnum.executive}</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className={'form-row'}>
                        <TextField
                            id={'numberOfOpenings'}
                            variant={'standard'}
                            type={'number'}
                            fullWidth
                            label={'Poziții disponibile'}
                            inputProps={{min: 1}}
                            error={job.numberOfOpenings < 1}
                            required
                            value={job.numberOfOpenings}
                            onChange={(e) => handleChange(e.target.value, 'numberOfOpenings')}
                            className={'form-text-field left'}
                        />
                        <div className={'form-text-field right'} style={{width: '100%'}}>
                            <InputLabel id={'locationType-label'} style={{fontSize: 12}}>Tipul jobului *</InputLabel>
                            <Select
                                onChange={(e) => handleChange(e.target.value, 'locationType')}
                                labelId={'locationType-label'}
                                required
                                fullWidth
                                value={job.locationType}
                                variant={'standard'}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            borderRadius: '0 0 20px 20px'
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={LocationTypeEnum.remote}>{LocationTypeEnum.remote}</MenuItem>
                                <MenuItem value={LocationTypeEnum.hibrid}>{LocationTypeEnum.hibrid}</MenuItem>
                                <MenuItem value={LocationTypeEnum.onsite}>{LocationTypeEnum.onsite}</MenuItem>
                            </Select>
                        </div>
                    </div>
                    {
                        job.locationType !== LocationTypeEnum.remote && job.locationType !== LocationTypeEnum.none &&
                        <Autocomplete
                            value={job.googleAddress ?? null}
                            onChange={(e, v) => {
                                handleChange(v, 'googleAddress');
                                setJobAddress(v ? v.description : '')
                            }}
                            onInputChange={(event, v) => {
                                setJobAddress(v);
                            }}
                            noOptionsText={'Nicio adresa gasita'}
                            getOptionLabel={(option) => option.description}
                            filterOptions={(x) => x}
                            includeInputInList
                            autoComplete
                            filterSelectedOptions
                            fullWidth
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        label={'Adresa jobului'}
                                        fullWidth
                                        required
                                        error={addressHasError}
                                        helperText={addressHasError ? 'Adresa job-ului nu este in Reșița' : ''}
                                        variant={'standard'}
                                        className={'form-text-field'}
                                    />
                                );
                            }}
                            options={options}
                            componentsProps={{
                                paper: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}/>
                    }
                    <div className={'solid'}/>
                    <div className={'form-row editor-form-row'}>
                        <div className={'rich-text-editor-container'}>
                            <InputLabel id={'locationType-label'} style={{fontSize: 12, paddingBottom: 6}}>Descrierea
                                jobului *</InputLabel>
                            <MUIRichTextEditor
                                label={'Adaugă o scurtă descriere a ofertei de recrutare, detalii despre cerințele pe care trebuie sa le indeplineasca aplicanții' +
                                ', precum și informații despre bonusurile si beneficiile oferite unui potențial angajat.'}
                                defaultValue={''}
                                onChange={(newState) => setEditorState(newState)}
                                controls={controls}
                            />
                        </div>
                    </div>
                    <div className={'form-row'} style={{justifyContent: 'start'}}>
                        <TextField
                            id={'salary'}
                            variant={'standard'}
                            type={'number'}
                            fullWidth
                            label={'Salariu'}
                            placeholder={'Ex: 2400'}
                            required={!job.confidentialSalary}
                            value={job.salary}
                            disabled={job.confidentialSalary}
                            onChange={(e) => handleChange(e.target.value, 'salary')}
                            className={'form-text-field left'}
                        />
                        <div className={'form-text-field'}
                             style={{
                                 marginBottom: 0,
                                 display: 'flex',
                                 flexDirection: 'row',
                                 alignItems: 'flex-end',
                                 width: '100%'
                             }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={job.confidentialSalary}
                                        onChange={(e) => {
                                            handleChange(e.target.checked, 'confidentialSalary')
                                        }}
                                    />}
                                label={'Confidențial'}/>
                        </div>
                    </div>
                    <div className={'form-text-field width-50-large left'}>
                        <InputLabel id={'industry-label'} style={{fontSize: 12}}>Domeniu *</InputLabel>
                        <Select
                            onChange={(e) => handleChange(e.target.value as IndustriesEnum, 'industry')}
                            labelId={'industry-label'}
                            required
                            fullWidth
                            value={job.industry}
                            disabled={company?.industries!.length === 1}
                            variant={'standard'}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        borderRadius: '0 0 20px 20px'
                                    }
                                }
                            }}
                        >
                            {
                                company?.industries!.map(industry => {
                                    return <MenuItem key={industry.toString()} value={industry}>{industry}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                    <div className={'solid'}/>
                    <div className={'skills-container form-text-field'}>
                        <InputLabel id={'locationType-label'} style={{fontSize: 12, paddingBottom: 6}}>Aptitudini
                            necesare</InputLabel>
                        {
                            job.skills.length > 0 ? job.skills.map((skill, index) => {
                                return (
                                    <div key={'skill-' + index}>
                                        <div className={'labels-row'}>
                                            <div style={{fontSize: 14, width: '50%', marginRight: 5}}>
                                                Denumire *
                                            </div>
                                            <div className={'skill-level-large'} style={{fontSize: 14}}>
                                                {'Nivel - ' + skill.content}
                                            </div>
                                        </div>
                                        <div className={'form-row'}>
                                            <TextField
                                                onChange={(e) => {
                                                    const newSkills = [...job.skills];
                                                    newSkills[index] = {
                                                        label: e.target.value,
                                                        content: skill.content
                                                    }
                                                    setJob({
                                                        ...job,
                                                        skills: newSkills
                                                    })
                                                }}
                                                placeholder={'Ex: C++'}
                                                type={'text'}
                                                fullWidth
                                                value={skill.label}
                                                variant={'standard'}
                                                className={'form-text-field left'}
                                                style={{flex: 1, height: 40}}
                                            />
                                            <div className={'form-text-field right fullwidth-mobile'} style={{flex: 1}}>
                                                <div className={'skill-level-small'} style={{fontSize: 14}}>
                                                    {'Nivel - ' + skill.content}
                                                </div>
                                                <Slider
                                                    key={`slider-${index}`}
                                                    className={'skill-slider color-' + SkillLevelEnum[skill.content]}
                                                    style={{height: 40, paddingTop: 0}}
                                                    step={null}
                                                    value={SkillLevelEnum[skill.content] as any}
                                                    marks={[
                                                        {
                                                            value: 10,
                                                        },
                                                        {
                                                            value: 30,
                                                        },
                                                        {
                                                            value: 50,
                                                        },
                                                        {
                                                            value: 70,
                                                        },
                                                        {
                                                            value: 90,
                                                        },
                                                    ]}
                                                    track={false}
                                                    onChange={(e, value) => {
                                                        const newSkills = [...job.skills];
                                                        newSkills[index] = {
                                                            label: skill.label,
                                                            content: SkillLevelEnum[value as any]
                                                        }
                                                        setJob({
                                                            ...job,
                                                            skills: newSkills
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div style={{
                                                height: 80,
                                                width: 40,
                                                paddingLeft: window.innerWidth < 768 ? 0 : 10
                                            }}>
                                                <IconButton onClick={() => {
                                                    const newJob = {
                                                        ...job,
                                                        skills: job.skills.filter((skill, i) => i !== index)
                                                    };
                                                    setJob(newJob)
                                                }}>
                                                    <Delete/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : null
                        }
                        {
                            job.skills.length < 7 &&
                            <Button onClick={() => setJob(
                                {
                                    ...job,
                                    skills: [...job.skills, {
                                        label: '',
                                        content: 'Mediu'
                                    }]
                                })} style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                {
                                    <>
                                        <Add color={'primary'} fontSize={'small'}/>
                                        <span>Adaugă aptitudine</span>
                                    </>
                                }
                            </Button>
                        }
                    </div>
                    <div className={'form-text-field'}>
                        <TextField
                            id={'languages'}
                            variant={'standard'}
                            fullWidth
                            label={'Limbi vorbite'}
                            placeholder={'Ex: Romana, Engleza'}
                            value={job.languages}
                            onChange={(e) => handleChange(e.target.value, 'languages')}
                            style={{marginBottom: 10}}
                        />
                        <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Noteaza limbile pe care candidatul
                            trebuie sa le cunoasca, separate prin virgula</InputLabel>
                    </div>
                    <div className={'form-text-field'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={job.applyExternal ?? false}
                                    onChange={(e) => {
                                        handleChange(e.target.checked, 'applyExternal')
                                    }}
                                />}
                            label={'Aplicare externa'}/>
                        <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Candidatul va fi redirecționat
                            catre un site extern pentru a finaliza aplicarea </InputLabel>
                    </div>
                    {
                        job.applyExternal &&
                        <TextField
                            id={'externalUrl'}
                            variant={'standard'}
                            fullWidth
                            label={'URL aplicare externa'}
                            placeholder={'Ex: https://example.com/aplicare'}
                            required
                            value={job.externalUrl ?? ''}
                            error={!!job.externalUrl && job.externalUrl.length > 0 && !websiteRegex.test(job.externalUrl)}
                            helperText={!!job.externalUrl && job.externalUrl.length > 0 && !websiteRegex.test(job.externalUrl) ? 'Introduceți un url valid.' : undefined}
                            onChange={(e) => handleChange(e.target.value, 'externalUrl')}
                            className={'form-text-field'}
                        />
                    }
                    <div className={'form-text-field'}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={job.needLetter ?? false}
                                    onChange={(e) => {
                                        handleChange(e.target.checked, 'needLetter')
                                    }}
                                />}
                            label={'Scrisoare de intenție'}/>
                        <InputLabel id={'languages-label'} style={{fontSize: 12}}>* Candidatul va trebui sa completeze o scrisoare de intenție
                            cand va aplica pentru acest job </InputLabel>
                    </div>
                    <div className={'button-container'}>
                        {
                            !isLoading ?
                                <Button style={!isFormValid ? {
                                        color: 'rgba(0,0,0,0.6)',
                                        backgroundColor: 'rgba(0,0,0,0.2)',
                                        padding: '7.5px 12.5px',
                                        borderRadius: 12,
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em'
                                    } :
                                    {
                                        color: 'white',
                                        backgroundColor: 'var(--dark-primary)',
                                        padding: '7.5px 12.5px',
                                        borderRadius: 12,
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em'
                                    }}
                                        onClick={addJob}
                                        disabled={!isFormValid}
                                >
                                    Adaugă job
                                </Button> :
                                <CircularProgress size={30} style={{marginRight: 20}}/>
                        }
                    </div>
                </div>
            </Container>
        </>
    );
}

const AddJob = MotionHoc(AddJobComponent);
export default AddJob;