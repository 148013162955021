import React, {useEffect, useState} from 'react';
import CardHeader from "../Profile/Cards/CardHeader";
import {LabelContentItem} from "../Profile/Profile";
import JobInformationDialog from "./Dialogs/JobInformationDialog";
import {Job} from "../../models/Job";

interface Props {
    rows: LabelContentItem[];
    title: string;
    subtitle?: string;
    isOwnProfile: boolean;
    job: Job;
}

const JobInformationCard = (props: Props) => {
    const [notNullRows, setNotNullRows] = useState<LabelContentItem[]>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        if (props.rows) {
            var rows = props.rows.filter(row => row.content !== null && row.content !== undefined);
            setNotNullRows(rows);
        }
    }, [props.rows]);

    return (
        <div className={'information-card rounded'}>
            <CardHeader
                isOwnProfile={props.isOwnProfile}
                editCallback={() => setShowDialog(true)}
                title={props.title}
                subtitle={props.subtitle}
                job={props.job}
            />
            <div className={'solid'}/>
            {
                notNullRows.length === 0 &&
                <div>
                    Nicio informatie. Adaugă informații folosind butonul de editare.
                </div>
            }
            {
                notNullRows?.map((row, index) => {
                    return row.content ? (
                        <div key={`information-row-${index}`} className={'information-row'}>
                            <div className={'label'}>{row.label + ':'}</div>
                            <div className={'content'}>{row.content}</div>
                        </div>
                    ) : null;
                })
            }
            {
                showDialog && <JobInformationDialog
                    open={true}
                    closeCallback={() => setShowDialog(false)}
                    job={props.job}
                />
            }
        </div>
    );
}

export default JobInformationCard;