import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, InputLabel,
    TextField
} from "@mui/material";
import {ApplicationState} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import '../../Profile/profile.scss';
import FirebaseUtil from "../../Firebase/Firebase";
import {snackbarSlice} from "../../../store/Snackbar";
import {defaultRejectionMessage, defaultThanksForApplyingMessage} from "../../defaults";

interface DialogProps {
    open: boolean;
    closeCallback: () => void;
}

export enum IndustriesEnum {
    achizitii = 'Achizitii' as any,
    administratie = 'Administratie' as any,
    agricultura = 'Agricultura' as any,
    amenajari = 'Amenajari' as any,
    aprovizionare = 'Aprovizionare' as any,
    arhitectura = 'Arhitectura' as any,
    arta = 'Arta' as any,
    asigurari = 'Asigurari' as any,
    asociatii = 'Asociatii' as any,
    aupair = 'Au Pair' as any,
    audit = 'Audit' as any,
    automotive = 'Automotive' as any,
    aviatie = 'Aviatie' as any,
    b2b = 'B2B' as any,
    babysitter = 'Babysitter' as any,
    backoffice = 'Back Office' as any,
    bancar = 'Bancar' as any,
    biochimie = 'Biochimie' as any,
    calitate = 'Calitate' as any,
    cercetaredepiata = 'Cercetare de piata' as any,
    cercetaredezvoltare = 'Cercetare si dezvoltare' as any,
    chimie = 'Chimie' as any,
    comert = 'Comert' as any,
    constructii = 'Constructii' as any,
    consultanta = 'Consultanta' as any,
    contabilitate = 'Contabilitate' as any,
    copywriting = 'Copywriting' as any,
    cultura = 'Cultura' as any,
    curatenie = 'Curatenie' as any,
    designtehnic = 'Design tehnic' as any,
    educatie = 'Educatie' as any,
    electricitate = 'Electricitate' as any,
    electronice = 'Electronice' as any,
    energie = 'Energie' as any,
    entertainment = 'Entertainment' as any,
    farmaceutic = 'Farmaceutic' as any,
    financiar = 'Financiar' as any,
    fundatii = 'Fundatii' as any,
    helpDesk = 'Helpdesk' as any,
    hostess = 'Hostess' as any,
    hotelier = 'Hotelier' as any,
    ithardware = 'IT Hardware' as any,
    itsoftware = 'IT Software' as any,
    it = 'IT' as any,
    imobiliare = 'Imobiliare' as any,
    industriiextractive = 'Industrii extractive' as any,
    infrumusetare = 'Infrumusetare' as any,
    inginerie = 'Inginerie' as any,
    ingrijire = 'Ingrijire' as any,
    instalatii = 'Instalatii' as any,
    institutiifinanciare = 'Institutii Financiare' as any,
    intretinere = 'Intretinere' as any,
    juridic = 'Juridic' as any,
    logistic = 'Logistic' as any,
    mlm = 'MLM' as any,
    managementnaval = 'Management Naval' as any,
    management = 'Management' as any,
    marketing = 'Marketing' as any,
    mecanica = 'Mecanica' as any,
    media = 'Media' as any,
    medicina = 'Medicina' as any,
    medicinaalternativa = 'Medicina Alternativa' as any,
    medicinaveterinara = 'Medicina Veterinara' as any,
    menaj = 'Menaj' as any,
    naval = 'Naval' as any,
    newmedia = 'New Media' as any,
    ong = 'ONG' as any,
    pr = 'PR' as any,
    piscicultura = 'Piscicultura' as any,
    productie = 'Productie' as any,
    projectmanagement = 'Project management' as any,
    promoteri = 'Promoteri' as any,
    protectiamediului = 'Protectia mediului' as any,
    protectiamuncii = 'Protectia muncii' as any,
    protectiacopilului = 'Protectia copilului' as any,
    pazasiprotectie = 'Protectie si Paza' as any,
    psihologie = 'Psihologie' as any,
    publicitate = 'Publicitate' as any,
    restaurante = 'Restaurante' as any,
    resurseumane = 'Resurse umane' as any,
    retail = 'Retail' as any,
    sanatate = 'Sanatate' as any,
    secretariat = 'Secretariat' as any,
    service = 'Service' as any,
    seviciuclienti = 'Serviciu clienti' as any,
    silvicultura = 'Silvicultura' as any,
    soferi = 'Soferi' as any,
    sport = 'Sport' as any,
    stomatologie = 'Stomatologie' as any,
    suport = 'Suport' as any,
    telecomunicatii = 'Telecomunicatii' as any,
    traduceri = 'Traduceri' as any,
    training = 'Training' as any,
    transport = 'Transport' as any,
    turism = 'Turism' as any,
    vanzari = 'Vanzari' as any,
    webdesign = 'Web Design' as any,

}

const controls: string[] = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'undo',
    'redo',
    'link',
];

const CompanyProfileDialog = (props: DialogProps) => {
    const company = useSelector((s: ApplicationState) => s.auth.company)

    const [email, setEmail] = useState<string>(company?.contactEmail!);
    const [name, setName] = useState<string>(company?.companyName!);
    const [phone, setPhone] = useState<string>(company?.phoneNumber ?? '');
    const [isValidCompany, setIsValidCompany] = useState<boolean>(!!company?.isValidCompany);
    const [cityAddress, setCityAddress] = useState<string>(company?.cityAddress ?? '');
    const [industries, setIndustries] = useState<IndustriesEnum[] | undefined>(company?.industries);
    const [website, setWebsite] = useState<string>(company?.website ?? '');

    const [rejectionEmail, setRejectionEmail] = useState<string>(company?.rejectionEmail ?? defaultRejectionMessage);
    const [applyEmail, setApplyEmail] = useState<string>(company?.applyEmail ?? defaultThanksForApplyingMessage);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const websiteRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const dispatch = useDispatch();

    useEffect(() => {
        const isEmailValid = re.test(email);
        const isNameValid = name.length > 0;
        const isIndustriesValid = !!industries && industries.length > 0;
        const isWebsiteValid = website.length === 0 || websiteRegex.test(website);
        const companyValid = !isValidCompany || cityAddress.length > 0;
        const emailMessagesValid = rejectionEmail.length > 0 && applyEmail.length > 0;

        setIsFormValid(isEmailValid && isNameValid && isIndustriesValid && isWebsiteValid && companyValid && emailMessagesValid);
    }, [email, name, industries, website, isValidCompany, cityAddress, rejectionEmail, applyEmail]);

    const saveCallback = async () => {
        setIsLoading(true);
        await FirebaseUtil.write('Companies', company!.id!,
            {
                contactEmail: email.trim(),
                companyName: name.trim(),
                phoneNumber: phone.trim(),
                isValidCompany: !!isValidCompany,
                cityAddress: cityAddress?.trim(),
                website: website.trim(),
                industries: industries,
                rejectionEmail: rejectionEmail,
                applyEmail: applyEmail
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        ).finally(() => {
            props.closeCallback()
        });
    }

    return (
        <Dialog
            open={props.open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick')
                    return;
                props.closeCallback();
            }}
            className={'form-dialog'}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>Editează datele companiei</DialogTitle>
            <DialogContent style={{paddingTop: 5}}>
                <TextField
                    required
                    label={'Email de contact'}
                    type={'email'}
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant={'standard'}
                    className={'form-text-field'}
                    error={!re.test(email)}
                />
                <TextField
                    required
                    onChange={(e) => setName(e.target.value)}
                    label={'Numele companiei'}
                    type={'text'}
                    disabled
                    fullWidth
                    value={name}
                    variant={'standard'}
                    className={'form-text-field'}
                    style={{flex: 1}}
                    error={!name.length}
                />
                <Autocomplete
                    multiple
                    getOptionDisabled={(option) => !!industries && industries.length >= 3}
                    value={industries}
                    onChange={(e, v) => {
                        setIndustries(v as IndustriesEnum[]);
                    }}
                    fullWidth
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label={'Domeniu'}
                                required
                                error={!industries || industries.length === 0}
                                variant={'standard'}
                                className={'form-text-field'}

                            />
                        );
                    }}
                    options={Object.values(IndustriesEnum).filter(val => (val as string)[0] === (val as string)[0].toUpperCase())}
                    getOptionLabel={(option) => {
                        return option.toString();
                    }}
                    componentsProps={{
                        paper: {
                            style: {
                                borderRadius: '0 0 20px 20px'
                            }
                        }
                    }}/>
                <div className={'form-row'}>
                    <TextField
                        onChange={(e) => setWebsite(e.target.value)}
                        label={'Website'}
                        type={'text'}
                        fullWidth
                        value={website}
                        variant={'standard'}
                        className={'form-text-field left'}
                        error={website.length > 0 && !websiteRegex.test(website)}
                    />
                    <TextField
                        onChange={(e) => setPhone(e.target.value)}
                        label={'Număr de telefon'}
                        type={'tel'}
                        fullWidth
                        value={phone}
                        variant={'standard'}
                        className={'form-text-field right'}
                    />
                </div>
                <div className={'form-text-field'}
                     style={{marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isValidCompany}
                                onChange={(e) => {
                                    setIsValidCompany(e.target.checked);
                                }}
                            />}
                        label={'Punct de lucru in Resita'}/>
                </div>
                <div className={'form-row'}>
                    <TextField
                        onChange={(e) => setApplyEmail(e.target.value)}
                        label={'Mesaj aplicare job email'}
                        required
                        type={'text'}
                        fullWidth
                        value={applyEmail}
                        variant={'standard'}
                        className={'form-text-field'}
                        multiline
                        minRows={3}
                    />
                </div>
                <div className={'form-row'}>
                    <TextField
                        onChange={(e) => setRejectionEmail(e.target.value)}
                        label={'Mesaj respingere email'}
                        required
                        type={'text'}
                        fullWidth
                        value={rejectionEmail}
                        variant={'standard'}
                        className={'form-text-field'}
                        multiline
                        minRows={3}
                    />
                </div>
                {
                    isValidCompany && <TextField
                        required
                        label={'Adresa punctului de lucru din Resita'}
                        type={'text'}
                        fullWidth
                        value={cityAddress}
                        onChange={(e) => setCityAddress(e.target.value)}
                        variant={'standard'}
                        className={'form-text-field'}
                    />
                }
            </DialogContent>
            <DialogActions>
                {
                    !isLoading ? <>
                            <Button disabled={isLoading} onClick={props.closeCallback}
                                    style={{
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.06em',
                                        color: 'black'
                                    }}>Anulează</Button>
                            <Button disabled={!isFormValid || isLoading} onClick={saveCallback}
                                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}>
                                Salvează
                            </Button>
                        </> :
                        <CircularProgress size={30} style={{marginRight: 20}}/>
                }
            </DialogActions>
        </Dialog>
    );
}

export default CompanyProfileDialog;