import React, {useEffect, useState} from 'react';
import {reauthenticateWithCredential, updatePassword, EmailAuthProvider} from "firebase/auth";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import './settings.scss';
import FirebaseUtil from "../Firebase/Firebase";
import {auth} from "../../App";
import {snackbarSlice} from "../../store/Snackbar";

interface Props {
    expanded: boolean;
    onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    collapse: () => void;
}

const PasswordAccordion = (props: Props) => {
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const company = useSelector((s: ApplicationState) => s.auth.company);
    const isCompany = !!company;

    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');

        setPasswordError('');
        setNewPasswordError('');
        setConfirmPasswordError('');
    }, [props.expanded]);

    const changeAccountPassword = async () => {
        if (isLoading)
            return;

        setIsLoading(true);
        setPasswordError('');
        setNewPasswordError('');
        setConfirmPasswordError('');
        let hasError = false;

        if (newPassword.length < 6) {
            setNewPasswordError('Parola trebuie sa aiba minim 6 caractere');
            hasError = true;
        }

        if (newPassword !== confirmPassword) {
            setConfirmPasswordError('Parolele nu coincid');
            hasError = true;
        }

        if (hasError) {
            setIsLoading(false);
            return;
        }

        const currentEmail = auth.currentUser?.email;
        const credential = EmailAuthProvider.credential(currentEmail!, password);
        try {
            await reauthenticateWithCredential(auth.currentUser!, credential);
        } catch (e: any) {
            dispatch(snackbarSlice.actions.show({
                message: 'Parola incorecta',
                severity: 'error',
            }));
            setPassword('');
            setNewPassword('');
            setConfirmPassword('');

            setPasswordError('');
            setNewPasswordError('');
            setConfirmPasswordError('');
            setIsLoading(false);
            return;
        }

        await updatePassword(auth.currentUser!, newPassword);
        dispatch(snackbarSlice.actions.show({
            message: 'Parola schimbata cu succes!',
            severity: 'success',
        }));
        props.collapse();
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setIsLoading(false);
    }

    return (
        <Accordion
            expanded={props.expanded}
            onChange={!isLoading ? props.onChange('password') : () => {
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowDownward/>}
                aria-controls="panel1a-content"
            >
                <div>
                    <h3>Parola</h3>
                    <p className={'grey-subtitle'}>
                        Schimbă parola contului
                    </p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                    variant={'standard'}
                    label={'Parola curenta'}
                    fullWidth
                    type={'password'}
                    className={'accordion-text-field'}
                    value={password}
                    error={passwordError.length > 0}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        setPasswordError('');
                    }}
                />
                {
                    passwordError.length > 0 &&
                    <p className={'error-paragraph'} style={{color: 'red', fontSize: 14}}>
                        {passwordError}
                    </p>
                }
                <TextField
                    variant={'standard'}
                    label={'Noua parolă'}
                    fullWidth
                    type={'password'}
                    className={'accordion-text-field'}
                    value={newPassword}
                    error={newPasswordError.length > 0}
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                        setNewPasswordError('');
                    }}
                />
                {
                    newPasswordError.length > 0 &&
                    <p className={'error-paragraph'} style={{color: 'red', fontSize: 14}}>
                        {newPasswordError}
                    </p>
                }
                <TextField
                    variant={'standard'}
                    label={'Confirmă noua parolă'}
                    fullWidth
                    type={'password'}
                    className={'accordion-text-field'}
                    value={confirmPassword}
                    error={confirmPasswordError.length > 0}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setConfirmPasswordError('');
                    }}
                />
                {
                    confirmPasswordError.length > 0 &&
                    <p className={'error-paragraph'} style={{color: 'red', fontSize: 14}}>
                        {confirmPasswordError}
                    </p>
                }
            </AccordionDetails>
            <AccordionActions>
                <Button
                    style={{textTransform: 'uppercase', letterSpacing: '0.06em'}}
                    onClick={changeAccountPassword}
                >
                    Salvează
                </Button>
            </AccordionActions>
        </Accordion>
    );
}

export default PasswordAccordion;