import {
    collection,
    doc,
    getDoc,
    setDoc,
    getDocs,
    where,
    query,
    deleteDoc,
    orderBy,
    limit,
    startAt,
    endAt
} from 'firebase/firestore'
import {db, storage} from "../../App";
import firebase from "firebase/compat";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import Compress from 'browser-image-compression';

const FirebaseUtil = {
    write: async (collectionName: string, docName: string, data: Partial<firebase.firestore.DocumentData>, merge?: boolean) => {
        if (merge === undefined || merge === null)
            merge = true;

        const collectionRef = collection(db, collectionName);
        await setDoc(doc(collectionRef, docName), data, {merge: merge});
    },
    read: async (collectionName: string, docName: string) => {
        const docRef = doc(db, collectionName, docName);
        const docSnap = await getDoc(docRef);

        return docSnap.data();
    },
    findDoc: async (collectionName: string, docName: string) => {
        return await getDoc(doc(db, collectionName, docName));
    },
    getDocs: async (collectionName: string) => {
        const collectionRef = collection(db, collectionName);
        const collectionSnap = await getDocs(collectionRef);

        return collectionSnap.docs;
    },
    getDocsWithCondition: async (collectionName: string, ...args: { fieldPath: string | firebase.firestore.FieldPath, opStr: firebase.firestore.WhereFilterOp, value: unknown }[]) => {
        const collectionRef = collection(db, collectionName);
        let q = query(collectionRef);
        args.forEach(arg => {
            q = query(q, where(arg.fieldPath, arg.opStr, arg.value));
        });
        return await getDocs(q);
    },
    getDocsInOrder: async (collectionName: string, orderByField: string, ascending: boolean, maxNumberOfItems?: number) => {
        const collectionRef = collection(db, collectionName);
        let q = query(collectionRef, orderBy(orderByField, ascending ? 'asc' : 'desc'));
        if (maxNumberOfItems) {
            q = query(q, limit(maxNumberOfItems));
        }

        return await getDocs(q);
    },
    getDocsInOrderWithCondition: async (collectionName: string,
                                        orderByField: string | undefined,
                                        ascending: boolean,
                                        maxNumberOfItems?: number,
                                        start?: number, end?: number,
                                        ...args: { fieldPath: string | firebase.firestore.FieldPath, opStr: firebase.firestore.WhereFilterOp, value: unknown }[]) => {
        const collectionRef = collection(db, collectionName);
        let q = query(collectionRef);
        args.forEach(arg => {
            q = query(q, where(arg.fieldPath, arg.opStr, arg.value));
        });
        if (orderByField) {
            q = query(q, orderBy(orderByField, ascending ? 'asc' : 'desc'));
        }
        if (maxNumberOfItems) {
            q = query(q, limit(maxNumberOfItems));
        }

        return await getDocs(q);
    },
    uploadFile: async (path: string, file: any, isImage: boolean = true): Promise<string> => {
        let url = '';
        const storageRef = ref(storage, path);

        const compressOptions = {
            maxSizeMb: 1,
            useWebWorker: true,
        }
        let compressedBlobFile: File | undefined = undefined;
        if(isImage)
            compressedBlobFile = await Compress(file, compressOptions);

        const uploadTask = uploadBytesResumable(storageRef, isImage && !!compressedBlobFile && compressedBlobFile?.size < file.size ? compressedBlobFile : file);
        await uploadTask.then(async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                url = downloadURL;
            });
        });

        return url;
    },
    download: async (url: string, fileName: string) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
            const csvURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', fileName);
            tempLink.click();
        };
        xhr.open('GET', url);
        xhr.send();
    },
    deleteDoc: async (collectionName: string, docName: string) => {
        await deleteDoc(doc(db, collectionName, docName));
    },
    deleteDocs: async (collectionName: string, ...args: { fieldPath: string | firebase.firestore.FieldPath, opStr: firebase.firestore.WhereFilterOp, value: unknown }[]) => {
        const colRef = collection(db, collectionName);
        let q = query(colRef);
        args.forEach(arg => {
            q = query(q, where(arg.fieldPath, arg.opStr, arg.value));
        });
        const querySnapshot = await getDocs(q);
        await Promise.all(querySnapshot.docs.map(async (d) =>
            await deleteDoc(doc(db, collectionName, d.id))
        ));
    }
}

export default FirebaseUtil;