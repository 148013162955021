import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import MotionHoc from "../MotionHoc";
import {NavLink, useParams} from "react-router-dom";
import {Job, LocationTypeEnum} from "../../models/Job";
import FirebaseUtil from "../Firebase/Firebase";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Chip, Container, Tooltip, Zoom} from "@mui/material";
import './jobDetails.scss'
import {ApartmentRounded, Language, LocationOn, Phone} from "@mui/icons-material";
import {CompanyDetails} from "../../store/Authentication";
import SkillsInformationCard from "../Profile/Cards/SkillsInformationCard";
import JobInformationCard from "./JobInformationCard";
import {LabelContentItem} from "../Profile/Profile";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {db, geocode, GOOGLE_API_KEY} from "../../App";
import {ApplicationState} from "../../store";
import DescriptionInformationCard from "../CompanyProfile/Cards/DescriptionInformationCard";
import {snackbarSlice} from "../../store/Snackbar";
import {doc, onSnapshot} from "firebase/firestore";
import {IndustriesEnum} from "../CompanyProfile/Dialogs/CompanyProfileDialog";
import Email from "@mui/icons-material/Email";

const mapOptions: google.maps.MapOptions =
    {
        streetViewControl: false,
        mapTypeControl: false,
        styles: [{
            "featureType": "all",
            "elementType": "labels.text",
        }],
    }
const JobDetailsComponent = () => {
    let {id} = useParams();

    const company = useSelector((s: ApplicationState) => s.auth.company);
    const user = useSelector((s: ApplicationState) => s.auth.user);
    const isLoggedIn = !!company || !!user;
    const [isOwnProfile, setIsOwnProfile] = useState(false);

    const [job, setJob] = useState<Job | null | undefined>();
    const [companyProfile, setCompanyProfile] = useState<CompanyDetails | undefined>();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [jobDetailRows, setJobDetailRows] = useState<LabelContentItem[]>([]);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
        libraries: ['places']
    });

    useLayoutEffect(() => {
        if (job && job.address && job.latitude && job.longitude) {
            setCenter({lat: job.latitude, lng: job.longitude})
        }
    }, [map, job])

    useEffect(() => {
        function onResize() {
            const div = document.getElementById('reverse-div');
            const width = window.innerWidth;
            setWindowWidth(width);
            const margin = width > 1536 ? width - 1536 : 0;
            if (div) {
                if (width > 768)
                    div.style.right = 'calc(7.5vw + ' + margin / 2 + 'px)';
                else
                    div.style.right = '';
            }
        }

        onResize();
        window.addEventListener('resize', onResize);
    }, [companyProfile, job]);

    useEffect(() => {
        if (id) {
            const unsubscribeFromUserChanges = onSnapshot(doc(db, 'Jobs', id.toString()), async (doc) => {
                FirebaseUtil.read('Jobs', id!.toString()).then((data) => {
                    if (data) {
                        setJob({
                            id: data.id,
                            jobType: data.jobType,
                            description: data.description,
                            industry: data.industry,
                            languages: data.languages,
                            careerLevel: data.careerLevel,
                            numberOfOpenings: data.numberOfOpenings,
                            locationType: data.locationType,
                            salary: data.salary,
                            skills: data.skills,
                            confidentialSalary: data.confidentialSalary,
                            title: data.title,
                            address: data.address,
                            active: data.active,
                            createdAt: data.createdAt,
                            companyId: data.companyId,
                            latitude: data.latitude,
                            longitude: data.longitude,
                            applyExternal: data.applyExternal,
                            externalUrl: data.externalUrl,
                            needLetter: data.needLetter
                        });
                        FirebaseUtil.read('Companies', data.companyId).then(company => {
                            if (!company?.active)
                                navigate('/404', {replace: true})
                            setCompanyProfile(company);
                        })
                        setIsOwnProfile(!!company && data.companyId === company.id);
                    } else {
                        navigate('/404', {replace: true});
                    }
                });
            });
            return unsubscribeFromUserChanges;
        }
    }, [id]);

    useEffect(() => {
        if (job) {
            setJobDetailRows([
                {
                    label: 'Domeniu',
                    content: <Chip
                        style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                        label={job.industry}
                    />,
                },
                {
                    label: 'Nivelul jobului',
                    content: <Chip
                        style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                        label={job.jobType}
                    />,
                },
                {
                    label: 'Nivel cariera',
                    content: job.careerLevel.map((level, index) => {
                        return (
                            <Chip
                                style={{borderRadius: 6, marginRight: 10, marginBottom: 5, fontSize: 14}}
                                label={level.toString()}
                                key={index}
                            />
                        );
                    }),
                },
                {
                    label: 'Limbi vorbite',
                    content: job.languages.length > 0 ?
                        job.languages.split(',').map((lng, index) => {
                            return (
                                <Chip
                                    style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                                    label={capitalizeFirstLetter(lng.trim())}
                                    key={index}
                                />
                            );
                        }) : null,
                },
                {
                    label: 'Salariu',
                    content: job.confidentialSalary ?
                        <Chip
                            style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                            label={'Confidențial'}
                        />
                        :
                        <Chip
                            style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                            label={job.salary}
                        />,
                },
                {
                    label: 'Tipul jobului',
                    content: <Chip
                        style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                        label={job.locationType}
                    />,
                },
                {
                    label: 'Adresa',
                    content: job.locationType !== LocationTypeEnum.remote ?
                        job.address ? <Chip
                            style={{borderRadius: 6, marginRight: 10, fontSize: 14}}
                            label={job.address}
                        /> : null
                        : null,
                },
            ]);
        }
    }, [job]);

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const onLoad = function callback(map: google.maps.Map) {
        setMap(map);
    };

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, []);

    const profileDiv = () => {
        return (
            <div id={'reverse-div'}>
                <div className={'reverse-profile-container rounded'}>
                    <div className={'profile-picture-container'}>
                        <NavLink to={'/companii/' + companyProfile?.id!}>
                            {
                                companyProfile?.photoURL ?
                                    <img src={companyProfile?.photoURL} className={'profile-picture'}/> :
                                    <ApartmentRounded style={{padding: '0.5rem'}} className={'profile-icon'}/>
                            }
                        </NavLink>
                    </div>
                    <div className={'profile-name'}>
                        <NavLink to={'/companii/' + companyProfile?.id!}>
                            <h2>{companyProfile?.companyName}</h2>
                        </NavLink>
                    </div>
                    <div className={'profile-type'}>
                        {companyProfile?.industries?.map((industry, index) => {
                            return (
                                <NavLink
                                    to={`/joburi?industry=${IndustriesEnum[industry as unknown as keyof typeof IndustriesEnum]}`}
                                    key={index}>
                                    <Chip
                                        style={{margin: 4, borderRadius: 6}}
                                        label={industry}
                                        className={'job-badge chip-job-badge'}
                                        key={index}/>
                                </NavLink>
                            )
                        })}
                    </div>
                    <div className={'social-icons-row'}>
                        <div className={'contact-info'}>
                            <div className={'contact-row'}>
                                <Email className={'contact-icon'}/>
                                <div className={'contact-content'}>
                                    <Tooltip
                                        title={companyProfile?.contactEmail ?? companyProfile?.email ?? ''}
                                        TransitionComponent={Zoom}
                                        arrow>
                                        <a href={`mailto:${companyProfile?.contactEmail ?? companyProfile?.email}`}>
                                            {companyProfile?.contactEmail ?? companyProfile?.email ?? ''}
                                        </a>
                                    </Tooltip>

                                </div>
                            </div>
                            {
                                companyProfile?.phoneNumber && <div className={'contact-row'}>
                                    <Phone className={'contact-icon'}/>
                                    <div className={'contact-content'}>
                                        <Tooltip
                                            title={companyProfile?.phoneNumber}
                                            TransitionComponent={Zoom}
                                            arrow>
                                            <a href={`tel:${companyProfile?.phoneNumber}`}>
                                                {companyProfile?.phoneNumber}
                                            </a>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            {
                                companyProfile?.website && <div className={'contact-row'}>
                                    <Language className={'contact-icon'}/>
                                    <div className={'contact-content'}>
                                        <Tooltip
                                            title={companyProfile?.website}
                                            TransitionComponent={Zoom}
                                            arrow>
                                            <a href={companyProfile?.website} target={'_blank'}>
                                                {companyProfile?.website.replace('https://', '').replace('http://', '')}
                                            </a>
                                        </Tooltip>
                                    </div>
                                </div>
                            }
                            {
                                companyProfile?.isValidCompany && <div className={'contact-row'}>
                                    <LocationOn className={'contact-icon'}/>
                                    <div className={'contact-content'} style={{whiteSpace: 'break-spaces'}}>
                                        {companyProfile?.cityAddress}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    isLoaded && center && <div className={'job-location-container rounded'}>
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%'
                            }}
                            center={center}
                            zoom={15}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={mapOptions}
                        >
                            {
                                center && <Marker
                                    title={job?.title}
                                    position={center}
                                />
                            }
                        </GoogleMap>
                    </div>
                }
            </div>
        )
    }

    const saveNewDescription = async (newDescription: string) => {
        await FirebaseUtil.write('Jobs', job!.id,
            {
                description: newDescription
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        )
    }

    const saveNewSkills = async (newSkills: LabelContentItem[]) => {
        await FirebaseUtil.write('Jobs', job!.id,
            {
                skills: newSkills
            }).catch(e =>
            dispatch(snackbarSlice.actions.show({
                message: 'A intervenit o eroare. Incearca din nou mai tarziu.',
                severity: 'error',
            }))
        )
    }

    return companyProfile && job ? (
        <>
            <div className={'background-container'}/>
            <Container maxWidth={'xl'} className={'page-container'}>
                {
                    windowWidth > 768 && profileDiv()
                }
                <div className={'reverse-information-container rounded'}>
                    <JobInformationCard
                        rows={jobDetailRows}
                        title={job.title}
                        subtitle={job.numberOfOpenings + (job.numberOfOpenings > 1 ? ' poziții disponibile' : ' poziție disponibila')}
                        isOwnProfile={isOwnProfile}
                        job={job}
                    />
                    <DescriptionInformationCard
                        description={job?.description}
                        isOwnProfile={isOwnProfile}
                        saveCallback={saveNewDescription}
                        isForJob={true}
                    />
                    {
                        job.skills && <SkillsInformationCard
                            isOwnProfile={isOwnProfile}
                            rows={job.skills}
                            title={'Aptitudini necesare'}
                            saveCallback={saveNewSkills}
                        />
                    }

                </div>
                {
                    windowWidth <= 768 && profileDiv()
                }
            </Container>
        </>
    ) : null;
}

const JobDetails = MotionHoc(JobDetailsComponent);

export default JobDetails;